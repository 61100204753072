// import { SocialLinks } from "components/GlobalComponents/SocialLinks/SocialLinks";
// import MiniNews from "components/News/MiniNews/MiniNews";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
// import { dummyNews as news } from "DummyData/DummyNews";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import { useHistory } from "react-router";
//import { Carousel } from "antd";
import "./News.scss";
import { getItemApiMethod, getLajmeForHomePage } from "API";
import { useParams } from "react-router-dom";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import NewsRelated from "components/Komision/NewsRelated/NewsRelated";
import Dokumentat from "components/Komision/Dokumentat/Dokumentat";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";
// import attachmentIcon from "assets/images/attachment.png";
// import defaultImg from "../../assets/images/header-backround.jpg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function News() {
  // const history = useHistory();
  const { id } = useParams();
  const [t, i18n] = useTranslation();
  // const [selectedNews, setSelectedNews] = useState(null);
  const [lajm, setLajm] = useState();
  const [suggestedNews, setSuggestNews] = useState([]);

  // useEffect(() => {
  //   const newsId = history.location.pathname.substr(6);
  //   const filter = news.filter((a) => a.id == newsId)[0];
  //   setSelectedNews(filter || null);
  // }, []);

  let newsDocs = lajm?.dokumentet
    .filter(
      (doc) =>
        doc.tipDokument.emer != "FOTOKRYESORELAJMI" &&
        doc.tipDokument.emer != "FOTOGALERI"
    )
    .reduce((r, a) => {
      r[a.tipDokument.emer] = [...(r[a.tipDokument.emer] || []), a];
      return r;
    }, {});
  // console.log(newsDocs);

  useEffect(() => {
    getItemApiMethod({ apiName: "lajmet", id }).then((res) => {
      setLajm(res.data);
    });
    getLajmeForHomePage({ apiName: "lajmet" }).then((res) => {
      setSuggestNews(res.data);
    });
  }, [id]);

  const lajmMainPhotos =
    lajm?.dokumentet?.filter(
      (doc) => doc.tipDokument.emer.toLowerCase() === "fotokryesorelajmi"
    ) || [];

  if (lajmMainPhotos.length >= 2) {
    var firstEl = lajmMainPhotos.shift();
  }

  const lajmGalleryPhotos =
    lajm?.dokumentet?.filter(
      (doc) => doc.tipDokument.emer.toLowerCase() === "fotogaleri"
    ) || [];

  // if (lajmGalleryPhotos.length >= 2) {
  //   var gallryEl = lajmGalleryPhotos.shift();
  // }

  console.log(lajmMainPhotos);
  console.log(firstEl);
  console.log(lajmGalleryPhotos);
  //console.log(gallryEl);

  const images = lajmGalleryPhotos.map((photo) => photo.url);

  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  return (
    <LoadingComp loading={!lajm} className="long-data-loading" foundData={lajm}>
      <div className="news-wrapper">
        <div className="news-body">
          <div className="news-content">
            <DynamicHeader title={t("newsPage.news")} />
            <p className="posted-date">
              {t("newsPage.posted")}{" "}
              {moment(lajm?.datePublikimi).format("DD/MM/YYYY")}
            </p>
            <div className="news-title">
              {getTranslatedField(lajm, i18n, "titull", "titullEn")}
            </div>
            {lajmMainPhotos[0]?.url && (
              <div className="main-photo">
                <img src={lajmMainPhotos[0]?.url} />
              </div>
            )}

            <div
              className="news-body-content"
              dangerouslySetInnerHTML={{
                __html: getTranslatedField(
                  lajm,
                  i18n,
                  "permbajtje",
                  "permbajtjeEn"
                ),
              }}
            />

            {lajmGalleryPhotos.length > 1 && (
              <div>
                <button type="button" onClick={() => setIsOpen(true)}>
                  {t("navigation.gallery")}
                </button>

                {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={
                      images[(photoIndex + images.length - 1) % images.length]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % images.length)
                    }
                  />
                )}
              </div>
            )}

            {/* {lajmGalleryPhotos.length > 1 && (
              <div className="news-carousel-container">
                <Carousel autoplay>
                  {lajmGalleryPhotos.map((lajm, index) => (
                    <div key={index}>
                      <img className="news-featured-img" src={lajm.url} />
                    </div>
                  ))}
                </Carousel>
              </div>
            )} */}

            <div className="news-documents">
              {Object.entries(newsDocs || []).map((dok) => {
                return (
                  <Dokumentat key={dok[0]} lloji={dok[0]} dokumenta={dok[1]} />
                );
              })}
            </div>
            <NewsRelated
              newsList={suggestedNews}
              className="suggested-news-wrapper"
            />
          </div>
        </div>
      </div>
    </LoadingComp>
  );
}
