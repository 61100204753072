import React from "react";
import "./RendiDitesComp.scss";
import calendarIcon from "assets/images/calendarKomision.svg";
import moment from "moment";
import { Col } from "antd";
import { useHistory } from "react-router-dom";

export default function RendiDitesComp({ data }) {
  const history = useHistory();
  // console.log(data);
  return (
    <div
      className="rendi-dites-comp"
      onClick={() => {
        history.push(`/kalendar/${data?.dateOre}`);
      }}
    >
      <Col flex={1}>
        <img alt="calendar-icon" src={calendarIcon} />
      </Col>
      <Col flex={10}>
        <p>{moment(data.dateOre).format("dddd, \\d\\a\\t\\ë DD MMMM YYYY")}</p>
      </Col>
    </div>
  );
}
