import React from "react";
import "./Ndertesa.scss";
import { ndertesaData } from "./NdertesaData";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

const { Option } = Select;

export const Ndertesa = () => {
  const [godina, setGodina] = useState("Salla");
  const [link, setLink] = useState(null);
  const { i18n } = useTranslation();
  const [t] = useTranslation();
  useEffect(() => {
    if (godina == "Salla") {
      if (i18n.language == "al") {
        setLink("https://my.matterport.com/show/?m=USKbSY1QvmM");
      } else {
        setLink("https://my.matterport.com/show/?m=y5zjW9R7yrk");
      }
    } else {
      if (i18n.language == "al") {
        setLink("https://my.matterport.com/show/?m=dQSSVFyA2VJ");
      } else {
        setLink("https://my.matterport.com/show/?m=1pjM1dCJQFM");
      }
    }
  }, [i18n.language]);
  function handleChange(value) {
    setGodina(value);
    if (value == "Salla") {
      if (i18n.language == "al") {
        setLink("https://my.matterport.com/show/?m=USKbSY1QvmM");
      } else {
        setLink("https://my.matterport.com/show/?m=y5zjW9R7yrk");
      }
    } else {
      if (i18n.language == "al") {
        setLink("https://my.matterport.com/show/?m=dQSSVFyA2VJ");
      } else {
        setLink("https://my.matterport.com/show/?m=1pjM1dCJQFM");
      }
    }
  }
  
  return (
    <div className="ndertesa-wrapper">
      <iframe src={link} width="70%" height="600px" />
      <div className="select-tour">
        <Select
          defaultValue="Salla"
          value={godina}
          style={{ width: 280 }}
          onChange={handleChange}
        >
          <Option value="Salla">
            {t("kuvendiMenu.history.select.option1")}
          </Option>
          <Option value="Godina">
            {t("kuvendiMenu.history.select.option2")}
          </Option>
        </Select>
      </div>
      <b>Ndertesa</b>
      <div className="ndertesa-content">
        {ndertesaData.map((item) => (
          <>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </>
        ))}
      </div>
    </div>
  );
};

export default Ndertesa;
