import axios from "axios";
import moment from "moment";
import { convertToIsoFomat } from "helpers/isoTimeFormat";

export //const fbToken = `EAANzBfw3HzQBO72fZBeSZC3Gr1Gr3DfQSfN5I1V6MtBwZCXrspFq0RrUg5BsZBGWVZBbbVEsUYidToKKitfk8SWXpOzJF1oePBJ7qZAKHmmI9oSJ6pekOvl3TZAdZA7zUeFA9zmcfZATQuvGJL1ovJU7DLhJFpaHRObedPhdZCP2w6oOaXIeLS1Xg8nz3ZCbkKELAZDZD`;
const localBaseUrl = "https://kuvendiapi.azurewebsites.net/api";
const googleCaptchaVerifyApi =
  "https://www.google.com/recaptcha/api/siteverify";
const RECAPTCHA_SERVER_KEY = "6Ldi5CweAAAAAMCcS1JtmCdbzitu8VypAW_JzWUB";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~GET ALL ITEMS~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getAllApiMethod = async ({ apiName }) => {
  return await axios.get(`${localBaseUrl}/${apiName}`);
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~GET AN ITEM~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getItemApiMethod = async ({ apiName, id }) => {
  return await axios.get(`${localBaseUrl}/${apiName}/${id}`);
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ACTIVITIES~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getMbledhjetHomepage = async ({
  // Get mbledhjet on homepage depending on day selected by user
  apiName,
  today,
  fourDaysAfter,
}) => {
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=dateore le ${fourDaysAfter} and dateore ge ${today}&$expand=mbledhjestrukturat($expand=strukture)&$orderby=dateore asc`
  );
};

export const getMbledhjetByDate = async ({
  // Get mbledhjet by date of selected mbledhje
  apiName,
  startOfDay,
  endOfDay,
}) => {
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=dateore le ${endOfDay} and dateore ge ${startOfDay}&$expand=mbledhjestrukturat($expand=strukture)&$orderby=dateore asc`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ANETARET~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getAllAnetaretData = async ({ apiName }) => {
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=status eq 'Aktiv'&$orderby=emer`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~NEWS~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getLajmeForHomePage = async ({ apiName }) => {
  // Get news on homepage by descending order
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=status eq 'Aktiv' and IsSticky eq true and Tip eq 'Lajm'&$top=3&$expand=lajmdokumentet($expand=dokument($expand=tipdokument))&orderby=datePublikimi desc`
    // `${localBaseUrl}/${apiName}?$filter=issticky eq false&$top=3&$expand=lajmdokumentet($expand=dokument($expand=tipdokument))&orderby=datePublikimi desc` // for getting sticky news if true
  );
};

export const getLimitedLajme = async ({ apiName, page, pageSize }) => {
  // Get news akte when clicking show more, showing a list of 10 news per render
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=status eq 'Aktiv' and Tip eq 'Lajm'&$top=${page}&skip=${pageSize}&$select=id,titull,tip,datekrijimi,datePublikimi,permbajtje&$expand=lajmdokumentet($expand=dokument)&orderby=datePublikimi desc`
  );
};

export const getFilteredLajme = async ({
  // Getting filtered news by filling fields
  fjaleKyce,
  datePublikimiStart,
  datePublikimiEnd,
  page,
  pageSize,
}) => {
  let filters = [];

  if (datePublikimiStart) {
    filters.push(
      `datepublikimi gt ${convertToIsoFomat(moment(datePublikimiStart))}`
    );
  }

  if (datePublikimiEnd) {
    filters.push(
      `and datepublikimi lt ${convertToIsoFomat(moment(datePublikimiEnd))} and `
    );
  }

  if (fjaleKyce) {
    filters.push(`contains(permbajtje, '${fjaleKyce}') and `);
  }

  const filterString = filters.join(" ");

  return await axios.get(
    `${localBaseUrl}/Lajmet?$filter=${filterString} status eq 'Aktiv'&$top=${pageSize}&$skip=${page} &$select=id, titull, permbajtje, datePublikimi&$expand=lajmdokumentet($expand=dokument)&orderby=datePublikimi desc`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~AKTE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~KETU SHIKO
export const getAkteForHomePage = async ({ apiName }) => {
  // Get akte in the homepage by descending order
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=tip eq 'Projektligj' and status ne 'Pasiv' and status ne 'Draft'&$top=3&$expand=aktstrukturat($expand=strukture)&orderby=dateDepozitimi desc`
  );
};

export const getLimitedActs = async ({ apiName, page, pageSize }) => {
  // Get akte when clicking show more, showing a list of 10 acts per render
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=status ne 'Pasiv'and status ne 'Draft'&$top=${page}&$skip=${pageSize}&$orderby=dateDepozitimi desc`
  );
};

export const getAkteByTip = async ({ tip, skip = 0, pageSize = 10 }) => {
  // Get akte in the homepage by descending order with pagination
  return await axios.get(
    `${localBaseUrl}/aktet?$filter=tip eq '${tip}' and status ne 'Pasiv' and status ne 'Draft'&$top=${pageSize}&$skip=${skip}&$expand=aktstrukturat($expand=strukture)&orderby=dateDepozitimi desc`
  );
};

export const getFilteredAkte = async ({
  dateMiratimiStart,
  dateMiratimiEnd,
  titull,
  tip,
  page,
  pageSize,
}) => {
  let filters = [];

  if (dateMiratimiStart) {
    filters.push(
      `datedepozitimi gt ${convertToIsoFomat(moment(dateMiratimiStart))}`
    );
  }

  if (dateMiratimiEnd) {
    filters.push(
      `and datedepozitimi lt ${convertToIsoFomat(moment(dateMiratimiEnd))} and `
    );
  }

  if (titull) {
    filters.push(`contains(titull, '${titull}') and `);
  }

  if (tip) {
    filters.push(`tip eq '${tip}' and `);
  }

  // Common status condition
  //filters.push("and status ne 'Pasiv' and status ne 'Draft'");

  const filterString = filters.join(" ");

  const response = await axios.get(
    `${localBaseUrl}/aktet?$filter=${filterString}status ne 'Pasiv' and status ne 'Draft'&$top=${pageSize}&$skip=${page}&$select=id, titull, tip, propozuesi, datemiratimi, datedepozitimi&$orderby=datedepozitimi%20desc`
  );

  return response;
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~NJOFTIME~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getNjoftime = async ({ apiName, page, pageSize }) => {
  // Get all news with type 'njoftim'
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=tip%20eq%20%27Njoftim%27 and status eq 'Aktiv'&$top=${page}&skip=${pageSize}&$select=id,titull,tip,datekrijimi,datePublikimi&$expand=lajmdokumentet($expand=dokument)&orderby=datePublikimi desc`
  );
};

export const getNjoftimeHomepage = async ({ apiName }) => {
  // Get all news with type 'njoftim' on homepage
  return await axios.get(
    `${localBaseUrl}/${apiName}?$filter=tip%20eq%20%27Njoftim%27 and status eq 'Aktiv'&$select=id,titull,tip,datekrijimi&orderby=datePublikimi desc`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~PROCESVERBALE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getProcesverbale = async ({ apiName, page = 0 }) => {
  // Get all documents with type 'procesverbal'
  return await axios.get(
    //`${localBaseUrl}/${apiName}?$filter=tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4 &$top=10&$orderby=datekrijimi desc`
    //`${localBaseUrl}/${apiName}?$expand=dokument&$filter=dokument/tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4 and struktureid eq 332f2692-f708-41aa-8059-2b8d2143bca1&$expand=strukture&$top=10`
    //`${localBaseUrl}/${apiName}?$filter=tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4 &$top=10`
    // `${localBaseUrl}/${apiName}?$expand=dokument&$filter=dokument/tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4 and struktureid eq 332f2692-f708-41aa-8059-2b8d2143bca1&$expand=strukture&$top=10&orderby=dateKrijimi desc`
    `${localBaseUrl}/${apiName}?$orderby=datekrijimi desc&$filter=tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4&$expand=strukturedokumentet($expand=strukture)&$top=100&$skip=${page}`
  );
};

export const getFilteredProcesverbale = async ({
  apiName,
  struktureId,
  page,
  pageSize,
}) => {
  // Get filtered documents of the selected structure of type 'procesverbal'
  return await axios.get(
    //`${localBaseUrl}/${apiName}?$expand=dokument&$filter=dokument/tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4 and struktureid eq ${struktureId} &$expand=strukture&$top=10`
    //`${localBaseUrl}/${apiName}?$expand=dokument&$filter=dokument/tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4 and struktureid eq ${struktureId} &$expand=strukture&$top=10`
    `${localBaseUrl}/${apiName}/${struktureId}/dokumentet?$expand=strukturedokumentet($expand=strukture)&$orderby=datekrijimi desc&$top=${pageSize}&$skip=${page}&$filter=tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4`
  );
};

export const getNextRecords = async ({ apiName, page, pageSize }) => {
  // Get procesverbale when clicking show more, showing a list of 10 acts per render
  return await axios.get(
    `${localBaseUrl}/${apiName}?$expand=dokument&$filter=dokument/tipdokumentid eq cc439e41-586a-45b0-a005-a505f6a141a4 and struktureid eq 332f2692-f708-41aa-8059-2b8d2143bca1&$expand=strukture&$top=${page}&$skip=${pageSize}&$orderby=datekrijimi desc`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~GOOGLE CAPTCHA~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const verifyGoogleCaptcha = async ({ token }) => {
  //google capcha for pyet deputetin forme
  return await axios.post(
    `${googleCaptchaVerifyApi}?secret=${RECAPTCHA_SERVER_KEY}&response=${token}`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~SEARCH ENTIRE WEBSITE~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const searchAllData = async ({ value, size }) => {
  // Getting search results on entire app about user value typed on homepage general search
  return await axios.get(
    `${localBaseUrl}/search/all?searchedtext=${value}&size=${size}`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ABONIME~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const postAbonim = async ({ apiName, id, email, tip, token = "" }) => {
  // Post abonim
  return await axios.post(`${localBaseUrl}/${apiName}`, {
    id,
    email,
    tip,
    token,
  });
};

export const getSeancePlenare = async () => {
  return await axios.get(
    `${localBaseUrl}/strukturat/332f2692-f708-41aa-8059-2b8d2143bca1`
  );
};

export const getSherbimiSeancePlenare = async () => {
  return await axios.get(
    `${localBaseUrl}/strukturat/1cd4218c-b5cf-4a59-895f-b92291c0f5da`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~GET AN ITEM~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getSuggestedNewsByStruktureId = async ({ apiName, id }) => {
  return await axios.get(
    `${localBaseUrl}/${apiName}/${id}?$expand=anetaret,mbledhjet,dokumentet,lajmet($orderby=DatePublikimi desc)`
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~DOKUMENTA~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getDokumenta = async ({ apiName, page }) => {
  // Get all documents with type
  return await axios.get(
    //`${localBaseUrl}/${apiName}?$filter=tipdokumentid eq 97756fcb-b0c7-4c60-969a-ab07be7d0ad7 or tipdokumentid eq 7587bd49-801e-40c9-aba2-5bbcf228a649&$expand=strukturedokumentet($expand=strukture)&$top=100&$skip=${page}&$orderby=datekrijimi desc`
    `${localBaseUrl}/${apiName}?$filter=tipdokumentid ne 4fbe2f80-6216-404a-b56b-28eba39c604d and tipdokumentid ne 1893b2c1-7109-4f5f-9884-d82470f99e7e&$top=10&$skip=${page}&$orderby=datekrijimi desc`
  );
};

export const getFilteredDokumenta = async ({
  apiName,
  struktureId,
  tipDokId,
}) => {
  return await axios.get(
    `${localBaseUrl}/${apiName}/${struktureId}/dokumentet?$expand=strukturedokumentet($expand=strukture)&$orderby=datekrijimi desc&$filter=tipdokumentid eq ${tipDokId}&$top=10`
  );
};

export const getFilteredDokumentaSearch = async ({
  apiName,
  struktureId,
  tipDokId,
  page,
}) => {
  return await axios.get(
    `${localBaseUrl}/${apiName}/${struktureId}/dokumentet?$expand=strukturedokumentet($expand=strukture)&$orderby=datekrijimi desc&$filter=tipdokumentid eq ${tipDokId}&$top=10&$skip=${page}`
  );
};

export const getNextRecordsDokumenta = async ({
  apiName,
  struktureId,
  tipDokId,
  page,
}) => {
  return await axios.get(
    //`${localBaseUrl}/${apiName}?$expand=dokument&$filter=struktureid eq 332f2692-f708-41aa-8059-2b8d2143bca1&$expand=strukture&$top=${page}&$skip=${pageSize}`
    `${localBaseUrl}/${apiName}/${struktureId}/dokumentet?$expand=strukturedokumentet($expand=strukture)&$orderby=datekrijimi desc&$filter=tipdokumentid eq ${tipDokId}&$top=${page}&$skip=10`
  );
};

export const getFilteredDokumentaByTipDokId = async ({
  apiName,
  tipDokId,
  page = 0,
}) => {
  // Get all documents with type
  return await axios.get(
    //`${localBaseUrl}/${apiName}?$filter=tipdokumentid eq 97756fcb-b0c7-4c60-969a-ab07be7d0ad7 or tipdokumentid eq 7587bd49-801e-40c9-aba2-5bbcf228a649&$expand=strukturedokumentet($expand=strukture)&$top=100&$skip=${page}&$orderby=datekrijimi desc`
    `${localBaseUrl}/${apiName}?$filter=tipdokumentid eq ${tipDokId}&$top=10&$skip=${page}&$orderby=datekrijimi desc`
  );
};

export const getFilteredDokumentaByStruktureId = async ({
  apiName,
  struktureId,
  page = 0,
}) => {
  // Get all documents with type
  return await axios.get(
    //`${localBaseUrl}/${apiName}?$filter=tipdokumentid eq 97756fcb-b0c7-4c60-969a-ab07be7d0ad7 or tipdokumentid eq 7587bd49-801e-40c9-aba2-5bbcf228a649&$expand=strukturedokumentet($expand=strukture)&$top=100&$skip=${page}&$orderby=datekrijimi desc`
    `${localBaseUrl}/${apiName}/${struktureId}/dokumentet?$top=10&$skip=${page}&$orderby=datekrijimi desc`
  );
};
