import moment from "moment";
import React from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";

export default function AgendaItem({ data }) {
  const history = useHistory();
  let emratStuktura = data?.MbledhjeStrukturat[0]?.Strukture;
  const [t, i18n] = useTranslation();
  //console.log(data);

  return (
    <div
      className="agenda-item"
      onClick={() => {
        history.push(`/kalendar/${data?.DateOre}`);
      }}
    >
      <div className="agenda-time">
        <ClockCircleOutlined />
        &nbsp;&nbsp;
        {moment(data.DateOre).format("HH:mm")}
      </div>
      <div
        className="agenda-description"
        // dangerouslySetInnerHTML={{ __html: data.Rendi }}
      >
        {getTranslatedField(emratStuktura, i18n, "Emer", "EmerEn")}
        {t("")}
      </div>
    </div>
  );
}
