import SearchResultComp from "components/GlobalComponents/SearchResultComponent/SearchResultComp";
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
import React from "react";
import "./SearchItemsDynamicComp.scss";

export const SearchItemsDynamicComp = ({ data }) => {
  // console.log(data);
  return (
    <div className="search-item-dynam-comp">
      <b>{capitalizeFirstLetter(data?.key)}</b>
      <div className="search-item-content">
        {data?.topHits?.hits?.hits?.map((item, key) => (
          <SearchResultComp key={key} data={item} />
        ))}
      </div>
    </div>
  );
};
