import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React from "react";
//import strukturafooterimg from "../../../assets/FoooterAssets/strukturafooterimg.png";
import "./StukturaFooter.scss";

const StrukturaFooter = () => {
  return (
    <div className="struktura-footer-wrapper">
      <DynamicHeader title="Struktura" />
      <div className="img-container">
        <img src="https://kuvendiwebfiles.blob.core.windows.net/webfiles/organigrama.PNG"></img>
      </div>
    </div>
  );
};

export default StrukturaFooter;
