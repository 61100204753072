import React from "react";
import { useHistory } from "react-router";
import "./HeaderItem.scss";

export default function HeaderItem({ icon, text, goTo, outside }) {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        if (outside) {
          window.open(goTo, "_blank");
        } else {
          history.push(goTo);
        }
      }}
      className="header-item"
    >
      <div className="icon-part">
        <img alt="header-icon" src={icon} />
      </div>
      <div className="text-part">{text}</div>
    </div>
  );
}
