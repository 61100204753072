import React from "react";
import "./Roli.scss";
import { roliData } from "./RoliData";
import { useTranslation } from "react-i18next";

export const Roli = () => {
  const [t] = useTranslation();
  return (
    <div className="roli-wrapper">
      <b>{t("kuvendiMenu.role.header")}</b>
      <div className="roli-content">
        {roliData({ ...{ t } }).map((item) => (
          <>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.videoUrl && (
              <iframe
                width="100%"
                height="600"
                src={item.videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Roli;
