import { ActCard } from "components/GlobalComponents/ActCard/ActCard";
import { getTranslatedField } from "helpers";
import { objectKeysToLowerCase } from "helpers/objectKeysToLowerCase";
import React from "react";
import { useTranslation } from "react-i18next";
import "./TabelaAkteve.scss";

export const TabelaAkteve = ({ akte, t }) => {
  let transformetKeysArr = akte?.map((item) => objectKeysToLowerCase(item));
  const { i18n } = useTranslation();
  return (
    <div className="akte-wrapper">
      {transformetKeysArr.map((akt, key) => (
        <ActCard
          key={key}
          id={akt.id}
          title={getTranslatedField(akt, i18n, "titull", "titullEn")}
          // number="123"
          propozuesi={akt.propozuesi}
          time={{
            dt_dep: akt.datedepozitimi,
            dt_miratimi: akt.datemiratimi,
          }}
          type={akt.tip}
          t={t}
        />
      ))}
    </div>
  );
};

export default TabelaAkteve;
