import kipIcon from "assets/images/kipIcon.png";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getSherbimiSeancePlenare } from "../../../API/requests";
import "./PIPKIP.scss";
import { useHistory } from "react-router";

export default function PIPKIP() {
  const [t] = useTranslation();
  const [seancaPlenare, setSeancaPlenare] = React.useState(null);
  //const history = useHistory();

  useEffect(() => {
    getSherbimiSeancePlenare().then((res) => setSeancaPlenare(res.data));
  }, []);

  let workCal = "/";
  let workProgram = "/";
  console.log(seancaPlenare, "sp");
  if (seancaPlenare) {
    const docs = seancaPlenare.dokumentet.filter(
      (dok) => dok.tip === "KALENDARIPUNIMEVE"
    );
    let dokumentetList = [];
    docs.forEach((doc) => {
      dokumentetList = dokumentetList.concat(doc.dokumentet || []);
    });

    const latestDocs = dokumentetList.sort((a, b) => {
      return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
    });
    if (latestDocs[0]) {
      workCal =
        "dokumenta/332f2692-f708-41aa-8059-2b8d2143bca1/7587bd49-801e-40c9-aba2-5bbcf228a649";
    }
  }
  if (seancaPlenare) {
    const docs = seancaPlenare.dokumentet.filter(
      (dok) => dok.tip === "PROGRAMPUNE"
    );
    let dokumentetList = [];
    docs.forEach((doc) => {
      dokumentetList = dokumentetList.concat(doc.dokumentet || []);
    });

    const latestDocs = dokumentetList.sort((a, b) => {
      return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
    });
    if (latestDocs[0]) {
      workProgram =
        "dokumenta/332f2692-f708-41aa-8059-2b8d2143bca1/97756fcb-b0c7-4c60-969a-ab07be7d0ad7";
    }
  }
  return (
    <div className="pipkip-wrapper">
      <PIPKIPComponent
        text={t("homeSections.homeHeader.workProgram")}
        icon={kipIcon}
        url={workProgram}
      />
      <PIPKIPComponent
        text={t("homeSections.homeHeader.workCalendar")}
        icon={kipIcon}
        url={workCal}
      />
    </div>
  );
}
function PIPKIPComponent({ text, icon, url }) {
  console.log(url);
  const history = useHistory();
  return (
    <div
      className="pipkip-comp-wrapper"
      //onClick={() => window.open(url, "_blank")}
      onClick={() => history.push(url)}
    >
      <div className="icon-part">
        <img src={icon} alt="pippip-icon" />
      </div>
      {text}
    </div>
  );
}
