import React from "react";
import Agenda from "../Agenda/Agenda";
import { Row, Col } from "antd";
import "./HomeHeader.scss";
import HeaderItem from "../HeaderItem/HeaderItem";
import procesiLegjislativ from "assets/images/procesiLegjislativ.png";
import pyetDeputetinIcon from "assets/images/pyetDeputetinIcon.png";
import kidsIcon from "assets/images/kidsIcon.png";
import informIcon from "assets/images/informIcon.png";
import kuvendiIcon from "assets/images/kuvendiIcon.png";
import notificationsIcon from "assets/images/notificationsIcon.png";
// import projectIcon from "assets/images/projectIcon.png";
import perfshiju from "assets/images/perfshiju.png";
import petitionsIcon from "assets/images/petitions.png";
import statistics from "assets/images/statistics.png";
import PIPKIP from "../PIPKIP/PIPKIP";
import { useTranslation } from "react-i18next";
import { edukimiQytetar } from "helpers/strukturaDefiners";
import { koordinatoriShqoCivile } from "helpers/strukturaDefiners";

export default function HomeHeader() {
  const [t] = useTranslation();

  const headerItems = [
    {
      goTo: "/kuvendi/historiku",
      icon: kuvendiIcon,
      text: t("homeSections.homeHeader.assembly"),
    },
    {
      goTo: "/pyeteDeputetin",
      icon: pyetDeputetinIcon,
      text: t("homeSections.homeHeader.askMp"),
    },
    {
      goTo: "/kuvendi/funksionet/roli",
      icon: procesiLegjislativ,
      text: t("homeSections.homeHeader.legProcess"),
    },
    {
      goTo: `struktura/${edukimiQytetar[0].id}`,
      icon: kidsIcon,
      text: t("homeSections.homeHeader.civicEducation"),
    },
    {
      goTo: `struktura/${koordinatoriShqoCivile[0].id}`,
      icon: informIcon,
      text: t("homeSections.homeHeader.stayInformed"),
    },
    {
      goTo: `/struktura/ac8730e3-0ac5-46a1-9a65-c5c2fbecd726`,
      icon: petitionsIcon,
      text: t("homeSections.homeHeader.petitions"),
    },
    {
      goTo: "/njoftime",
      icon: notificationsIcon,
      text: t("homeSections.homeHeader.announcements"),
    },
    // {
    //   goTo: "/",
    //   icon: projectIcon,
    //   text: t("homeSections.homeHeader.projects"),
    // },
    {
      goTo: "/struktura/1f6ca8d8-fdf4-440b-869d-05ac3d0b915d",
      icon: perfshiju,
      text: t("homeSections.homeHeader.getInvolved"),
    },
    {
      goTo: "/struktura/7ede1dde-9912-4d89-aabc-3c82ec4ac52d",
      icon: statistics,
      text: t("homeSections.homeHeader.statistic"),
    },
  ];
  return (
    <Row className="home-header-wrapper">
      <Col xs={24} md={12} className="header-items-part">
        <div className="header-items">
          {headerItems.map((item, i) => {
            return (
              <HeaderItem
                key={i}
                text={item.text}
                icon={item.icon}
                goTo={item.goTo}
                outside={item.outside}
              />
            );
          })}
        </div>
      </Col>
      <Col xs={24} md={12} className="todays-agenda-part">
        <Agenda />
      </Col>
      <PIPKIP />
    </Row>
  );
}
