import React from "react";
import attachRed from "assets/images/attachmentRed.png";
import "../StrukturaList/StrukturaList.scss";

export default function DeclarationList({ list }) {
  return (
    <div className="struktura-list-wrapper">
      {list.length === 0 && "S'ka deklarata!"}
      {list.map((declaration, i) => {
        return (
          <p
            onClick={() => {
              window.open(declaration?.url, "_blank");
            }}
            key={i}
            style={{ cursor: "pointer" }}
          >
            <img src={attachRed} />{" "}
            {`${
              declaration?.fileName?.length > 50
                ? `${declaration?.fileName.substring(0, 50)}...`
                : `${declaration?.fileName}`
            }`}
          </p>
        );
      })}
    </div>
  );
}
