import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React from "react";
import "./BuxhetiFooter.scss";
import { Collapse } from "antd";
import { FileTextOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const BuxhetiFooter = () => {
  return (
    <>
      <DynamicHeader title="Raporte, Statistika, Buxheti" />
      <div className="akte-ligjore-footer-wrapper">
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Raporte" key="1">
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport-2016.pdf">
                -Raport vjetor 2016
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport-vjetor-2015.pdf">
                -Raport vjetor 2015
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport-vjetor-2014.pdf">
                -Raport vjetor 2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport-vjetor-2013.pdf">
                -Raport vjetor 2013
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport%202017.pdf">
                -Raport vjetor 2017
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/KUVENDIRAPORTIVJETOR2018.pdf">
                -Raport vjetor 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raportvjetor2019.pdf">
                -Raport vjetor 2019
              </a>
            </p>
          </Panel>

          <Panel header="Buxheti" key="2">
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/relacion_shoqerues_i_raporteve_te_monitorimit_23311_1.doc">
                -Relacion shoqërues i raporteve të monitorimit 2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport_monitorimi_janar_dhjetor_21230_1.xlsx">
                -Raport monitorimi janar – dhjetor 2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raporti_vjetor_i_monitorimit_2014_21229_1.doc">
                -Raport vjetor i monitorimit 2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/analize_3_mujore_per_raportet_e_monitorimit_17229_1.doc">
                -Analizë 3 mujore për raportet e monitorimit 2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raporti_monitorimit_te_buxhetit_per_3_mujorin_e_pare_v_2014_17228_1%20(1).xls">
                -Raporti Monitorimit te buxhetit per 3-mujorin e pare v.2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/vendim_nr_9_i_byrose_maj_2014_17230_1.doc">
                -Vendim nr 9 i byrose Maj 2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/analize_9_mujor_19694_1.doc">
                -Analizë 9 mujore për raportet e monitorimit 2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raporte_monitorimi_9_mujori_19693_1.xlsx">
                -Raporti Monitorimit të buxhetit për 9-mujorin e parë v.2014
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raport-per-realizimin-e-buxhetit-2015.doc">
                -Relacion shoqërues i raportit të monitorimit, Janar-Dhjetor
                2015
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport-monitorimi-janar-dhjetor-2015-MF.xls">
                -Raport Monitorimi Janar-Dhjetor 2015
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport-monitorimi2c-janar-shtator-2015-1.xlsx">
                -Raport monitorimi për 9- mujorin, Janar-Shtator 2015
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Relacion-shoqerues-Janar-Dhjetor-2016.docx">
                -Relacion shoqërues janar – dhjetor 2016
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raport-Monitorimi-Janar-Dhjetor-2016..xlsx">
                -Raport monitorimi janar – dhjetor 2016
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Relacion-i-raporteve-Monitorimit-9-mujori-viti-2016.doc">
                -Relacion i raporteve Monitorimit 9-mujori viti 2016
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raport-monitorimi-janar-shtator-2016..xlsx">
                -Raport monitorimi janar – shtator 2016
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Relacion-i-raporteve-Monitorimit-6-mujori-viti-2016.doc">
                -Relacion i raporteve Monitorimit 6-mujori i parë viti 2016
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Relacion-i-raporteve-Monitorimit-3-mujori-1-viti-2016...doc">
                -Relacion i raporteve Monitorimit 3-mujori i parë viti 2016.
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raport-monitorimi-janar-mars-2016..xlsx">
                -Raport monitorimi janar-mars 2016.
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Relacion-shoqerues-Janar-Prill-2017.-final.docx">
                -Relacion i raporteve te monitorimit janar – prill 2017
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raporte-monitorimi-Janar-Prill-2017.xls">
                -Raport monitorimi janar – prill 2017
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Relacion%20shoqerues%20raporte%20monitorimi%20janar-dhjetor%202017.docx">
                -Relacion shoqërues i raporteve të monitorimit janar – dhjetor
                2017
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Analize%20zbatim%20buxheti%208%20mujori%202018.doc">
                -Analize zbatim buxheti 8 mujori 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raporte%20monitorimi%20Janar-Gusht%202018.xls">
                -Raporte monitorimi Janar-Gusht 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Relacion%20shoqerues%20i%20raporteve%20te%20monitorimit%20janar%20prill%202018.doc">
                -Relacion shoqerues i raporteve te monitorimit janar prill 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raporte%20monitorimi%20Janar-Prill%202018.xls">
                -Raporte monitorimi Janar-Prill 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raportemonitorimiJanar-Dhjetor2018.xls">
                -Raporte monitorimi Janar-Dhjetor 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raportemonitorimiJanar-Dhjetor2018.xls">
                -Raporte monitorimi Janar-Dhjetor 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/analizevjetoreperraportetemonitorimit.docx">
                -Analizë vjetore për raportet e monitorimit 2018
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/webraporteMonitorimi8mujori.xls">
                -Raporte monitorimi Janar-Gusht 2019
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Analizezbatimbuxheti8mujori2019.doc">
                -Relacion shoqerues i raporteve te monitorimit Janar-Gusht 2019
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Analizeshoqerueseperbuxhetinvjetor2019.docx">
                -Analize shoqëruese e raporteve vjetore të monitorimit per
                buxhetin vjetor 2019
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Analizezbatimbuxheti4mujorijanarprill2020.doc">
                -Analizë zbatim buxheti për 4 mujorin janar – prill 2020
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/informacionDEMPteprogramitAdministrativbuxhetor.docx">
                -Informacion i DEMP të programit administrativ buxhetor (4 mujor
                i parë 2020)
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raportmonitorimi4mujoripar2020.xls">
                -Raport monitorimi 4 mujori parë 2020
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/PMARaportiMonitorimitvjetor2019.docx">
                -PMA Raporti i Monitorimit vjetor 2019
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raportperformancepervitin2019.docx">
                -Raport performance për buxhetin, viti 2019, legjislativi
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raportetmonitorimizbatimitbuxhetitvjetor2019.xls">
                -Raportet e monitorimit zbatimit të buxhetit vjetor 2019
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/buxhet2020.xls">
                -Raportet e monitorimit të zbatimit të buxhetit për vitin 2020
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/relacionbuxhet2020.doc">
                -Relacion shoqërues i raporteve të monitorimit të zbatimit të
                buxhetit të vitit 2020
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Analize%20zbatim%20buxheti%204%20mujori%20janar-prill%202021.pdf">
                -Analize zbatim buxheti 4 mujori janar-prill 2021
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raport%20monitorimi%20janar-prill%202021.xls">
                -Raport monitorimi janar-prill 2021
              </a>
            </p>
          </Panel>
          <Panel
            header="Raport mbi pjesëmarrjen e publikut në procesin e vendimmarrjes"
            key="3"
          >
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raporti-pjesemarrja_2015.pdf">
                -Raport mbi pjesëmarrjen e publikut në procesin e vendimmarrjes
                2015
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/raport_pjesmarrja_2016.pdf">
                -Raport mbi pjesëmarrjen e publikut në procesin e vendimmarrjes
                2016
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/2020_Pjes%C3%ABmarrja_Pubilkut.pdf">
                -Raport mbi pjesëmarrjen e publikut në procesin e vendimmarrjes
                2020
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raport_pjes%C3%ABmarrjen_2017.pdf">
                -Raport mbi pjesëmarrjen e publikut në procesin e vendimmarrjes
                2017
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raport%20mbi%20pjes%C3%ABmarrjen%20e%20publikut%20n%C3%AB%20procesin%20e%20vendimmarrjes%202019.pdf">
                -Raport mbi pjesëmarrjen e publikut në procesin e vendimmarrjes
                2019
              </a>
            </p>
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Raportishoqerisecivile2018.pdf">
                -Raport mbi pjesëmarrjen e publikut në procesin e vendimmarrjes
                2018
              </a>
            </p>
          </Panel>

          <Panel header="Mandatet parlamentare 2021-2025" key="4">
            <p>
              <FileTextOutlined />
              <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/mandatet.pdf">
                -Mandatet parlamentare 2021-2025
              </a>
            </p>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default BuxhetiFooter;
