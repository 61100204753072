import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React from "react";
import "./InstitutiParlamentar.scss";

const InstitutiParlamentar = () => {
  return (
    <>
      <DynamicHeader title="Instituti Parlamentar" />
      <div className="instituti-parl-wrapper">
        <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/IPinfografike.pdf">
          -Instituti Parlamentar - Infografikë
        </a>
        <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/insparlamentar.pdf">
          -Instituti Parlamentar
        </a>
      </div>
    </>
  );
};

export default InstitutiParlamentar;
