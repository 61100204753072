import React from "react";
import "./MemberCard.scss";
import { Card, Col } from "antd";
import { useHistory } from "react-router-dom";
import defaultImg from "../../../assets/images/header-backround.jpg";

const { Meta } = Card;

export default function MemberCard({ member }) {
  // console.log(member.fotoprofil);
  const history = useHistory();
  // const memberImg = member?.anetardokumentet
  //   ? member?.anetardokumentet?.find(
  //       (doc) => doc.Dokument.TipDokument.Emer.toLowerCase() === "fotoanetar"
  //     )?.Dokument?.Url
  //   : member?.fotoProfil;
  // console.log(member);

  return (
    <Col
      xs={24}
      sm={24}
      md={12}
      lg={6}
      span={6}
      className="gutter-row member-card"
    >
      <Card
        onClick={() => {
          history.push(`/deputet/${member.id}`);
        }}
        hoverable
        cover={
          <img
            alt={"image"}
            src={
              member?.fotoprofil
                ? member?.fotoprofil
                : member?.fotoProfil
                ? member?.fotoProfil
                : defaultImg
            }
          />
        }
      >
        <Meta
          title={member.emer + " " + member.mbiemer}
          description={member.email}
        />
      </Card>
    </Col>
  );
}
