import React, { useState, useEffect } from "react";
import "./Deputet.scss";
// import { deputet as data, partite, qarku } from "DummyData/DummyDeputet";
// import { useHistory } from "react-router";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import { Col, Row } from "antd";
import CustomTabsComp from "components/GlobalComponents/CustomTabs/CustomTabsComp";
import StrukturaList from "components/DeputetComponents/StrukturaList/StrukturaList";
import DeclarationList from "components/DeputetComponents/DeclarationList/DeclarationList";
import PostList from "components/DeputetComponents/PostList/PostList";
import AskDeputy from "components/DeputetComponents/AskDeputy/AskDeputy";
import { getItemApiMethod } from "API";
import { useParams } from "react-router-dom";
import { SocialLinks } from "components/GlobalComponents/SocialLinks/SocialLinks";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import { defineQarku } from "helpers/Denfiners";
import attachRed from "assets/images/attachmentRed.png";
import { useTranslation } from "react-i18next";
import defaultImg from "../../assets/images/header-backround.jpg";
import { TIP_DOK } from "helpers/Denfiners/definers";

export default function Deputet() {
  // const history = useHistory();
  const { id } = useParams();
  const [selectedDep, setSelectedDep] = useState();
  const [deputetData, setDeputetData] = useState();
  const [deklarime, setDeklarime] = useState([]);
  const [t] = useTranslation();
  // const struktura = new Array(10).fill(null).map(() => ({
  //   name: "Komisioni për Punën, Çështjet Sociale dhe Shëndetësinë zhvilloi disa seanca dëgjimore lidhur me projektbuxhetin 2022",
  //   url: faker.internet.url(),
  // }));

  // useEffect(() => {
  //   const deputetId = history.location.pathname.substr(9);
  //   const filter = data.filter((a) => a.id == deputetId)[0];
  //   setSelectedDep(filter || null);
  // }, []);
  const deputetTabs = [
    {
      tabName: t("tabs.structures"),
      tabContent: <StrukturaList list={selectedDep?.strukturat} />,
    },
    {
      tabName: t("tabs.posts"),
      tabContent: (
        <PostList
          twitterName={
            selectedDep?.id == "afb72233-f771-4b06-a51a-a42eaef51077" ||
            selectedDep?.id == "573f0a45-6cc7-4fc1-af79-26c19770f302"
              ? selectedDep?.mbiemer + selectedDep?.emer
              : selectedDep?.emer + selectedDep?.mbiemer
          }
        />
      ),
    },
    {
      tabName: t("tabs.declaration"),
      tabContent: <DeclarationList list={deklarime} />,
    },
  ];

  useEffect(() => {
    getItemApiMethod({ apiName: "anetaret", id }).then((res) => {
      // console.log(res.data);
      const deputet = res.data;
      // let deputetImg =
      //   deputet.dokumentat.find(
      //     (doc) => doc.tipDokument.emer.toLowerCase() === "fotoanetar"
      //   ) || "";
      let deputetImg = deputet.fotoProfil || "";
      const deklarimet =
        deputet.dokumentat.filter(
          (doc) => doc.tipDokument.emer === TIP_DOK.DEKLARATE
        ) || [];
      let deputetCv =
        deputet.dokumentat.find(
          (doc) => doc.tipDokument.emer.toLowerCase() === "cv"
        ) || "";
      let grupiParl = deputet.strukturat.find((str) => str.tip === 6) || "";
      setSelectedDep(res.data);
      setDeklarime(deklarimet);
      setDeputetData({ deputetImg, deputetCv, grupiParl });
    });
  }, [id]);
  // if (!selectedDep) {
  //   return <div>Loading...</div>;
  // }
  // console.log(selectedDep);
  // let deputetImg =
  //   selectedDep?.dokumentat?.find(
  //     (doc) => doc.tipDokument.emer.toLowerCase() === "fotoanetar"
  //   ) || "";
  // let deputetCv =
  //   selectedDep?.dokumentat?.find((doc) => doc.tipDokument.emer.toLowerCase() === "cv") ||
  //   "";
  // let grupiParl = selectedDep?.strukturat?.find((str) => str.tip === 6) || "";

  return (
    <div className="deputet-wrapper">
      <DynamicHeader title={t("graphSection.mps")} />
      <LoadingComp
        loading={!selectedDep}
        className="deputet-loading"
        foundData={selectedDep}
      >
        <Row className="content-wrapper">
          <Col xs={24} xl={6} className="sticky-pos-content">
            <img
              className="profile-pic"
              src={deputetData?.deputetImg || defaultImg}
            />
            <div className="deputet-content">
              <h2>
                {selectedDep?.emer} {selectedDep?.mbiemer}
              </h2>
              <div className="deputet-details">
                <h5>
                  <b>{t("mpsPage.district")}:</b>{" "}
                  {defineQarku(selectedDep?.qarku)}
                </h5>
                <h5>
                  <b>{t("mpsPage.politicalEntities")}:</b>
                  {selectedDep?.partia}
                </h5>
                <h6>*sipas shpalljes KQZ</h6>
                <h5>
                  <b>{t("mpsPage.group")}:</b> {deputetData?.grupiParl.emer}
                </h5>
                <h5>
                  <b>{t("mpsPage.email")}:</b>{" "}
                  <a href={`mailto:${selectedDep?.email}`}>
                    {selectedDep?.email}
                  </a>
                </h5>
                <h5>
                  <b>{t("mpsPage.birthplace")}:</b> {selectedDep?.vendlindje}
                </h5>
                <h5>
                  <b>
                    <a
                      href={deputetData?.deputetCv.url}
                      target="_blank"
                      rel="noreferrer"
                      className="jeteshkrimi-link"
                    >
                      {t("mpsPage.resume")}
                    </a>
                  </b>
                  <img src={attachRed} />
                </h5>
              </div>
              <SocialLinks className="profili-social-links" />
            </div>
          </Col>

          <Col xs={24} xl={12}>
            <CustomTabsComp
              {...{
                tabData: deputetTabs,
                verticalTabs: false,
              }}
            />
          </Col>
          <Col xs={24} xl={6} className="sticky-pos-content">
            <AskDeputy t={t} title />
          </Col>
          {/* <div className="fb-post" data-href="https://www.facebook.com/LinditaNikolla.official/posts/497402278420259" data-width="500" data-show-text="true"><blockquote cite="https://www.facebook.com/LinditaNikolla.official/posts/497402278420259" className="fb-xfbml-parse-ignore"><p>Kuvendin e Shqipërisë e përshëndeti Presidenti i Turqisë, z. Recep Tayyip Erdoğan.
          Turqia është partneri i 4 tregtar i...</p>Posted by <a href="https://www.facebook.com/LinditaNikolla.official">Lindita Nikolla</a> on&nbsp;<a href="https://www.facebook.com/LinditaNikolla.official/posts/497402278420259">Tuesday, January 18, 2022</a></blockquote></div> */}
        </Row>
      </LoadingComp>
    </div>
  );
}
