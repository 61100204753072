import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React, { useState, useEffect } from "react";
import "./ActTimeline.scss";
import { Timeline } from "antd";
// import { ClockCircleOutlined } from "@ant-design/icons";
// import moment from "moment";
// import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";
import Newsletter from "components/GlobalComponents/Newsletter/Newsletter";
import { useParams } from "react-router-dom";
import { getItemApiMethod } from "API";
import moment from "moment";
import { ClockCircleOutlined } from "@ant-design/icons";
import { FilePdfOutlined as FilePdfFilled } from "@ant-design/icons";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";
// const title = `Ligj nr. 109/2021 “Për ratifikimin e konventës së Këshillit të Europës për
//   bashkëprodhimin kinematografik (e rishikuar), Roterdam, 30.1.2017`;

// let customStep = (
//   <DynamicButton text={moment().calendar()} action={() => {}} type="view-act" />
// );

export const ActTimeline = () => {
  const { id } = useParams();
  const [akt, setAkt] = useState();
  const [t, i18n] = useTranslation();

  useEffect(() => {
    getItemApiMethod({ apiName: "aktet", id }).then((res) => {
      setAkt(res.data);
    });
  }, [id]);
  // console.log(akt);

  // if (akt.length === 0) {
  //   return <div>Loading</div>;
  // }
  // console.log(akt);
  const removeNumbersFromString = (title) => {
    return title ? title : "";
  };

  const getDeposition = (dokumentet) => {
    if (!dokumentet) {
      return {
        dokumentet: [],
        earliestDate: null,
      };
    }
    const depositionElements = [
      "Projektligji",
      "Projektvendimi",
      "Projektrezoluta",
      "Projektmarreveshja",
      "Projektdeklarata",
      "Raport vjetor",
      "Relacioni Shoqërues",
      "Marrëveshja",
      "Raporti i vlerësimit të ndikimit",
      "Konventa",
      "Marrëveshja Shtesë",
      "Tabela e përputhshmërisë",
      "Bashkelidhur",
      "Mocioni",
      "Pyetje",
      "Raportime",
      "Kërkesë për informacion",
      "Interpelanca",
    ];
    const docs = dokumentet.filter((el) =>
      depositionElements.includes(el?.tipDokument?.emer)
    );
    // const sortedDocs = docs.sort((a, b) =>
    //   moment(a.dateKrijimi).diff(moment(b.dateKrijimi))
    // );
    // const earliestDate = sortedDocs[0] ? sortedDocs[0].dateKrijimi : null;

    return {
      dokumentet: docs,
      earliestDate: akt.dateDepozitimi,
    };
  };
  const getShqyrtimKomision = (dokumentet) => {
    if (!dokumentet) {
      return {
        dokumentet: [],
        earliestDate: null,
      };
    }
    const depositionElements = [
      "Raporti i komisionit përgjegjës",
      "Raporti i komisionit për dhënie mendimi",
      "Tabela e konsultimit publik",
      "Amendamente të Depozituara në Komisionin përgjegjës",
      "Aneks",
      "Raporti i komisionit përgjegjës për rishqyrtimin e ligjit",
    ];
    const docs = dokumentet.filter((el) =>
      depositionElements.includes(el?.tipDokument?.emer)
    );
    const sortedDocs = docs.sort((a, b) =>
      moment(a.dateKrijimi).diff(moment(b.dateKrijimi))
    );
    const earliestDate = sortedDocs[0] ? sortedDocs[0].dateKrijimi : null;

    return {
      dokumentet: docs,
      earliestDate,
    };
  };
  const getShqyrtimSeance = (dokumentet) => {
    if (!dokumentet) {
      return {
        dokumentet: [],
        earliestDate: null,
      };
    }
    const depositionElements = [
      "Amendamente 24 orë përpara seancës plenare",
      "Votimi i deputetëve",
      "Ligji i miratuar",
      "VENDIM",
      "REZOLUTE",
      "LIGJ",
      "Dekreti për rishqyrtim ligji",
    ];
    const docs = dokumentet.filter((el) =>
      depositionElements.includes(el?.tipDokument?.emer)
    );
    const sortedDocs = docs.sort((a, b) =>
      moment(a.dateKrijimi).diff(moment(b.dateKrijimi))
    );
    const earliestDate = sortedDocs[0] ? sortedDocs[0].dateKrijimi : null;

    return {
      dokumentet: docs,
      earliestDate,
    };
  };

  return (
    <div className="act-timeline-wrapper">
      <DynamicHeader
        title={getTranslatedField(akt, i18n, "titull", "titullEn")}
      />
      <LoadingComp
        loading={!akt}
        className="loading-akte-timeline"
        foundData={akt}
      >
        <div className="act-timeline-pre-wrapper">
          <div className="act-timeline-content">
            <Timeline mode="alternate">
              <Timeline.Item
                // dot={customStep}
                dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                label={
                  getDeposition(akt?.dokumentet).earliestDate
                    ? moment(
                        getDeposition(akt?.dokumentet).earliestDate
                      ).format("DD/MM/YYYY")
                    : "-"
                }
              >
                <a target="_blank" rel="noreferrer">
                  {t("actsIndex.deposition")}
                </a>
                <div>
                  {getDeposition(akt?.dokumentet).dokumentet.map(
                    (el, index) => {
                      return (
                        <div
                          onClick={() => window.open(el.url, "_blank")}
                          className="link-file"
                          key={index}
                        >
                          <FilePdfFilled size={35} />
                          {removeNumbersFromString(el.fileName)}
                        </div>
                      );
                    }
                  )}
                </div>
              </Timeline.Item>
              <Timeline.Item
                // dot={customStep}
                dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                // label={
                //   getShqyrtimKomision(akt?.dokumentet).earliestDate
                //     ? moment(
                //         getShqyrtimKomision(akt?.dokumentet).earliestDate
                //       ).calendar()
                //     : "-"
                // }
              >
                <a target="_blank" rel="noreferrer">
                  {t("actsIndex.responsibleComission")}
                </a>
                <div>
                  {getShqyrtimKomision(akt?.dokumentet).dokumentet.map(
                    (el, index) => {
                      return (
                        <div
                          onClick={() => window.open(el.url, "_blank")}
                          className="link-file"
                          key={index}
                        >
                          <FilePdfFilled />
                          {removeNumbersFromString(el.fileName)}
                        </div>
                      );
                    }
                  )}
                </div>
              </Timeline.Item>
              <Timeline.Item
                // dot={customStep}
                dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                // label={
                //   getShqyrtimSeance(akt?.dokumentet).earliestDate
                //     ? moment(
                //         getShqyrtimSeance(akt?.dokumentet).earliestDate
                //       ).calendar()
                //     : "-"
                // }
              >
                <a target="_blank" rel="noreferrer">
                  {t("actsIndex.sessionView")}
                </a>
                <div>
                  {getShqyrtimSeance(akt?.dokumentet).dokumentet.map(
                    (el, index) => {
                      return (
                        <div
                          onClick={() => window.open(el.url, "_blank")}
                          className="link-file"
                          key={index}
                        >
                          {removeNumbersFromString(el.fileName)}
                        </div>
                      );
                    }
                  )}
                </div>
              </Timeline.Item>
            </Timeline>
          </div>
          <Newsletter {...{ type: "aktet", tip: 1, t }} />
        </div>
      </LoadingComp>
    </div>
  );
};

export default ActTimeline;
