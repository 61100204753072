import { Row, Col, Select, Input } from "antd";
import React, { useState, useEffect } from "react";
// import DynamicButton from "../DynamicButton/DynamicButton";
import "./Members.scss";
// import { useTranslation } from "react-i18next";
import MemberCard from "../MemberCard/MemberCard";
import AskDeputy from "components/DeputetComponents/AskDeputy/AskDeputy";

const { Option } = Select;
const { Search } = Input;

export default function Members({ heading, members, t }) {
  const [memberData, setMemberData] = useState(members);
  // const { t } = useTranslation();
  console.log(memberData);
  useEffect(() => {
    setMemberData(members);
  }, [members]);
  const searchByName = (e) => {
    var filteredMembers = members.filter(
      (member) =>
        member.emer
          .concat(" ", member.mbiemer)
          .toLowerCase()
          .search(e.target.value.toLowerCase()) != -1
    );
    setMemberData(filteredMembers);
  };
  const handleSortChange = (e) => {
    var sorted = memberData.sort((a, b) => {
      if (e == "descending") {
        return b.emer.localeCompare(a.emer);
      } else {
        return a.emer.localeCompare(b.emer);
      }
    });
    setMemberData([...sorted]);
  };
  // const handlePartyChange = (e) => {
  //   if (e > 0) {
  //     var filter = members.filter((a) => a.party == e);
  //     setMemberData(filter);
  //   } else {
  //     setMemberData(members);
  //   }
  // };
  // console.log(memberData);

  return (
    <Col className="members-wrapper">
      <Row className="mini-heading">
        <Col xs={24} sm={17}>
          <strong>
            <h2>{heading}</h2>
          </strong>
        </Col>
        <Col xs={24} sm={7} className="members-actions">
          <Search
            onChange={searchByName}
            enterButton
            placeholder="Kërko deputet"
          />
          <Select
            defaultValue="ascending"
            style={{ width: 120 }}
            onChange={handleSortChange}
          >
            <Option value="ascending">A-Z</Option>
            <Option value="descending">Z-A</Option>
          </Select>
          {/* <Select
            defaultValue={"0"}
            style={{ width: 120 }}
            // onChange={handlePartyChange}
          >
            <Option key={0} value={"0"}>
              {t("homeSections.membersSection.partyAll")}
            </Option>
            {partite.map((party) => {
              return (
                <Option key={party.id} value={party.id}>
                  {party.name}
                </Option>
              );
            })}
          </Select> */}
        </Col>
      </Row>
      <Row className="members-section">
        <Col span={20}>
          <Row
            // justify={"center"}
            gutter={[30, 30]}
            className="members-container"
          >
            {memberData
              ?.sort((a, b) => a.emer.localeCompare(b.emer))
              .map((member) => {
                return <MemberCard key={member.id} member={member} />;
              })}
          </Row>
        </Col>
        <Col span={4}>
          <AskDeputy members={memberData} title t={t} />
        </Col>
      </Row>
    </Col>
  );
}
