import React from "react";
import "./Zgjedhjet.scss";
import mandatetPhoto from "../../../../assets/images/mandatet.jpg";
import subjektet_zgjedhorePhoto from "../../../../assets/images/subjektet_zgjedhore.jpg";
import subjektet_zgjedhorePhoto1 from "../../../../assets/images/subjektet_zgjedhore_1.jpg";

export const Zgjedhjet = () => {
  return (
    <div>
      <div className="zgjedhjet-wrapper">
        <b>Zgjedhjet parlamentare</b>
        <div className="zgjedhjet-content">
          <div className="top_photos">
            <img src={subjektet_zgjedhorePhoto}></img>
            <img src={mandatetPhoto}></img>
          </div>
          <img
            src={subjektet_zgjedhorePhoto1}
            className="subjektet_photo"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Zgjedhjet;
