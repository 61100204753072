import React from "react";
import "./DynamicButton.scss";

export default function DynamicButton({ text, action, type = null }) {
  return (
    <div onClick={action} className={`dynamic-button ${type && type}`}>
      {text}
    </div>
  );
}
