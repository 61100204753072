export const drejtuesNeVite = [
  {
    name: "XHEMAL NAIPI",
    position: "(Kryetar i Këshillit Kombëtar, 1920)",
  },
  {
    name: "DHIMITËR KACIMBRA",
    position: "(Kryetar i Këshillit Kombëtar, 1920)",
  },
  {
    name: "PANDELI EVANGJELI",
    position: [
      "(Kryetar i Këshillit Kombëtar, 1920)",
      "(Kryetar i Senatit, 1925-1926)",
      "(Kryetar i Asamblesë Kushtetuese, 1928)",
      "(Kryetar i Parlamentit, 1928-1930, 1937-1939)",
    ],
  },
  {
    name: "ESHREF FRASHËRI",
    position: [
      "(Kryetar i Këshillit Kombëtar, 1922-1923)",
      "(Kryetar i Kuvendit Kushtetues, 1924)",
      "(Kryetar i Senatit, 1924-1925)",
    ],
  },
  {
    name: "PETRO POGA",
    position: "(Kryetar i Kuvendit Kushtetues, 1924)",
  },
  {
    name: "KOSTAQ KOTA",
    position: [
      "(Kryetar i Dhomës së Deputetëve, 1925-1928)",
      "(Kryetar i Parlamentit, 1930-1937)",
    ],
  },
  {
    name: "LEF NOSI",
    position: "(Kryetar i Kuvendit Kombëtar, 1943-1944)",
  },
  {
    name: "TERENC TOCI	",
    position: "(Kryetar i Këshillit të Epërm Fashist Koorporativ, 1940-1943)",
  },
  {
    name: "MIHAL ZALLARI",
    position: "(Kryetar i Kuvendit Kombëtar, 1944)",
  },
  {
    name: "OMER NISHANI",
    position: "(Kryetar i Këshillit Antifashist NacionalÇlirimtar, 1944)",
  },
  {
    name: "TUK JAKOVA",
    position: "(Kryetar i Kryesisë së Asamblesë Kushtetuese, 1946)",
  },
  {
    name: "Dr. YMER DISHNICA",
    position: "(Kryetar i Kryesisë së Asamblesë Kushtetuese, 1946-47)",
  },
  {
    name: "MANUSH MYFTIU",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1947-1949)",
  },
  {
    name: "GOGO NUSHI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1950, 1954-1956)",
  },
  {
    name: "THEODOR HEBA	",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1950-1951)",
  },
  {
    name: "MIHAL PRIFTI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1951-1954)",
  },
  {
    name: "RITA MARKO",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1956-1958)",
  },
  {
    name: "Dr. MEDAR SHTYLLA",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1958-1964)",
  },
  {
    name: "LEFTER GOGA",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1964-1966)",
  },
  {
    name: "ABDYL KËLLEZI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1966-1969)",
  },
  {
    name: "BEHAR SHTYLLA",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1969-1970)",
  },
  {
    name: "FADIL PAÇRAMI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1970-1973)",
  },
  {
    name: "FADIL PAÇRAMI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1970-1973)",
  },
  {
    name: "ILIAZ REKA",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1973-1976)",
  },
  {
    name: "ALI MANAJ",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1976-1978)",
  },
  {
    name: "SIMON STEFANI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1978-1982)",
  },
  {
    name: "PALI MISKA",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1982-1987)",
  },
  {
    name: "PETRO DODE	",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1987-1990)",
  },
  {
    name: "KASTRIOT ISLAMI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1991-1992)",
  },
  {
    name: "PJETËR ARBNORI",
    position: "(Kryetar i Kryesisë së Kuvendit Popullor, 1992-1996; 1996-1997)",
  },
  {
    name: "SKËNDER GJINUSHI",
    position: "(Kryetar i Kuvendit të RSH, 1997-2001)",
  },
  {
    name: "NAMIK DOKLE",
    position: "(Kryetar i Kuvendit të RSH, 2001-2002)",
  },
  {
    name: "SERVET PËLLUMBI",
    position: "(Kryetar i Kuvendit të RSH, 2002-2005)",
  },
  {
    name: "JOZEFINA TOPALLI (ÇOBA)",
    position: "(Kryetare e Kuvendit të RSH, 2005-2009; 2009-2013)",
  },
  {
    name: "ILIR META",
    position: "(Kryetar i Kuvendit të RSH, 2013-2017)",
  },
  {
    name: "GRAMOZ RUÇI",
    position: "(Kryetar i Kuvendit të RSH, 2017-2021)",
  },
  {
    name: "LINDITA NIKOLLA",
    position: "(Kryetare e Kuvendit të RSH, 2021-2024)",
  },
  {
    name: "ELISA SPIROPALI",
    position: "(Aktualisht Kryetare e Kuvendit të RSH)",
  },
];
