import React from "react";
import kuvendiLogo from "../../../assets/images/logo.png";
import "./LoadingComp.scss";

const LoadingComp = ({
  loading,
  children,
  LoaderComp = kuvendiLogo,
  noDataMsg = "Nuk ka rezultate",
  className = "",
  foundData,
}) =>
  loading ? (
    <div className={`loading-logo ${className}`.trim()}>
      <img src={LoaderComp} />
    </div>
  ) : foundData && Object.keys(foundData).length > 0 ? (
    children
  ) : (
    noDataMsg
  );

export default LoadingComp;
