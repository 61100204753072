import React, { useCallback, useState } from "react";
import { Input, AutoComplete } from "antd";
import "./SearchBar.scss";
import { useTranslation } from "react-i18next";
// import { UserOutlined } from '@ant-design/icons';
import { searchAllData } from "API";
// import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";
import {
  handleSearchTypes,
  handleUrlOfItemSelected,
  searchKeysPriorityOrder,
} from "helpers/Denfiners";

const renderTitle = (title) => <span>{title.toUpperCase()}</span>;

const renderItem = ({ title, id, type, score, source }) => ({
  value: title,
  id,
  type,
  score,
  source,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {title?.length > 30 ? `${title.substring(0, 30)}...` : title}
      {/* <span>
        <UserOutlined /> {count}
      </span> */}
    </div>
  ),
});

const options = ({ results }) => {
  let resultItems = results.map((item) => ({
    label: renderTitle(item.key),
    options: item.topHits.hits.hits.map((res) =>
      renderItem({
        title: Array.isArray(handleSearchTypes[res._index])
          ? handleSearchTypes[res._index]
              .map((item) => res._source[item])
              .join(" ")
          : res._source[handleSearchTypes[res._index]],
        id: res._id,
        type: res._index,
        score: res._score,
        source: res._source,
      })
    ),
  }));
  // console.log(resultItems);
  return resultItems;
};

// const options = ({ results }) => {    //previews function of
//   let resultItems = [];
//   for (const [key, values] of Object.entries(results)) {
//     resultItems.push({
//       label: renderTitle(key),
//       options: values.map((res) =>
//         renderItem({
//           title: Array.isArray(handleSearchTypes[res.index])
//             ? handleSearchTypes[res.index]
//                 .map((item) => res.source[item])
//                 .join(" ")
//             : res.source[handleSearchTypes[res.index]],
//           id: res.id,
//           type: res.index,
//           score: res.score,
//           source: res.source,
//         })
//       ),
//     });
//   }
//   // console.log(resultItems);
//   return resultItems;
// };

export default function SearchBar() {
  const history = useHistory();
  const [t] = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const onChange = useCallback(
    (value) => {
      if (value) {
        searchAllData({ value, size: 5 }).then((res) => {
          // let groupByType = res.data.reduce((r, a) => {
          //   r[a.index] = [...(r[a.index] || []), a];
          //   return r;
          // }, {});
          // delete groupByType["dokumentet"];
          // setResults(groupByType);
          let data = res.data.aggregations.groupByIndex.buckets;
          data.map(
            (item) => (item.order = searchKeysPriorityOrder[item.key].order)
          );
          data.sort((a, b) => a.order - b.order);
          setInputValue(value);
          setResults(data);
        });
      } else {
        setResults([]);
      }
    },
    [inputValue]
  );

  // const handleEnterKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     console.log('do validate');
  //   }
  // }

  // console.log(results);

  // const debouncedHandler = useMemo(debounce(onChange, 500), []);

  const inItemSelect = (value, options) => {
    // console.log(options);
    if (options.type !== "dokumentet") {
      history.push(`${handleUrlOfItemSelected[options.type]} ${options.id}`);
    } else {
      window.open(options.source.url, "_blank");
    }
  };

  const onHitSearchHandle = (e) => {
    // console.log('searched', e);
    history.push({
      pathname: "/search",
      value: e,
    });
  };

  // useEffect(() => {
  //   return () => {
  //     debouncedHandler.cancel();
  //   };
  // }, []);
  return (
    <div
      className="search-bar"
      // onKeyDown={handleEnterKeyPress}
    >
      <AutoComplete
        dropdownClassName="category-search-dropdown"
        dropdownMatchSelectWidth={300}
        options={options({ results })}
        notFoundContent="S'ka rezultat"
        onChange={onChange}
        onSelect={inItemSelect}
        listHeight="100%"
      >
        <Input.Search
          placeholder={t("navigation.searchPlaceholder")}
          onSearch={(e) => onHitSearchHandle(e)}
        />
      </AutoComplete>
    </div>
  );
}
