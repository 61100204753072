import React from "react";
import NewsRelatedComp from "./NewsRelatedComp/NewsRelatedComp";
import "./NewsRelated.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";
import { objectKeysToLowerCase } from "helpers/objectKeysToLowerCase";

export default function NewsRelated({ newsList, className = "" }) {
  const [t] = useTranslation();
  const history = useHistory();
  let transformedKeysArr = newsList?.map((item) => objectKeysToLowerCase(item));
  // console.log(newsList);
  return (
    // <div className="news-related-wrapper">
    <div className={`${className} news-related-cards`}>
      <strong>
        <h2>{t("newsPage.latestNews")}</h2>
      </strong>
      <div className="news-contents">
        {transformedKeysArr.map((news) => (
          <NewsRelatedComp key={news.id} data={news} />
        ))}
      </div>
      <DynamicButton
        text={t("navigation.more")}
        action={() => {
          history.push("/lajme");
        }}
        type={"secondary"}
      />
    </div>
    // </div>
  );
}
