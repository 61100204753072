export const roliData = ({ t }) => [
  {
    title: "",
    description: t("kuvendiMenu.role.subDescription"),
    videoUrl: "",
  },
  {
    title: t("kuvendiMenu.role.subHeader1"),
    description: t("kuvendiMenu.role.description1"),
    videoUrl: "",
  },
  {
    title: t("kuvendiMenu.role.subHeader2"),
    description: t("kuvendiMenu.role.description2"),
    videoUrl: "https://www.youtube.com/embed/YnJO_H4mNcU",
  },
  {
    title: t("kuvendiMenu.role.subHeader3"),
    description: t("kuvendiMenu.role.description3"),
    videoUrl: "",
  },
  {
    title: t("kuvendiMenu.role.subHeader4"),
    description: t("kuvendiMenu.role.description4"),
    videoUrl: "",
  },
];
