import React from "react";
import { Card } from "antd";
import "./Cards.scss";

export const Cards = ({ imgUrl, title, description }) => {
  const { Meta } = Card;
  return (
    <Card
      hoverable
      cover={
        <img
          //  src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
          src={imgUrl}
        />
      }
    >
      <Meta {...{ title, description }} />
    </Card>
  );
};
