import React, { useState } from "react";
import "./Footer.scss";
import logo from "assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { pageLinks } from "./footerData";
import { Row, Col } from "antd";
import { SocialLinks } from "../SocialLinks/SocialLinks";
import { useHistory } from "react-router";

export const Footer = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const [itemsCollapsed, setItemsCollapsed] = useState(
    pageLinks({ ...{ t } }).map((item, key) => ({ key, hidden: false }))
  );
  const handleVisibility = (i) => {
    let collapsedItemsCopied = [...itemsCollapsed];
    collapsedItemsCopied[i].hidden = !collapsedItemsCopied[i].hidden;
    setItemsCollapsed(collapsedItemsCopied);
  };
  const linkClicked = (url, routeUrl) => {
    // console.log(`"You have clicked" ${e}`);
    if (url !== "undefined") {
      // console.log(e);
      window.open(url, "_blank");
    } else if (routeUrl !== "undefined") {
      history.push(`/${routeUrl}`);
    }
  };
  return (
    <div className="footer-wrapper">
      <div className="footer-links">
        <div className="logo-part">
          <img
            src={logo}
            onClick={() => {
              history.push("/");
            }}
          />
          <div className="logo-name">
            {t("topHeader.header1")}
            <br />
            <b>{t("topHeader.header2")}</b>
          </div>
        </div>
        <Row className="links" gutter={{ xs: 8, sm: 16, md: 25 }}>
          {pageLinks({ ...{ t, history } }).map((item, key) => (
            <Col
              key={key}
              xs={item.size.xs}
              sm={item.size.sm}
              md={item.size.md}
            >
              <ul
                onClick={() => {
                  handleVisibility(key);
                }}
              >
                <li
                  className={
                    !itemsCollapsed[key].hidden
                      ? "collapsed-list-header"
                      : "non-collapsed-list-header"
                  }
                >
                  {item.title}
                </li>
              </ul>
              <ul
                className={
                  !itemsCollapsed[key].hidden ? "collapsed show" : "collapsed"
                }
              >
                {item.linkName.map((i, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => linkClicked(i.url, i.routeUrl)}
                    >
                      {i.text}
                    </li>
                  );
                })}
              </ul>
            </Col>
          ))}
          <SocialLinks className="footer-social-links" />
        </Row>
      </div>
      <div className="copyright">
        <p>Copyright {currentYear} Albanian Parliament</p>
        <p>
          Albanian Parliament, Bulevard “Dëshmorët e Kombit”, No. 4 – Tirana
        </p>
      </div>
    </div>
  );
};
