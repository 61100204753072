import i18next from "i18next";

const isEnglish = i18next.languages[0] === "en"; // Define isEnglish here

//const isEnglish = i18next.languages[0]; // Define isEnglish here
console.log(isEnglish);
console.log(i18next.language.startsWith("English"));
const footerData = {
  administration: {
    structure: {
      name: "Structure",
      routeUrl: "strukturafooter",
    },
    services: {
      name: "Services",
      routeUrl: "sherbimefooter",
    },
    legalActs: {
      name: "Legal Acts",
      routeUrl: "akteLigjoreFooter",
    },
    vacancy: {
      name: "Vacancies",
      url: "https://parlament.al/struktura/03653fa6-20fd-4fd7-8823-82be44b3a7b6/#doku",
    },
    budget: {
      name: "Budget",
      url: "https://parlament.al/struktura/67d6663d-5f74-4836-b7b8-ec0c88bf1419/#doku",
    },
    internalRegulations: {
      name: "Internal Regulations",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/202307311524154035Rregullore%20e%20brendshme.pdf",
    },
    contacts: {
      name: "Contacts",
      routeUrl: "kontakteFooter",
    },
    reports: {
      name: "Reports",
      url: "https://parlament.al/struktura/f5e7176c-d17e-4427-a627-c3e47e202e31/#doku",
    },
    alerts: {
      name: "On signaling and signal protection",
      routeUrl: "sinjalizimeFooter",
    },
    parliamentaryInstitutes: {
      name: "Parliamentary Institutes",
      url: "https://parlament.al/struktura/bb64f9f3-58fc-434d-95bd-b9d9a8cd0805",
    },
    CV: {
      name: "CV",
      url: "https://parlament.al/dokumenta/e1ff5ac9-0dac-4af0-bdc6-d7288e97bfc4/85cdd59f-98b9-488c-a498-cb486600bf15",
    },
  },
  parliamentarian: {
    deputies: {
      name: "Deputies",
      routeUrl: "deputet",
    },
    parliamentaryMandates: {
      name: "Parliamentary Mandates 2021-2025",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/mandatet.pdf",
    },
    assemblyDeputiesDistribution: {
      name: "Parliamentary Mandates 2021-2025",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/dptneorgkuvend.pdf",
    },
    presidenciesParliamentaryGroups: {
      name: "Presidencies of parliamentary groups",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/kryesite-e-grupeve-parlamentare.pdf",
    },
    parliamentaryDeputiesGroups: {
      name: "Deputies by parliamentary groups",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/Deputetet%20sipas%20grupeve%20parlamentare.pdf",
    },
    // deputiesOutsideParliamentary: {
    //   name: "Deputies outside parliamentary groups",
    //   url: "https://parlament.al/struktura/66f2e99c-7ce4-402f-8f3c-1389a20460c4",
    // },
    hallPlanning: {
      name: "Planning in the hall",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/salla.jpg",
    },
    // womenAllianceDeputies: {
    //   name: "Alliance of women MPs",
    //   url: "https://parlament.al/struktura/99aeb82d-58b5-4be3-9664-6385b0857abd",
    // },
    deputiesStatistics: {
      name: "Statistics for MPs",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/skedaemandateve.pdf",
    },
    deputiesConductCode: {
      name: "Code of Conduct for Deputies",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/Kodi%20i%20Sjelljes.pdf",
    },
    assemblyConductGuide: {
      name: "Detailed Assembly Conduct Guide",
      url: "https://parlament.al/struktura/449f3a8c-1e18-4f09-b39f-41aaf47a9f12/#doku",
    },
    deputiesDeclaration: {
      name: "Special statements of MPs",
      url: "https://parlament.al/struktura/a730b178-5434-4b03-9a12-b7254ef4b7cf",
    },
    // activitiesStatementsParticipation: {
    //   name: "Statements of MPs for participation in activities organized by third parties",
    //   url: "https://parlament.al/struktura/1cd4218c-b5cf-4a59-895f-b92291c0f5da/#doku",
    // },
    deputyFinancialExpenses: {
      name: "Financial expenses for each deputy",
      url: "https://parlament.al/struktura/67d6663d-5f74-4836-b7b8-ec0c88bf1419/#doku",
    },
  },
  transparency: {
    transDeclare: {
      name: "Transparency Statement",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/DeklarateTransparence.pdf",
    },
    assemblyActivityStatistics: {
      name: "Statistics for the activity of the Assembly",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/Buletini%20statistikor%202021-2025..pdf",
    },
    electronicRegister: {
      name: "Electronic Register of CSOs",
      url: "https://parlament.al/struktura/1f6ca8d8-fdf4-440b-869d-05ac3d0b915d/#doku",
    },
    publicParticipationManual: {
      name: "Manual of public participation in decision making",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/Manual%20Pjese%CC%88marrjes%20se%CC%88%20Publikut%20ne%CC%88%20Procesin%20Vendimmarrjes%20te%CC%88%20Kuvendit%20-%20Copy%20(2).pdf",
    },
    civilSocietyCoordinator: {
      name: "Civil Society Coordinator",
      url: "https://parlament.al/struktura/1f6ca8d8-fdf4-440b-869d-05ac3d0b915d",
    },
    transparencyProgram: {
      name: "Transparency Program",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/202209271039351628Programi i transparences.html",
    },
    publicParticipationReport: {
      name: "Report on public participation in the decision-making process",
      url: "https://parlament.al/struktura/1f6ca8d8-fdf4-440b-869d-05ac3d0b915d/#doku",
    },
    requestResponseRegister: {
      name: "Request and Response Register",
      url: "https://parlament.al/struktura/042d8e58-33d1-4697-8fa2-8e3ed2a98098/#doku",
    },
    documentationFees: {
      name: "Fees for providing and using documentation",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/VENDIMINR.70DATE04.05.2016-TARIFATPERSHERBIMINEDHENIESSEDOKUMENTACIONIT.pdf",
    },
    infoCoordinator: {
      name: "Coordinator for the right to information",
      url: "https://parlament.al/struktura/042d8e58-33d1-4697-8fa2-8e3ed2a98098",
    },
    massMediaRegulation: {
      name: "Media Regulation",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/202306061248544037RREGULLORE%20PER%20AKREDITIMIN%20E%20MASMEDIES.pdf",
    },
    registerOfAccreditation: {
      name: "Regjistri i Akreditimeve",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/Regjistri%20i%20akreditimeve.pdf",
    },
    monitoring: {
      name: "Manuali i Mbikeqyrjes dhe Kontrollit Parlamentar",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/202312111950174946Manuali%20i%20Mbikeqyrjes%20dhe%20Kontrollit%20Parlamentar.pdf",
    },
    onlineApply: {
      name: "Apply online",
      url: "https://parlament.al/struktura/8a98372e-3b0d-4b62-b4b8-262efa935e06",
    },
    newsArchive: {
      name: "News archive",
      routeUrl: "lajme",
    },
    formulars: {
      name: "Formularët e vetdeklarimit të subjekteve që mbartin detyrim ligjor sipas ligjit 138/2015",
      url: "https://parlament.al/struktura/a2c66c4d-9077-4ad2-86d5-3ea40537c8cb",
    },
  },
  forCitizens: {
    //assemblyRecognize: "To know the Assembly of Albania",
    //frequentlyQuestions: "Frequently Asked Questions",
    //assemblyVisit: "Visit the Assembly",
    visaApply: {
      name: "Apply for a visit",
      url: "https://parlament.al/struktura/8a98372e-3b0d-4b62-b4b8-262efa935e06",
    },
    //childrenAssembly: "Assembly for children",
    //library: "Biblioteka",
    parliamentaryPublications: {
      name: "Parliamentary Publications",
      url: "https://parlament.al/struktura/192408a4-458e-4dcf-abf2-144f43594d8a",
    },
    parliamentaryBisedime: {
      name: "Bisedimet Parlamentare",
      url: "https://bisedimet.parlament.al",
    },
    //multimediaGallery: "Multimedia Gallery",
    contacts: "Contacts",
    plenarySession: {
      name: "Plenary Session - Guide",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/Shiko%20dokumentin.pdf",
    },
    informativeBuletin: {
      name: "Buletini Infomativ i Diplomacise Shumepaleshe Janar - Qershor 2023",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/202309151314341807Buletini%20Infomativ%20i%20Diplomacise%20Shumepaleshe%20%20Janar%20-%20Qershor%202023.pdf",
    },
  },
  normativeActs: {
    kushtetutaDoc: {
      name: "Kushtetuta",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/kushtetuta.pdf",
      urlEn:
        "https://kuvendiwebfiles.blob.core.windows.net/webfiles/ANGLISHT.pdf",
    },
    regKuvendi: {
      name: "Rregullorja e Kuvendit të Republikës së Shqipërisë",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/RregullorjaeKuvenditeperditesuar.pdf",
    },
    electoralCode: {
      name: "Electoral Code",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/kodi%20zgjedhor.pdf",
    },
    deputyStatusLaw: {
      name: "Law on MP Status",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/ligji-per-statusin-e-deputetit.pdf",
    },
    investigativeCommissionsLaw: {
      name: "Law on Commissions of Inquiry",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/ligji-komisionet-hetimore.pdf",
    },
    internationalAgreementsLaw: {
      name: "Law on International Agreements",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/ligji%20per%20marreveshjet%20nderkombetare.pdf",
    },
    officialPublicationsLaw: {
      name: "Law on the Official Publications Center",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/ligji%20per%20qendren%20e%20botimeve%20zyrtare.pdf",
    },
    albaniaEuRoleLaw: {
      name: "Law on the role of the Assembly in the process of Albania's integration into the European Union",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/LigjrolinKuvenditProcesinIntegrimit.pdf",
    },
    albaniaEuRoleLawChanged: {
      name: "Ligji 19/2023 “Për disa shtesa dhe ndryshime në Ligjin nr.15/2015 për rolin e Kuvendit në procesin e integrimit të Republikës së Shqipërisë me Bashkimin Europian”",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/202305241519045596Ligj%20nr.%2019%20dt.%2016.3.2023.pdf",
    },
    constitutionalCourtLaw: {
      name: "Law on the Constitutional Court",
      url: "https://kuvendiwebfiles.blob.core.windows.net/webfiles/Ligj%20nr.%208577,%20date%CC%88%2010.2.2000%20%E2%80%9CPe%CC%88r%20organizimin%20dhe%20funksionimin%20e%20Gjykate%CC%88s%20Kushtetuese%20te%CC%88%20Republike%CC%88s%20se%CC%88%20Shqipe%CC%88rise%CC%88%E2%80%9D%20te%CC%88%20ndryshuar%E2%80%9D.pdf",
    },
  },
};

export const pageLinks = ({ t }) => [
  {
    size: {
      xs: 12,
      sm: 12,
      md: 4,
    },
    title: t("footer.administration.title"),
    linkName: Object.entries(footerData.administration).map((item) => {
      return {
        text: t(`footer.administration.${item[0]}`),
        url: `${item[1].url}`,
        routeUrl: `${item[1].routeUrl}`,
      };
    }),
  },
  {
    size: {
      xs: 12,
      sm: 12,
      md: 6,
    },
    title: t("footer.parliamentarian.title"),
    linkName: Object.entries(footerData.parliamentarian).map((item) => {
      return {
        text: t(`footer.parliamentarian.${item[0]}`),
        url: `${item[1].url}`,
        routeUrl: `${item[1].routeUrl}`,
      };
    }),
  },

  {
    size: {
      xs: 12,
      sm: 12,
      md: 5,
    },
    title: t("footer.transparency.title"),
    linkName: Object.entries(footerData.transparency).map((item) => {
      return {
        text: t(`footer.transparency.${item[0]}`),
        url: `${item[1].url}`,
        routeUrl: `${item[1].routeUrl}`,
      };
    }),
  },
  {
    size: {
      xs: 12,
      sm: 12,
      md: 4,
    },
    title: t("footer.forCitizens.title"),
    linkName: Object.entries(footerData.forCitizens).map((item) => {
      return {
        text: t(`footer.forCitizens.${item[0]}`),
        url: `${item[1].url}`,
        routeUrl: `${item[1].routeUrl}`,
      };
    }),
  },
  {
    size: {
      xs: 12,
      sm: 12,
      md: 5,
    },
    title: t("footer.normativeActs.title"),
    linkName: Object.entries(footerData.normativeActs).map((item) => {
      console.log(item[0]);
      return {
        text: t(`footer.normativeActs.${item[0]}`),
        //url: `${item[1].url}`,
        //url: isEnglish ? item[1].urlEn : item[1].url, // Use urlEn if the language is English, else use url
        url: t(`footer.normativeActs.${item[0] + "Url"}`),
        routeUrl: `${item[1].routeUrl}`,
      };
    }),
  },
];
