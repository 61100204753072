import { SearchItemsDynamicComp } from "components/SearchItemsDynamicComp/SearchItemsDynamicComp";
import React from "react";

export const SearchComp = ({ data }) => {
  // console.log(data);

  return (
    <div className="search-items-section">
      {data.map((item, key) => (
        <ol key={key}>
          <SearchItemsDynamicComp key={item.key} data={item} />
        </ol>
      ))}
    </div>
  );
};
