import React from "react";
import { LinksDataComp } from "./LinksDataComp/LinksDataComp";

const tabsContent = {
  deputetet: `Kuvendi i Shqipërisë përbëhet nga 140 deputetë të zgjedhur me sistem
    proporcional me zona zgjedhore shumemërore. Zonat përputhen me
    ndarjen administrative të një prej niveleve të organizimit
    administrativo-territorial, qarkut, sipas ligjit nr. 10.019, datë
    29.12.2008 “Kodi Zgjedhor i Republikës së Shqipërisë”. Zona
    zgjedhore shërben si njësi zgjedhore për zgjedhjen e një numri të
    caktuar mandatesh, sipas rregullave të përcaktuara në ligjin e
    mësipërm. Kufijtë administrativë të qarqeve vendosen me ligjin për
    ndarjen administrative e territoriale të Republikës së Shqipërisë.
    Deputetët përfaqësojnë zgjedhësit: fjala “deputet” do të thotë
    “përfaqësues”. Sipas dispozitave kushtetuese çdo deputet, edhe pse i
    zgjedhur në një zonë të caktuar zgjedhore dhe nga një parti politike
    e caktuar, përfaqëson të gjithë popullin.`,
  depSipasQarqeve: ["Lista e deputetëve sipas qarqeve"],
  mandatetParl: ["Mandatet parlamentare 2017-2021"],
  shperndarjaDep: [
    "Byroja e Kuvendit",
    "Sekretariatet",
    "Konferenca e Kryetarëve",
    "Këshilli për Rregulloren, Mandatet dhe Imunitetin",
    "Komisione hetimore",
    "Këshilli për Legjislacionin",
    "Komisionet e përhershme",
    "Nënkomisionet",
    "Komisione të posaçme",
  ],
  kryestiteGrupParl: ["Kryesitë e grupeve parlamentare"],
  grupetParl: [
    "Grupi Parlamentar i Partisë Socialiste",
    "Deputetë jashtë grupeve parlamentare",
    "Grupi Parlamentar 'Demokrat'",
    "Grupi Parlamentar “I pavarur”",
  ],
  orgVullnetare: ["Kuvendi për Fëmijët", "Aleanca e Grave Deputete"],
  infoStat: [
    "100 vjet Parlament",
    "Strategjia për edukimin ligjor të publikut",
    "Komisioni i pavarur për koordinimin, monitorimin dhe ndjekjen e zbatimit të ligjit nr. 115/2016 “Për organet e qeverisjes së sistemit të drejtësisë”",
    "Statistika - periudha e pandemisë së shkaktuar nga COVID-19",
    "Statistikat e veprimtarise se komisioneve parlamentare per periudhen Janar-Korrik 2020, sesioni VI i legjislatures I",
    "AKTIVITETI I SHËRBIMIT TË KOMISIONEVE JANAR – MARS 2019",
    "Statistikat e Sherbimit te Komisioneve per periudhen Janar-korrik 2019",
    "Buletini statistikor 2017-2021",
  ],
  buletiniInfo: ["Buletini Informativ Elektronik", "Buletini Javor i Kuvendit"],
};

export const legjislaturatData = [
  {
    tabName: "Deputetet",
    tabContent: tabsContent.deputetet,
  },
  {
    tabName: "Deputetët sipas qarqeve",
    tabContent: <LinksDataComp linksData={tabsContent.depSipasQarqeve} />,
  },
  {
    tabName: "Mandatet parlamentare 2017-2021",
    tabContent: <LinksDataComp linksData={tabsContent.mandatetParl} />,
  },
  {
    tabName: "Shpërndarja e deputetëve në organet e Kuvendit",
    tabContent: <LinksDataComp linksData={tabsContent.shperndarjaDep} />,
  },
  {
    tabName: "Kryesitë e grupeve parlamentare",
    tabContent: <LinksDataComp linksData={tabsContent.kryestiteGrupParl} />,
  },
  {
    tabName: "Grupet Parlamentare",
    tabContent: <LinksDataComp linksData={tabsContent.grupetParl} />,
  },
  {
    tabName: "Organizimet vullnetare",
    tabContent: <LinksDataComp linksData={tabsContent.orgVullnetare} />,
  },
  // {
  //   tabName: "Informacione dhe statistika për veprimtarinë e Kuvendit",
  //   tabContent: <LinksDataComp linksData={tabsContent.infoStat} />,
  // },
  // {
  //   tabName: "Buletini Informativ Elektronik",
  //   tabContent: <LinksDataComp linksData={tabsContent.buletiniInfo} />,
  // },
];
