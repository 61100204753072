export const longText = (text) => {
  var wordLength = text.length;
  if (wordLength <= 200) {
    return text;
  } else {
    return text.slice(0, 195) + "...";
  }
};

export const longTextAct = (text) => {
  var wordLength = text.length;
  if (wordLength <= 123) {
    return text;
  } else {
    return text.slice(0, 120) + "...";
  }
};
