export const tipDokmentet = [
  {
    id: "eaee92ec-853a-4f42-afc3-00adeddb1ac3",
    emer: "Strategjia për edukimin ligjor",
  },
  {
    id: "6d0fe50d-2fb4-4a1e-9ecd-03223684aa93",
    emer: "DEKLARATE",
  },
  {
    id: "9700a0a7-0615-4093-94b5-0835e5b65ccb",
    emer: "Projektligji",
  },
  {
    id: "4e04dad5-5224-44b4-88d7-0c56f705a28a",
    emer: "Raporti i komisionit për dhënie mendimi",
  },
  {
    id: "d329e99d-3ccc-4466-9713-1ca4e17c7432",
    emer: "VOTIM",
  },
  {
    id: "eaf1c030-83c7-443e-a877-1d06b9798614",
    emer: "Marrëveshja",
  },
  {
    id: "59491dbc-4616-41e8-b1cc-243c1fc7440f",
    emer: "Kërkesë për informacion",
  },
  {
    id: "7f3502a9-11d4-4f64-8216-27b2a527aa13",
    emer: "Regjistri i Peticioneve",
  },
  {
    id: "e4c53be7-9980-45b3-9f48-2c5a1bd0adf0",
    emer: "Dokumenta shoqërues të Seancës Plenare",
  },
  {
    id: "c3e79dcf-0ac7-47bd-b7f8-311ff46f1083",
    emer: "Tabela e konsultimit publik",
  },
  {
    id: "72226c6e-d209-4d4b-8e8e-31e7824a5c19",
    emer: "Dokumente KKIE",
  },
  {
    id: "4d875fea-dc9a-4a2d-a4c9-37efad44bfbc",
    emer: "Newsletter",
  },
  {
    id: "a1afb2f6-b213-434b-b98d-3916e603a238",
    emer: "Regjistri i kerkesave dhe pergjigjeve",
  },
  {
    id: "7b706d5c-b334-45c6-8401-399c3fbb8cca",
    emer: "Amendamente të Depozituara në Komisionin përgjegjës",
  },
  {
    id: "760a395e-8fdd-4a97-b007-3c41010f9cec",
    emer: "Formularë aplikimi",
  },
  {
    id: "a948ca32-d905-4f89-8b1d-3cb47c08f5c7",
    emer: "Formularë për shërbimin e publikut",
  },
  {
    id: "71374404-c34f-495a-a8f2-3da1d97412b9",
    emer: "Projektdeklarata",
  },
  {
    id: "a822ce0e-699d-4443-a941-467396b0aa33",
    emer: "Aneks",
  },
  {
    id: "e03a673e-2306-4728-adc8-47b537f5c659",
    emer: "Raporti i komisionit përgjegjës",
  },
  {
    id: "004b3590-a6dc-4572-8c97-4f7d6d3c1faf",
    emer: "Memorandumi i Mirëkuptimit",
  },
  {
    id: "9ac2b67f-966e-46f1-af85-52acd56e099e",
    emer: "Relacioni Shoqërues",
  },
  {
    id: "7e1e70cd-8e5a-4f70-88f1-55a51e3e5da7",
    emer: "Si kanë votuar Deputetët",
  },
  {
    id: "7b0b3ef0-fae8-4fd4-a290-58120a5469d3",
    emer: "Dokument Votim i Deputetëve për Rikthimin",
  },
  {
    id: "7587bd49-801e-40c9-aba2-5bbcf228a649",
    emer: "KALENDARIPUNIMEVE",
  },
  {
    id: "f6f63f16-584f-46fd-b202-5d39e9071c54",
    emer: "Formularë për shoqërinë civile",
  },
  {
    id: "b04fb3ad-a1fa-49dd-8b69-5eb6b88df16b",
    emer: "VENDIM",
  },
  {
    id: "5f34ea82-5e80-4d41-bfba-601412bb398f",
    emer: "Mocioni",
  },
  {
    id: "6086f76f-c0a0-4e1f-9977-6b4ab634dff7",
    emer: "Raportime",
  },
  {
    id: "52867e86-955f-4996-92f5-758b61bbf193",
    emer: "KERKESE",
  },
  {
    id: "6a69afba-eba0-4faf-8d36-761b0424f195",
    emer: "Raporti i vlerësimit të ndikimit",
  },
  {
    id: "59f472f5-92eb-4886-9a83-76d1ca895a26",
    emer: "Konventa",
  },
  {
    id: "661afc91-3863-4450-b69f-7f0c2ea4ff9e",
    emer: "Deklarime të deputetëve për pjesëmarrje në veprimtaritë e organizuara nga të tretë",
  },
  {
    id: "85001578-8341-4aaa-9fd8-7f54f2dc6abd",
    emer: "Kontrata e Konçesionit",
  },
  {
    id: "d7b70d1b-979a-40df-b1aa-8237555eb76f",
    emer: "Dekreti për rishqyrtim ligji",
  },
  {
    id: "007c261b-d399-43d8-bb88-845aea26f354",
    emer: "Votimi i deputetëve",
  },
  {
    id: "2cd8d8bf-73ab-402f-b213-874ba7f9563e",
    emer: "LIGJ",
  },
  {
    id: "acefea18-04f8-400a-a3a8-8e8eee59c142",
    emer: "Projektvendimi",
  },
  {
    id: "23a3d23c-687e-4dd8-9067-926d3c482a3d",
    emer: "Raporte",
  },
  {
    id: "ad26d3a2-ceb9-4a8e-80c6-9329bb2aff01",
    emer: "Programi i Transparences",
  },
  {
    id: "0d4ce188-f8ec-42b5-b87a-944fc2b72648",
    emer: "Shtojca",
  },
  {
    id: "34388088-7fdc-4afc-9a76-980e7f559933",
    emer: "Raporti i komisionit përgjegjës për rishqyrtimin e ligjit",
  },
  {
    id: "f5fb17c8-4e7a-4c36-8a67-99341c913e28",
    emer: "Bashkelidhur",
  },
  {
    id: "d9ebc726-89e1-4668-8758-9a9c8284da93",
    emer: "Projektrezoluta",
  },
  {
    id: "217f1280-0fba-4ab5-bbcf-a0d5150a599d",
    emer: "Marrëveshja Shtesë",
  },
  {
    id: "cc439e41-586a-45b0-a005-a505f6a141a4",
    emer: "PROCESVERBAL",
  },
  {
    id: "d1c80200-7847-415d-b677-a583f5e1d570",
    emer: "Protokolli",
  },
  {
    id: "97756fcb-b0c7-4c60-969a-ab07be7d0ad7",
    emer: "PROGRAMPUNE",
  },
  {
    id: "03c4d017-8756-421c-9244-ad1eb70353a2",
    emer: "Projektmarreveshja",
  },
  {
    id: "f15601f8-c1ee-4742-bfba-aebd62c8b85a",
    emer: "Ligji i miratuar",
  },
  {
    id: "d281b300-8f07-444f-b16e-c32f1852fefc",
    emer: "Udhëzuesi i Detajuar për Sjelljen në Kuvend",
  },
  {
    id: "82087d8b-dcce-4830-bdcb-c8d88cded624",
    emer: "Interpelanca",
  },
  {
    id: "e412568d-2679-4843-8f10-cdd45551c2df",
    emer: "Raporte të Komisionit Europian",
  },
  {
    id: "52b24501-19a7-46a2-8f20-d02db542c5b7",
    emer: "RAPORT",
  },
  {
    id: "6797b0f3-0f93-4b84-8dca-d3d1d9afd267",
    emer: "Ligji ekzitues që amendohet",
  },
  {
    id: "a87227db-8162-448e-b1c3-d4a76482aec9",
    emer: "Raport vjetor",
  },
  {
    id: "9e405b46-a84a-4621-ac26-d662da1529fd",
    emer: "Pyetje",
  },
  {
    id: "48c2f076-c59b-4ff5-a4cd-d75622d42490",
    emer: "Raporte Progresi KKIE",
  },
  {
    id: "70fb7b1f-d6b4-4f81-bf7a-d7b54a5c73db",
    emer: "Vënde të lira pune",
  },
  {
    id: "7883b592-aace-4848-b3ef-d8850410175f",
    emer: "MIRATUAR",
  },
  {
    id: "16d46750-03c3-4652-9991-d9bec6dcf949",
    emer: "Legjislacioni",
  },
  {
    id: "2a47190a-9bd8-4c4a-9570-db4536d8684f",
    emer: "Amendamente 24 orë përpara seancës plenare",
  },
  {
    id: "233c3131-0869-4c4f-9052-e525372cc635",
    emer: "PROJEKT",
  },
  {
    id: "94e65cf8-e7d0-4cf4-bc98-e82f254559be",
    emer: "Regjistrat elektronik",
  },
  {
    id: "fbb4ce63-a256-4cac-ab71-e8b2c9183bca",
    emer: "Raporte Paga dhe Përfitime",
  },
  {
    id: "b6c73959-b278-479e-a71b-fa1c97c702f8",
    emer: "Tabela e përputhshmërisë",
  },
  {
    id: "4a0481ea-cfd8-4c53-8f65-fb8f8514e051",
    emer: "REZOLUTE",
  },
  {
    id: "bda2bdb5-36a0-47d2-ab9f-fd200af1fdeb",
    emer: "Deklarime të veçanta të deputetëve në gjendje konflikti interesi",
  },
  {
    id: "521b5710-d9fe-4433-85e0-fff3ab0ed09a",
    emer: "Shkolla e Integrimit",
  },
];
