import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";
// import { dummyNews } from "DummyData/DummyNews";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./NewsLayout.scss";
import defaultImg from "../../../assets/images/header-backround.jpg";
import { longTextAct } from "helpers/longText";
import { getTranslatedField } from "helpers/contentTranslate";
// import Marquee from "react-fast-marquee";
// import { getNjoftimeHomepage } from "API";

export default function NewsLayout({ lajme }) {
  const [t, i18n] = useTranslation();
  const history = useHistory();

  // console.log(lajme, "CHECK LAJME");

  // useEffect(() => {
  //   getNjoftimeHomepage({ apiName: "lajmet" }).then((res) => {
  //     console.log(res.data);
  //   });
  // }, []);

  return (
    <div className="news-layout-wrapper">
      <div className="news-header">
        {t("homeSections.newsSection.latestNews")}
      </div>
      {/* <Marquee
        speed={300}
      >
        Test here
      </Marquee> */}
      <div className="news-layout-body">
        {lajme?.map((lajm) => {
          const lajmImg =
            lajm?.LajmDokumentet?.filter(
              (doc) =>
                doc.Dokument.TipDokument.Emer.toUpperCase() ===
                "FOTOKRYESORELAJMI"
            ) || [];
          // console.log(lajmImg);
          return (
            <div
              onClick={() => {
                history.push(`/lajme/${lajm.Id}`);
              }}
              key={lajm.Id}
              className="news-card"
            >
              <img
                src={lajmImg.length > 0 ? lajmImg[0].Dokument.Url : defaultImg}
              />
              <div className="news-overlay">
                <div className="news-card-title">
                  {longTextAct(
                    getTranslatedField(lajm, i18n, "Titull", "TitullEn")
                  )}
                </div>
                <div className="news-card-date">
                  {moment(lajm.DatePublikimi).format("DD/MM/YYYY")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="news-read-more">
        <DynamicButton
          text={t("navigation.more")}
          action={() => {
            history.push("/lajme");
          }}
          type={"secondary"}
        />
      </div>
    </div>
  );
}
