import React from "react";
import "./LigjvenesNeVite.scss";
import { FilePdfOutlined } from "@ant-design/icons";

export const LigjvenesNeVite = () => {
  return (
    <div className="ligjvenes-ne-vite-wrapper">
      <b>Ligjvënësit shqipëtarë në vite</b>
      <div className="ligjvenes-ne-vite-content">
        <FilePdfOutlined />
        <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/LigjvenesitNeVite.pdf">
          Ligjvënësit shqipëtarë në vite
        </a>
      </div>
    </div>
  );
};

export default LigjvenesNeVite;
