import React from "react";
import "./KryetariDetails.scss";
import { Avatar } from "antd";
// import { CaretRightOutlined } from "@ant-design/icons";
import dotsIcon from "../../../../assets/images/arch_of_dots.png";
import { useHistory } from "react-router";

const avatarSize = {
  xs: 100,
  sm: 130,
  md: 150,
  lg: 160,
  xl: 160,
  xxl: 170,
};

export const KryetariDetails = ({
  userIcon = true,
  description,
  title,
  kryetare = {},
}) => {
  const history = useHistory();

  const redirectToDeputets = () => {
    history.push("/deputet");
  };
  // console.log(kryetare);
  return (
    <div className="kryetari-details-wrapper">
      {userIcon ? (
        <Avatar
          size={avatarSize}
          onClick={() => history.push(`/deputet/${kryetare?.id}`)}
          src={
            kryetare?.dokumentat?.find(
              (doc) => doc.tipDokument.emer.toLowerCase() === "fotoanetar"
            )?.url
          }
        />
      ) : (
        <img
          className="dots"
          src={dotsIcon}
          onClick={() => redirectToDeputets()}
        />
      )}
      {userIcon ? (
        <strong>{title}</strong>
      ) : (
        <strong onClick={() => redirectToDeputets()} className="go-to-deeputet">
          {title}
        </strong>
      )}
      <p>{description}</p>
      {/* <span>
        <CaretRightOutlined />
        <h6>Go to website</h6>
      </span> */}
    </div>
  );
};
