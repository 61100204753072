export const historikuData = ({ t }) => [
  {
    title: "",
    description: t("kuvendiMenu.history.subDescription"),
  },
  {
    title: t("kuvendiMenu.history.subHeader1"),
    description: t("kuvendiMenu.history.description1"),
  },
  {
    title: t("kuvendiMenu.history.subHeader2"),
    description: t("kuvendiMenu.history.description2"),
  },
  {
    title: t("kuvendiMenu.history.subHeader3"),
    description: t("kuvendiMenu.history.description3"),
  },
  {
    title: t("kuvendiMenu.history.subHeader4"),
    description: t("kuvendiMenu.history.description4"),
  },
  {
    title: t("kuvendiMenu.history.subHeader5"),
    description: t("kuvendiMenu.history.description5"),
  },
  {
    title: t("kuvendiMenu.history.subHeader6"),
    description: t("kuvendiMenu.history.description6"),
  },
  {
    title: t("kuvendiMenu.history.subHeader7"),
    description: t("kuvendiMenu.history.description7"),
  },
  {
    title: t("kuvendiMenu.history.subHeader8"),
    description: t("kuvendiMenu.history.description8"),
  },
  {
    title: t("kuvendiMenu.history.subHeader9"),
    description: t("kuvendiMenu.history.description9"),
  },
  {
    title: t("kuvendiMenu.history.subHeader10"),
    description: t("kuvendiMenu.history.description10"),
  },
];
