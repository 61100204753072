import {
  getFilteredDokumentaSearch,
  getDokumenta,
  getFilteredDokumentaByTipDokId,
  getFilteredDokumentaByStruktureId,
} from "API";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import React, { useState, useEffect } from "react";
import { Form, message, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import "./DokSearch.scss";
import Filters from "./Filters/Filters";
import { ActCard } from "../../components/GlobalComponents/ActCard/ActCard";

export const DokSearch = () => {
  const [dokumenta, setDokumenta] = useState();
  const [current, setCurrent] = useState(1);
  //const [isSetCurrent] = useState(true);

  const [form] = Form.useForm();
  const [t] = useTranslation();

  const handleRecordsFilter = (
    _,
    page = 0,
    pageSize = 10,
    isSetCurrent = true
  ) => {
    const values = form.getFieldsValue();
    let { struktureId, tipDokId } = values;
    if (struktureId && tipDokId) {
      getFilteredDokumentaSearch({
        apiName: "strukturat",
        struktureId,
        tipDokId,
        page,
        pageSize,
      }).then((res) => {
        if (isSetCurrent) {
          setCurrent(1);
        }
        setDokumenta(res.data);
      });
    } else if (!struktureId && tipDokId) {
      getFilteredDokumentaByTipDokId({
        apiName: "dokumentet",
        tipDokId,
        page,
      }).then((res) => {
        if (isSetCurrent) {
          setCurrent(1);
        }
        setDokumenta(res.data);
      });
    } else if (struktureId && !tipDokId) {
      getFilteredDokumentaByStruktureId({
        apiName: "strukturat",
        struktureId,
        page,
      }).then((res) => {
        if (isSetCurrent) {
          setCurrent(1);
        }
        setDokumenta(res.data);
      });
    }

    console.log(struktureId, tipDokId);
  };

  const getOtherRecords = async (page, pageSize) => {
    const values = form.getFieldsValue();
    let { struktureId, tipDokId } = values;
    setCurrent(page);

    if (struktureId || tipDokId) {
      handleRecordsFilter({}, (page - 1) * pageSize, pageSize, false);
    } else {
      getDokumenta({
        apiName: "dokumentet",
        page: page - 1,
        pageSize,
      }).then((res) => {
        setDokumenta(res.data);
      });
    }
    console.log(struktureId, tipDokId);
  };

  const clearFilters = async () => {
    await getDokumenta({
      apiName: "dokumentet",
      page: 0,
    }).then((res) => {
      setDokumenta(res.data);
      message.success("Filtrat u pastruan me sukses");
      form.resetFields();
    });
  };

  useEffect(() => {
    getDokumenta({
      apiName: "dokumentet",
      page: 0,
    }).then((res) => {
      setDokumenta(res.data);
    });
  }, []);
  console.log(dokumenta);

  return (
    <div className="procesverbale-wrapper">
      <DynamicHeader title="Dokumenta" />
      <div className="procesverbale-content">
        <div className="procesverbale-fitlers">
          <b>{t("dokumentaPage.searchForRecord")}</b>
          <Filters {...{ form, handleRecordsFilter, clearFilters, t }} />
          <b>{t("dokumentaPage.recordList")}</b>
        </div>
        <LoadingComp loading={!dokumenta} foundData={dokumenta}>
          <div className="procesverbale-cards">
            {dokumenta?.map((dok, index) => (
              <ActCard
                key={index}
                id={dok.id}
                title={dok.fileName || dok.FileName}
                propozuesi={null}
                struktura={null}
                time={null}
                type={null}
                t={t}
                buttonText={t("dokumentaPage.download")}
                url={dok.url || dok.Url}
              />
            ))}
          </div>
        </LoadingComp>
        <Pagination
          current={current}
          defaultCurrent={1}
          disabled={!dokumenta?.length > 0}
          onChange={(page, pageSize) => getOtherRecords(page, pageSize)}
          showSizeChanger={false}
          total={1000}
        />
      </div>
    </div>
  );
};

export default DokSearch;
