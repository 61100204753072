import facebookIcon from "../../../assets/images/facebook.svg";
import twitterIcon from "../../../assets/images/twitter.png";
import liveIcon from "../../../assets/images/live.png";
import tvIcon from "../../../assets/images/tvIcon.png";

export const socialLinksData = [
  {
    icon: facebookIcon,
    color: "#1660AB",
    url: "https://www.facebook.com/kuvendishqiperise",
  },
  {
    icon: twitterIcon,
    color: "#03A9F4",
    url: "https://twitter.com/KuvendiSH",
  },
  {
    icon: liveIcon,
    color: "#96040B",
    url: "https://parlament.al/",
  },
  {
    icon: tvIcon,
    color: "#1CA9F4",
    url: "http://live.parlament.al/login.php?ref=Lw==",
  },
];
