import React, { useState } from "react";
import "./NavBar.scss";
import logo from "assets/images/logo.png";
import sq_language from "assets/images/sq-language.png";
import en_language from "assets/images/en-language.jpeg";
import { Menu, Avatar, Row, Col, Dropdown } from "antd";
import SearchBar from "components/GlobalComponents/SearchBar/SearchBar";
import LiveIcon from "components/Home/LiveIcon/LiveIcon";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import {
  //sekretariate,
  //komisionetParl,
  organeKuvendi,
  //keshillat,
  //nenKomisionet,
  grupetMiqesise,
} from "helpers/strukturaDefiners";
import { getTranslatedField } from "helpers";

const { SubMenu } = Menu;

export default function NavBar() {
  const history = useHistory();
  const [current, setCurrent] = useState("/");
  const { t, i18n } = useTranslation();
  const handleClick = (e) => {
    // console.log(e);
    history.push(e.key);
    setCurrent(e.key);
  };
  const changeLanguage = () => {
    if (i18n.language == "al") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("al");
    }
  };
  // console.log(current);
  const strukturaMenu = (
    <Menu onClick={handleClick} selectedKeys={[current]}>
      <Menu.Item key="/deputet/afb72233-f771-4b06-a51a-a42eaef51077">
        {t("navigation.chairman")}
      </Menu.Item>
      <Menu.Item key={`/struktura/${organeKuvendi[1].id}`}>
        {t("navigation.bureau")}
      </Menu.Item>
      <Menu.Item key="/struktura/eeae17fc-a34c-49f9-a9c2-cc7d8e13b940">
        {t("navigation.viceSpeakers")}
      </Menu.Item>
      <SubMenu title={t("navigation.secretariats")}>
        <Menu.Item key="/struktura/a77d8061-1f4f-4c74-b330-18cfc70fd78b">
          {t("navigation.itSecretariat")}
        </Menu.Item>
        <Menu.Item key="/struktura/c040b468-ecdc-44d8-973e-2f01d07974b8">
          {t("navigation.procSecretariat")}
        </Menu.Item>
        <Menu.Item key="/struktura/0776fff4-99ee-4865-8477-9e556589aaf6">
          {t("navigation.mpStatusSecretariat")}
        </Menu.Item>
        <Menu.Item key="/struktura/d462c5e6-81df-4216-9c18-a8a4977b1045">
          {t("navigation.researchSecretariat")}
        </Menu.Item>
        <Menu.Item key="/struktura/37a02df1-0660-49a5-96f4-bb43e1a528bd">
          {t("navigation.foreignSecretariat")}
        </Menu.Item>
        <Menu.Item key="/struktura/2de8f82d-e00c-4964-b917-e507088d632a">
          {t("navigation.budgetSecretariat")}
        </Menu.Item>
      </SubMenu>
      <Menu.Item key={`/struktura/${organeKuvendi[2].id}`}>
        {t("navigation.conference")}
      </Menu.Item>
      <SubMenu title={t("navigation.councils")}>
        <Menu.Item key="/struktura/93143c27-443b-4f35-89a9-a39d2206bb9d">
          {t("navigation.mandateCounsil")}
        </Menu.Item>
        <Menu.Item key="/struktura/c847a326-44f9-4f86-9c8d-ec72d77de02c">
          {t("navigation.legCounsil")}
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="">{t("navigation.delegations")}</Menu.Item>
      <SubMenu title={t("navigation.parliamentaryGroups")}>
        <Menu.Item key="/struktura/d6bd0f24-a665-46be-abf0-f8bffb5671ab">
          {t("navigation.grSoc")}
        </Menu.Item>
        <Menu.Item key="/struktura/cb2beaf3-32ee-4f51-85a7-b8645b89617e">
          {t("navigation.grDem")}
        </Menu.Item>
        <Menu.Item key="/struktura/725732ce-d21b-46c4-bc18-7b573a3341d4">
          {t("navigation.grDemAndIntg")}
        </Menu.Item>
        <Menu.Item key="/struktura/9e7de8aa-3660-4bb0-a0b4-3c20d19ee4d0">
          {t("navigation.grAlNdryshim")}
        </Menu.Item>
        <Menu.Item key="/struktura/66f2e99c-7ce4-402f-8f3c-1389a20460c4">
          {t("navigation.grJasht")}
        </Menu.Item>
      </SubMenu>
      <SubMenu title={t("navigation.committees")}>
        <Menu.Item key="/struktura/0cf43cc6-e63a-4d9d-8bdf-3ecc1429e743">
          {t("navigation.lawComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/6659e9fe-9ba3-418e-8960-47480d7f5985">
          {t("navigation.healthComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/b0b6142e-08af-4aaa-9fe8-4915e62cbf3a">
          {t("navigation.prodComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/ee21f12d-3f78-42b6-8cfe-870b51331cb1">
          {t("navigation.foreignComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/21eb4d1a-8b6e-4cd9-b584-a8554732a9ab">
          {t("navigation.securityComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/2bc4536d-d197-4e8a-af43-b06ba9cf34e2">
          {t("navigation.euComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/c1cc21fe-59c4-48ea-b42b-bf44ed9aa7e1">
          {t("navigation.eduComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/c314af43-615d-44b8-b63c-d0c4cdb109f4">
          {t("navigation.ecoComm")}
        </Menu.Item>
      </SubMenu>
      <SubMenu title={t("navigation.subCommittees")}>
        <Menu.Item key="/struktura/2181a753-ef5e-46d2-b690-28e81a86c673">
          {t("navigation.humanSubComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/e200931a-6de3-471d-8a1f-4a27e5562f0a">
          {t("navigation.adminSubComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/f7b6c7ef-e6b3-4827-b191-73185d953770">
          {t("navigation.diasporaSubComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/d1d56719-80b6-4a1e-92df-b1b0314d0e00">
          {t("navigation.devSubComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/ee89f751-6844-4c9a-bb81-e973e1630ead">
          {t("navigation.localSubComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/05f69e06-3c8f-431b-8de6-6a4c3b6c900f">
          {t("navigation.mjedisSubComm")}
        </Menu.Item>
        {/* <Menu.Item key="/struktura/c0840b24-b12e-4453-af3d-5e5e4a635e6d">
          {t("navigation.klgjklpAvokatiaSubComm")}
        </Menu.Item>
        <Menu.Item key="/struktura/95f6f754-8383-4dfb-ab5a-01583061d138">
          {t("navigation.klgjklpCSOSubComm")}
        </Menu.Item> */}
        <Menu.Item key="/struktura/f15baac2-02dd-4ed9-88b7-bfcd7b273f20">
          {t("navigation.auditPublicSectorSubComm")}
        </Menu.Item>
      </SubMenu>
      <SubMenu title={t("navigation.investigativeCommittees")}>
        {/* <Menu.Item key="/struktura/d8b0b1bf-ef1b-456e-aba8-b8a37a4d7125">
          {t("navigation.invComMbetjet")}
        </Menu.Item> */}
        <Menu.Item key="/struktura/c6e6e070-54e5-466c-9e35-f0ec3f2237d8">
          {t("navigation.invComTIMS")}
        </Menu.Item>
        <Menu.Item key="/struktura/2fb0a4c4-fc05-4a2b-9a5f-27395d4d8de2">
          {t("navigation.invComCeckup")}
        </Menu.Item>
      </SubMenu>
      <SubMenu title={t("navigation.specialCommittees")}>
        <Menu.Item key="/struktura/477ca4e3-f341-46b3-a216-86ecfe1d8817">
          {t("navigation.specialCommitteesIntegration")}
        </Menu.Item>
        <Menu.Item key="/struktura/16322ac8-aa61-4f89-bc7e-615b793eb3ea">
          {t("navigation.specialCommitteesRefZgjedh")}
        </Menu.Item>
        <Menu.Item key="/struktura/e05b8c81-e19e-4a1d-8a4e-234233dd09e3">
          {t("navigation.specialCommitteesThelRef")}
        </Menu.Item>
        <Menu.Item key="/struktura/8e230ff7-5344-45fb-bd6c-510f927c6b92">
          {t("navigation.specialCommitteesDesInfo")}
        </Menu.Item>
      </SubMenu>

      {/* <Menu.Item key="">{t("navigation.parliamentaryGroups")}</Menu.Item> */}
    </Menu>
  );

  const dokumentacioniMenu = (
    <Menu onClick={handleClick} selectedKeys={[current]}>
      <Menu.Item key="/dokumentacioni/aktet">{t("navigation.act")}</Menu.Item>
      <Menu.Item key="/dokumentacioni/aktecat/Raportime">
        {t("navigation.reports")}
      </Menu.Item>
      <Menu.Item key="/procesverbale">{t("navigation.records")}</Menu.Item>
      <Menu.Item key="/doksearch">{t("navigation.doc")}</Menu.Item>
      <Menu.Item key="/struktura/d453bfb5-1086-4b4b-88bd-38d37154849c">
        {"Dokumente te deklasifikuara"}
      </Menu.Item>
    </Menu>
  );

  const kuvendiMenu = (
    <Menu onClick={handleClick} selectedKeys={[current]}>
      <Menu.Item key="/kuvendi/historiku">
        <a>{t("navigation.historiku")}</a>
      </Menu.Item>
      <Menu.Item key="/kuvendi/drejtues-ne-vite">
        {t("navigation.drejtues")}
      </Menu.Item>
      <Menu.Item key="/kuvendi/ligjvenes-shqiptar-ne-vite">
        {t("navigation.ligjvenes")}
      </Menu.Item>
      <Menu.Item key="/kuvendi/legjislaturat">
        {t("navigation.legjislatura")}
      </Menu.Item>
      {/* <Menu.Item key="/kuvendi/kushtetuta">
        {t("navigation.kushtetuta")}
      </Menu.Item>
      <Menu.Item key="/kuvendi/rregullorja">
        {t("navigation.procedRules")}
      </Menu.Item> */}
      <Menu.Item key="/kuvendi/zgjedhjet-parlamentare">
        {t("navigation.parlElections")}
      </Menu.Item>
      <Menu.Item key="/kuvendi/funksionet/roli">
        {t("navigation.functions")}
      </Menu.Item>
      {/* <Menu.Item key="/kuvendi/funksionet/projektLigjLigj">
        {t("navigation.draftLaw")}
      </Menu.Item>
      <Menu.Item key="/kuvendi/funksionet/kontrolli">
        {t("navigation.controling")}
      </Menu.Item> */}
      <Menu.Item key="/kuvendi/ndertesa">{t("navigation.building")}</Menu.Item>
    </Menu>
  );

  const dipomaciaMenu = (
    <Menu onClick={handleClick} selectedKeys={[current]}>
      <SubMenu title={t("navigation.grMiqesise")}>
        {grupetMiqesise
          .sort((a, b) => a.emer.localeCompare(b.emer))
          .map((str) => (
            <Menu.Item key={`/struktura/${str.id}`}>
              {getTranslatedField(str, i18n, "emer", "emerEn")}
            </Menu.Item>
          ))}
      </SubMenu>
      <Menu.Item key={`/struktura/cc50f7db-e548-48dd-8fcd-483df5964145`}>
        {t("navigation.sapc")}
      </Menu.Item>
      <Menu.Item key={`/struktura/3ef91f2c-fc5c-4be5-803c-ec9c5b4a4c76`}>
        {t("navigation.preAccessionActionUnit")}
      </Menu.Item>
      <Menu.Item key={`/struktura/4e183343-b4cb-49fb-a667-4788c6739ee1`}>
        {t("navigation.pace")}
      </Menu.Item>
      <Menu.Item key={`/struktura/c03848be-eee0-4a2f-be07-8804817c18d8`}>
        {t("navigation.nato")}
      </Menu.Item>
      <Menu.Item key={`/struktura/da4518f4-1e36-458f-8ff5-cffa4b447f38`}>
        {t("navigation.osce")}
      </Menu.Item>
      <Menu.Item key={`/struktura/4d948e6b-06c2-41c6-9210-ad0cd9dbf10d`}>
        {t("navigation.paseecp")}
      </Menu.Item>
      <Menu.Item key={`/struktura/d4eb17fa-e3b2-4f2d-91c8-8c834bb0a18d`}>
        {t("navigation.pabsec")}
      </Menu.Item>
      <Menu.Item key={`/struktura/e401bf5d-3d08-46f5-bd1d-bc90eb27a471`}>
        {t("navigation.pam")}
      </Menu.Item>
      <Menu.Item key={`/struktura/5f64d47c-e670-46aa-8235-f8f41de64daa`}>
        {t("navigation.apf")}
      </Menu.Item>
      <Menu.Item key={`/struktura/53d1f17b-04d5-4d21-b62a-51bc054711bd`}>
        {t("navigation.paufm")}
      </Menu.Item>
      <Menu.Item key={`/struktura/0a13945b-d9ae-4b90-8281-4c4005cd4d5b`}>
        {t("navigation.dcei")}
      </Menu.Item>
      <Menu.Item key={`/struktura/cc969a27-a375-4bd1-9653-7eec2bdc88d5`}>
        {t("navigation.aii")}
      </Menu.Item>
      <Menu.Item key={`/struktura/7d47eba5-f758-4a71-a5fe-92e9fb0d118e`}>
        {t("navigation.ipu")}
      </Menu.Item>
      {/* <Menu.Item key={`/struktura/c10b7f30-4d25-468d-a7e5-094e7fece560`}>
        {t("navigation.ecprd")}
      </Menu.Item> */}
      <Menu.Item key="two">
        <a
          href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/202309151314341807Buletini%20Infomativ%20i%20Diplomacise%20Shumepaleshe%20%20Janar%20-%20Qershor%202023.pdf"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Buletini Informativ
        </a>
      </Menu.Item>
    </Menu>
  );
  const integrationMenu = (
    <Menu onClick={handleClick} selectedKeys={[current]}>
      <Menu.Item key={`/struktura/cb7c3a17-5b45-42db-9d58-a866b432345a`}>
        {t("navigation.ncei")}
      </Menu.Item>
      <Menu.Item key={`/struktura/956895cb-94da-4bd9-93d5-d06f4d9b8f19`}>
        {t("navigation.pcs")}
      </Menu.Item>
      <Menu.Item key={`/struktura/2bc4536d-d197-4e8a-af43-b06ba9cf34e2`}>
        {t("navigation.cei")}
      </Menu.Item>
      <Menu.Item key={`/struktura/3a38cf6e-d981-4e73-94d0-7bd3abc28703`}>
        {t("navigation.cosap")}
      </Menu.Item>
      <Menu.Item key={`/struktura/87c261ad-a8f0-4879-9cb9-183a4bab5f44`}>
        {t("navigation.cosac")}
      </Menu.Item>
      <Menu.Item key={`/struktura/7158d48a-222e-4e25-8f58-1ac600e3c7ce`}>
        {t("navigation.ai")}
      </Menu.Item>
      <Menu.Item
        key={`/dokumenta/1bed546b-10e7-4aca-aff1-4e9912d450cd/e8a31d20-9a16-49e7-b662-d463f72fab69`}
      >
        {t("navigation.intreports")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header">
      {/* <marquee>
        <a href="https://staging.parlament.al">
          Për çdo mungesë informacioni drejtohuni te faqja arkivë,
          https://staging.parlament.al
        </a>
      </marquee> */}
      <Row className="pre-nav-section">
        <Col sm={24} md={12} className="logo-part">
          <img
            src={logo}
            onClick={() => {
              history.push("/");
            }}
          />
          <div className="logo-name">
            {t("topHeader.header1")}
            <br />
            <b>{t("topHeader.header2")}</b>
          </div>
        </Col>
        <Col sm={24} md={12} lg={8} className="right-part">
          <div className="search-wrapp">
            <SearchBar {...{ history }} />
          </div>
          <div className="live-wrapp">
            <LiveIcon />
          </div>
          <div onClick={changeLanguage} className="lang-wrapp">
            <Avatar
              onC
              size={40}
              icon={
                <img src={i18n.language == "al" ? en_language : sq_language} />
              }
            />
          </div>
        </Col>
      </Row>
      <div className="navbar-wrapper">
        <a onClick={() => history.push("/")}>{t("navigation.home")}</a>
        <Dropdown overlay={kuvendiMenu} placement="bottomLeft">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {t("homeSections.homeHeader.assembly")} <DownOutlined />
          </a>
        </Dropdown>
        <Dropdown overlay={strukturaMenu} placement="bottomLeft">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {t("strukturaSections.strukturaHeader")} <DownOutlined />
          </a>
        </Dropdown>
        <Dropdown overlay={dokumentacioniMenu} placement="bottomLeft">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {t("navigation.documentation")} <DownOutlined />
          </a>
        </Dropdown>
        <Dropdown overlay={dipomaciaMenu} placement="bottomLeft">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <a>{t("navigation.parliamDiplomacy")}</a> <DownOutlined />
          </a>
        </Dropdown>
        <Dropdown overlay={integrationMenu} placement="bottomLeft">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <a>{t("navigation.euIntegration")}</a> <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
  );
}
