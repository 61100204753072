import React from "react";
import { KryetariDetails } from "./KryetariDetails/KryetariDetails";
import "./KryetariGraph.scss";
import kuvendiIcon from "../../../assets/images/parlament_tansparent_logo.png";
import { KryetariItems } from "./KryetariItems/KryetariItems";
import LoadingComp from "../LoadingComp/LoadingComp";
import { useHistory } from "react-router-dom";
import {
  sekretariate,
  komisionetParl,
  organeKuvendi,
  keshillat,
  administrata,
  bodiesGov,
} from "helpers/strukturaDefiners";
import { useTranslation } from "react-i18next";

export const KryetariGraph = ({ kryetare }) => {
  // console.log(kryetare);
  const history = useHistory();
  const [t] = useTranslation();
  return (
    <>
      <div className="kryetari-graph-wrapper">
        <div className="kryetari-graph-content">
          <LoadingComp loading={!kryetare} foundData={kryetare}>
            <div className="top-section">
              <KryetariDetails
                title={`${kryetare?.emer.concat(" ", kryetare?.mbiemer)}`}
                kryetare={kryetare}
                description={t("graphSection.speaker")}
              />
            </div>
          </LoadingComp>

          <div className="middle-circle-section">
            <div className="side-details">
              <KryetariItems
                positionedLeft={false}
                text={t("graphSection.bureau")}
                options={organeKuvendi}
                // number={140}
              />
              <KryetariItems
                positionedLeft={false}
                text={t("graphSection.committees")}
                options={komisionetParl}
              />
              <KryetariItems
                positionedLeft={false}
                text={t("graphSection.bodiesGov")}
                options={bodiesGov}
              />
            </div>
            <div className="circle">
              <div
                className="circle-content"
                onClick={() => {
                  history.push(
                    "/struktura/332f2692-f708-41aa-8059-2b8d2143bca1"
                  );
                }}
              >
                <img src={kuvendiIcon}></img>
                <h4>{t("graphSection.plenarySession")}</h4>
              </div>
              <div className="rad-1"></div>
              <div className="rad-2"></div>
              <div className="rad-3"></div>
              <div className="rad-4"></div>
              <div className="rad-5"></div>
              <div className="rad-6"></div>
            </div>
            <div className="side-details">
              <KryetariItems
                text={t("graphSection.secretaries")}
                options={sekretariate}
                // number={3}
              />
              <KryetariItems
                text={t("graphSection.council")}
                options={keshillat}
                // number={8}
              />
              <KryetariItems
                text={t("graphSection.administration")}
                options={administrata}
              />
            </div>
          </div>
          <div className="bottom-section">
            <KryetariDetails
              userIcon={false}
              title={t("graphSection.mps")}
              // description="Kuvendi perbehet nga 140 deputete"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default KryetariGraph;
