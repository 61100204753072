import React from "react";
// import { Row, Col } from "antd";
// import { data } from "../../../DummyData/LatestActs";
import "./LatestActs.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as GroupIcon } from "../../../assets/images/group.svg";
import PropozuesDeputet from "../../../assets/images/propozues_deputet.png";
import PropozuesKryeministria from "../../../assets/images/propozues_kryeministria.png";
import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";
import { useHistory } from "react-router";
import { longTextAct } from "helpers/longText";
import { definePropozuesi } from "helpers/Denfiners";
import { getTranslatedField } from "helpers";
// import { defineRelationship } from "helpers/Denfiners";

// const { Paragraph } = Typography;

export const LatestActs = ({ akte }) => {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  // console.log(akte);
  return (
    <div className="latest-acts-wrapper">
      <h3>{t("navigation.acts")}</h3>
      {akte?.map((akt, i) => (
        <div
          key={i}
          // gutter={{ xs: 10, sm: 20, md: 40, lg: 60 }}
          // justify="space-between"
          className="act-content"
          onClick={() => {
            history.push(`/dokumentacioni/aktet/${akt.Id}`);
          }}
        >
          <h4
          // span={8}
          // xs={24}
          // sm={24}
          // md={12}
          // lg={akt.AktStrukturat.length > 0 ? 8 : 15}
          // xl={akt.AktStrukturat.length > 0 ? 8 : 15}
          >
            {longTextAct(getTranslatedField(akt, i18n, "Titull", "TitullEn"))}
          </h4>
          {/* {akt.AktStrukturat.length > 0 ? (
            <div className="relationship">
              <Col span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
              {defineRelationship(akt.AktStrukturat[0].Relationship)}
              </Col>
            </div>
          ) : null} */}
          <span>
            {akt.Propozuesi == 0 ? (
              <img src={PropozuesDeputet} width={"60px"} />
            ) : akt.Propozuesi == 1 ? (
              <img src={PropozuesKryeministria} width={"60px"} />
            ) : (
              <GroupIcon />
            )}
            <h5>{definePropozuesi(akt.Propozuesi)}</h5>
          </span>
        </div>
      ))}
      <DynamicButton
        text={t("navigation.more")}
        action={() => {
          history.push("/dokumentacioni/aktet");
        }}
        type="secondary"
      />
    </div>
  );
};
