import React from "react";
import "./Rregullorja.scss";
import { FilePdfOutlined } from "@ant-design/icons";
import kushtetutaImage from "../../../../assets/images/kushtetuta.png";

export const Rregullorja = () => {
  return (
    <div>
      <div className="rregullorja-wrapper">
        <b>Rregullorja e Kuvendit të Republikës së Shqipërisë</b>
        <div className="rregullorja-img-wrapper">
          <img src={kushtetutaImage}></img>
        </div>
        <div className="rregullorja-content">
          <FilePdfOutlined />
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/RregullorjaeKuvenditeperditesuar.pdf">
            Rregullorja e Kuvendit të Republikës së Shqipërisë
          </a>
        </div>
      </div>
    </div>
  );
};

export default Rregullorja;
