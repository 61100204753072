import React from "react";
import "./DrejtuesNeVite.scss";
import { drejtuesNeVite } from "./DrejtuesNeViteData";

export const DrejtuesNeVite = () => {
  return (
    <div className="drejtues-ne-vite-wrapper">
      <b>Drejtues në vite</b>
      <div className="drejtues-ne-vite-content">
        <table>
          {drejtuesNeVite.map((drejtues, index) => (
            <tr key={index}>
              <td>{index + 1}.</td>
              <td>{drejtues.name}</td>
              <td>
                {Array.isArray(drejtues.position)
                  ? drejtues.position.map((d, i) => <span key={i}>{d}</span>)
                  : drejtues.position}
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default DrejtuesNeVite;
