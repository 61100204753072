import React from "react";
import "./ProjektLigjLigj.scss";
import { projektLigjLigjData } from "./ProjektLigjLigjData";

export const ProjektLigjLigj = () => {
  return (
    <div className="projektligjligj-wrapper">
      <b>Si një projektligj bëhet ligj</b>
      <div className="projektligjligj-content">
        {projektLigjLigjData.map((item) => (
          <>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </>
        ))}
      </div>
    </div>
  );
};

export default ProjektLigjLigj;
