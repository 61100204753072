import { Row } from "antd";
import React from "react";
import "./DynamicHeader.scss";

export default function DynamicHeader({ title }) {
  return (
    <Row className="dynamic-header">
      <h1>{title}</h1>
    </Row>
  );
}
