import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React, { useEffect, useState, useCallback } from "react";
import "./SearchView.scss";
import { useLocation } from "react-router-dom";
import { Input } from "antd";
import { searchAllData } from "API";
import { SearchComp } from "./SearchedComp/SearchComp";
import { searchKeysPriorityOrder } from "helpers/Denfiners";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

const { Search } = Input;

export const SearchView = () => {
  const location = useLocation();
  let [results, setResults] = useState([]);
  const [t] = useTranslation();
  // console.log(location);

  const onSearch = useCallback(
    (value) => {
      // console.log(value)
      searchAllData({ value, size: 100 }).then((res) => {
        let data = res.data.aggregations.groupByIndex.buckets;
        data.map(
          (item) => (item.order = searchKeysPriorityOrder[item.key].order)
        );
        data.sort((a, b) => a.order - b.order);
        setResults(data);
      });
    },
    [results]
  );

  // console.log(results);

  useEffect(() => {
    searchAllData({ value: location?.value || "", size: 100 }).then((res) => {
      let data = res.data.aggregations.groupByIndex.buckets;
      data.map(
        (item) => (item.order = searchKeysPriorityOrder[item.key].order)
      );
      data.sort((a, b) => a.order - b.order);
      setResults(data);
    });
  }, [location?.value]);
  return (
    <>
      <DynamicHeader title={t("navigation.searchPlaceholder")} />
      <div className="search-view-wrapper">
        <Search
          placeholder="Kerko"
          defaultValue={location?.value || ""}
          onSearch={(e) => onSearch(e)}
          enterButton
        />
        {results.length > 0 ? (
          <SearchComp data={results} />
        ) : (
          <Alert banner message="Fillo te kerkosh" type="info" />
        )}
      </div>
    </>
  );
};

export default SearchView;
