import { handleSearchTypes, handleUrlOfItemSelected } from "helpers/Denfiners";
import React from "react";
import { useHistory } from "react-router-dom";

const SearchResultComp = ({ data, key }) => {
  const history = useHistory();
  // console.log(data);

  const onItemSelect = (id, index) => {
    if (index !== "dokumentet") {
      history.push(`${handleUrlOfItemSelected[index]} ${id}`);
    } else {
      window.open(data._source.url, "_blank");
    }
  };
  let listKeys = key + 1;
  return (
    <li onClick={() => onItemSelect(data._id, data._index)} key={listKeys}>
      {Array.isArray(handleSearchTypes[data._index])
        ? handleSearchTypes[data._index]
            .map((item) => data._source[item])
            .join(" ")
        : data._source[handleSearchTypes[data._index]]}
    </li>
  );
};

export default SearchResultComp;
