import { getFilteredProcesverbale, getProcesverbale } from "API";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import React, { useState, useEffect } from "react";
import { Form, message, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import "./ProcesVerbale.scss";
import Filters from "./Filters/Filters";
import { getTranslatedField } from "helpers";
import { ActCard } from "../../components/GlobalComponents/ActCard/ActCard";

export const Procesverbale = () => {
  const [procesverbale, setProcesverbale] = useState();
  const [current, setCurrent] = useState(1);

  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const handleRecordsFilter = (
    _,
    page = 0,
    pageSize = 10,
    isSetCurrent = true
  ) => {
    const values = form.getFieldsValue();
    let { struktureId } = values;
    getFilteredProcesverbale({
      apiName: "strukturat",
      struktureId,
      page,
      pageSize,
    }).then((res) => {
      if (isSetCurrent) {
        setCurrent(1);
      }

      setProcesverbale(res.data);
    });
  };

  const getOtherRecords = async (page) => {
    const values = form.getFieldsValue();
    let { struktureId } = values;
    setCurrent(page);
    if (struktureId) {
      handleRecordsFilter({}, (page - 1) * 10, 10, false);
    } else {
      getProcesverbale({
        apiName: "dokumentet",
        page: (page - 1) * 10,
      }).then((res) => {
        setProcesverbale(res.data);
      });
    }
  };

  const clearFilters = async () => {
    await getProcesverbale({
      apiName: "dokumentet",
      page: 0,
    }).then((res) => {
      setProcesverbale(res.data);
      message.success("Filtrat u pastruan me sukses");
      form.resetFields();
    });
  };

  useEffect(() => {
    getProcesverbale({
      apiName: "dokumentet",
      page: 0,
    }).then((res) => {
      setProcesverbale(res.data);
    });
  }, []);
  // console.log(procesverbale);
  return (
    <div className="procesverbale-wrapper">
      <DynamicHeader title="Procesverbale" />
      <div className="procesverbale-content">
        <div className="procesverbale-fitlers">
          <b>{t("procesVerbalPage.searchForRecord")}</b>
          <Filters {...{ form, handleRecordsFilter, clearFilters, t }} />
          <b>{t("procesVerbalPage.recordList")}</b>
        </div>
        <LoadingComp loading={!procesverbale} foundData={procesverbale}>
          <div className="procesverbale-cards">
            {procesverbale?.map((dok, index) => (
              <ActCard
                key={index}
                id={dok.id}
                title={dok.FileName}
                // number="123"
                propozuesi={null}
                struktura={
                  dok?.StruktureDokumentet[0]?.Strukture &&
                  getTranslatedField(
                    dok?.StruktureDokumentet[0]?.Strukture,
                    i18n,
                    "Emer",
                    "EmerEn"
                  )
                }
                time={null}
                type={null}
                t={t}
                buttonText={t("procesVerbalPage.download")}
                url={dok.Url}
              />
              // <DokumentComp key={dok.id} data={dok} procesverbal />
            ))}
          </div>
        </LoadingComp>
        <Pagination
          current={current}
          defaultCurrent={1}
          disabled={!procesverbale?.length > 0}
          onChange={getOtherRecords}
          showSizeChanger={false}
          total={1500}
        />
      </div>
    </div>
  );
};

export default Procesverbale;
