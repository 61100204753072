import { getLimitedActs, getFilteredAkte } from "API";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React, { useState, useEffect } from "react";
import Filters from "./Filters/Filters";
import { Pagination, Form, message } from "antd";
import "./LibrariaAkteve.scss";
import moment from "moment";
import TabelaAkteve from "./TabelaAkteve/TabelaAkteve";
import { convertToIsoFomat } from "helpers/isoTimeFormat";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import { useTranslation } from "react-i18next";

export const LibrariaAkteve = () => {
  const [akte, setAkte] = useState();
  const [current, setCurrent] = useState(1); // Set default page to 1
  const [form] = Form.useForm();
  const [t] = useTranslation();

  const [filters, setFilters] = useState({
    date_miratimi: null,
    titull: null,
    tip: null,
  });

  const handleAkteFilter = () => {
    const values = form.getFieldsValue();
    setFilters({
      date_miratimi: values.date_miratimi,
      titull: values.titull,
      tip: values.tip,
    });

    getFilteredAkte({
      dateMiratimiStart: values.date_miratimi?.[0]
        ? convertToIsoFomat(moment(values.date_miratimi[0]))
        : null,
      dateMiratimiEnd: values.date_miratimi?.[1]
        ? convertToIsoFomat(moment(values.date_miratimi[1]))
        : null,
      titull: values.titull,
      tip: values.tip,
      page: 0, // Set the page to 1 after applying the filter
      pageSize: 10,
    }).then((res) => {
      setAkte(res.data);
      setCurrent(1); // Update the current page to 1
    });
  };

  const getSelectedActs = async (page) => {
    setCurrent(page);
    await getFilteredAkte({
      dateMiratimiStart: filters.date_miratimi?.[0],
      dateMiratimiEnd: filters.date_miratimi?.[1],
      titull: filters.titull,
      tip: filters.tip,
      page: (page - 1) * 10,
      pageSize: 10, // Use a constant value for pageSize
    }).then((res) => {
      setAkte(res.data);
    });
  };

  const clearFilters = async () => {
    await getLimitedActs({
      apiName: "aktet",
      page: 10, // Reset page to 1
      pageSize: 0,
    }).then((res) => {
      setAkte(res.data);
      setCurrent(1); // Reset current page to 1
      message.success("Filtrat u pastruan me sukses");
      form.resetFields();
      // Reset filters state
      setFilters({
        date_miratimi: null,
        titull: null,
        tip: null,
      });
    });
  };

  useEffect(() => {
    getLimitedActs({
      apiName: "aktet",
      page: 10,
      pageSize: 0,
    }).then((res) => {
      setAkte(res.data);
    });
  }, []);

  return (
    <div className="libraria-akteve-wrapper">
      <DynamicHeader title="Libraria e Akteve" />
      <div className="libraria-akteve-content">
        <div className="akte-filters-section">
          <b>{t("allActsPage.searchForActs")}</b>
          <Filters {...{ form, handleAkteFilter, clearFilters, t }} />
          <b>{t("allActsPage.actsList")}</b>
          <LoadingComp loading={!akte} foundData={akte}>
            <TabelaAkteve {...{ akte, t }} />
          </LoadingComp>
        </div>
        <Pagination
          current={current}
          disabled={!akte}
          onChange={getSelectedActs}
          showSizeChanger={false}
          total={1000}
        />
      </div>
    </div>
  );
};

export default LibrariaAkteve;
