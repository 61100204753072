export const handleSearchTypes = {
  aktet: "titull",
  anetaret: ["emer", "mbiemer"],
  lajmet: "titull",
  dokumentet: "fileName",
  strukturat: "emer",
};

export const handleUrlOfItemSelected = {
  aktet: "/dokumentacioni/aktet/",
  anetaret: "/deputet/",
  lajmet: "/lajme/",
  strukturat: "/struktura/",
};

export const searchKeysPriorityOrder = {
  aktet: { order: 1 },
  lajmet: { order: 2 },
  anetaret: { order: 3 },
  strukturat: { order: 4 },
  dokumentet: { order: 5 },
};

export const akteTypes = [
  "Projektligj",
  "ProjektVendim",
  "ProjektRezolute",
  "ProjektDeklarate",
  "Ligj",
  "Vendim",
  "Rezolute",
  "Deklarate",
  "Mocion",
  "Pyetje",
  "Raportime",
  "Kërkesë për informacion",
  "Interpelanca",
  "LigjeKthyerRishqyrtim",
  "Dekrete",
];

export const lajmeType = (type) => {
  switch (type) {
    case 0:
      return "Lajm";
    default:
      return "Njoftim";
  }
};

export const defineActNumberType = (type) => {
  let actTypeNumber = akteTypes.findIndex((actName) => actName === type);
  return actTypeNumber;
};

export const defineType = (type) => {
  switch (type) {
    case 0:
      return "Ligj";
    case 1:
      return "Vendim";
    case 2:
      return "Rezolute";
    case 3:
      return "Deklarate";
    case 4:
      return "Projektligj";
    case 5:
      return "ProjektVendim";
    case 6:
      return "ProjektDeklarate";
    case 7:
      return "ProjektRezolute";
    case 8:
      return "Mocion";
    case 9:
      return "Pyetje";
    case 10:
      return "Raportime";
    case 11:
      return "Kërkesë për informacion";
    case 12:
      return "Interpelanca";
    case 13:
      return "LigjeKthyerRishqyrtim";
    case 14:
      return "Dekret";
  }
};

export const definePropozuesi = (p) => {
  switch (p) {
    case 0:
      return "-";
    case 1:
      return "Këshilli i Ministrave";
    case 2:
      return "Deputet";
    case 3:
      return "Zgjedhes";
    case 4:
      return "President i Republikes";
    case 5:
      return "Komisioni i Posacem";
    case 6:
      return "Kryetari i Grupit Parlamentar";
    case 7:
      return "Komisioni i Perhershem";
    case 8:
      return "Institucion i Pavarur";
    case 9:
      return "Komision Hetimor";
    case 10:
      return "Grup Deputetësh";
    default:
      return "";
  }
};

export const defineRelationship = (rel) => {
  switch (rel) {
    case 0:
      return "Komision Përgjegjës";
    default:
      return "Komision Dhënie Mendimi";
  }
};

export const defineQarku = (id) => {
  switch (id) {
    case 0:
      return "Tiranë";
    case 1:
      return "Elbasan";
    case 2:
      return "Korçë";
    case 3:
      return "Gjirokastër";
    case 4:
      return "Berat";
    case 5:
      return "Kukës";
    case 6:
      return "Durrës";
    case 7:
      return "Shkodër";
    case 8:
      return "Vlorë";
    case 9:
      return "Fier";
    case 10:
      return "Lezhë";
    default:
      return "Dibër";
  }
};

export const TIP_DOK = Object.freeze({
  DEKLARATE: "DEKLARATE",
  CV: "CV",
  FOTOANETAR: "FOTOANETAR",
});
