import React, { useState } from "react";
import "./CustomTabsComp.scss";

export const CustomTabsComp = ({
  tabData = [],
  verticalTabs, // define this as true if you want a vertical type of tabs
  headerTitle = null,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="custom-tabs-comp-wrapper">
      {headerTitle && <b>{headerTitle}</b>}
      <div
        className={
          verticalTabs
            ? "custom-tabs-vertical-type"
            : "custom-tabs-horizontal-type"
        }
      >
        <div
          className={
            verticalTabs ? "vertical-custom-tabs" : "horizontal-custom-tabs"
          }
        >
          {tabData.map((tab, index) => (
            <span
              key={index}
              className={activeTab === index ? "active-tab" : "non-active-tab"}
              onClick={() => setActiveTab(index)}
            >
              {tab.tabName}
            </span>
          ))}
        </div>
        <div className="tab-content">{tabData[activeTab].tabContent}</div>
      </div>
    </div>
  );
};

export default CustomTabsComp;
