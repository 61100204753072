import React, { useState, useEffect } from "react";
import "../LiveVod.scss";
import axios from "axios";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";
import { useTranslation } from "react-i18next";
import LiveStreamComp from "../LiveStreamComp";
import { useHistory } from "react-router";
//import { fbToken } from "../../../API/requests";

export default function StrukturaVod({ name }) {
  const [facebookVideos, setFacebookVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextURIReq, setNextURIReq] = useState(
    //`https://graph.facebook.com/v13.0/105330971141171/live_videos?access_token=${fbToken}&pretty=0&limit=${limit}`
    `https://kuvendiapi.azurewebsites.net/api/FbWebhook`
  );
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getLiveVod(nextURIReq);
  }, [axios]);
  const getLiveVod = (uri) => {
    axios.get(uri).then((res) => {
      const data = res.data;
      setNextURIReq(data.paging.next);
      setFacebookVideos([...facebookVideos, ...data.data]);
      setLoading(false);
    });
  };
  if (!name) {
    return <div>error</div>;
  }

  return (
    <div>
      <div className={`news-related-cards`}>
        <strong>
          <h2>{t("LIVE/VOD")}</h2>
        </strong>
      </div>
      <div className="live-vod-vertical">
        <div className="live-vod-wrapper">
          {loading ? (
            <div className="loading-space">
              <LoadingComp loading={true} />
            </div>
          ) : (
            facebookVideos
              ?.filter((el) => el?.title.includes(name))
              .slice(0, 6)
              .map((video) => (
                <LiveStreamComp
                  key={video.id}
                  title={video.title}
                  html={video.embed_html}
                />
              ))
          )}
        </div>
        <DynamicButton
          text={t("navigation.more")}
          action={() => history.push("/live-vod")}
          type="secondary"
        />
      </div>
    </div>
  );
}
