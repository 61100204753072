import React from "react";

const LiveStreamComp = ({ html }) => {
  return (
    <div className="live-stream-comp">
      {/* <p>{title}</p> */}
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  );
};

export default LiveStreamComp;
