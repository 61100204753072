import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import Newsletter from "components/GlobalComponents/Newsletter/Newsletter";
import React, { useState, useEffect } from "react";
import NewsCard from "./NewsCard.jsx/NewsCard";
import "./NewsList.scss";
import moment from "moment";
import { Pagination, Form, message } from "antd";
import { getFilteredLajme, getLimitedLajme } from "API";
import defaultImg from "../../assets/images/header-backround.jpg";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import NewsFilters from "./NewsFilters/NewsFilters";
import { convertToIsoFomat } from "helpers/isoTimeFormat";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";

export const NewsList = () => {
  const [lajme, setLajme] = useState();
  const [current, setCurrent] = useState(1);
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const [filters, setFilters] = useState({
    datePublikimi: null,
    fjaleKyce: null,
  });

  const getSelectedLajme = async (page) => {
    setCurrent(page);
    await getFilteredLajme({
      datePublikimiStart: filters.datePublikimi?.[0],
      datePublikimiEnd: filters.datePublikimi?.[1],
      fjaleKyce: filters.fjaleKyce,
      page: (page - 1) * 10,
      pageSize: 10,
    }).then((res) => {
      setLajme(res.data);
    });
  };

  const handleNewsFilter = () => {
    const values = form.getFieldsValue();
    setFilters({
      datePublikimi: values.datePublikimi,
      fjaleKyce: values.fjaleKyce,
    });
    getFilteredLajme({
      datePublikimiStart: values.datePublikimi?.[0]
        ? convertToIsoFomat(moment(values.datePublikimi[0]))
        : null,
      datePublikimiEnd: values.datePublikimi?.[1]
        ? convertToIsoFomat(moment(values.datePublikimi[1]))
        : null,
      fjaleKyce: values.fjaleKyce,
      page: 0,
      pageSize: 10,
    }).then((res) => {
      setLajme(res.data);
      setCurrent(1); // Update the current page to 1
    });
  };

  const clearFilters = async () => {
    await getLimitedLajme({
      apiName: "lajmet",
      page: 10,
      pageSize: 0,
    }).then((res) => {
      setLajme(res.data);
      setCurrent(1); // Reset current page to 1
      message.success("Filtrat u pastruan me sukses");
      form.resetFields();
      // Reset filters state
      setFilters({
        datePublikimi: null,
        fjaleKyce: null,
      });
    });
  };

  useEffect(() => {
    getLimitedLajme({
      apiName: "lajmet",
      page: 10,
      pageSize: 0,
    }).then((res) => {
      setLajme(res.data);
    });
  }, []);

  //console.log(lajme.DatePublikimi);
  return (
    <>
      <DynamicHeader title={t("allNewsPage.header")} />
      <div className="newsList-wrapper">
        <div className="newList-content-wrapper">
          <b>{t("allNewsPage.searchNews")}</b>
          <NewsFilters {...{ form, handleNewsFilter, clearFilters, t }} />
          <b>{t("allNewsPage.newsList")}</b>
          <LoadingComp loading={!lajme} foundData={lajme}>
            <div className="newList-content">
              {lajme?.map((lajm, index) => {
                const lajmImg = lajm?.LajmDokumentet[0]?.Dokument?.Url;
                // ?.filter(
                //   (doc) => doc.Dokument.TipDokument.Emer === "FotoKryesoreLajm"
                // ) || [];
                // console.log(lajmImg.length > 0
                //   ? lajmImg[0].Dokument.Url
                //   : defaultImg);
                return (
                  <NewsCard
                    title={getTranslatedField(lajm, i18n, "Titull", "TitullEn")}
                    imageUrl={
                      // lajmImg.length > 0 ? lajmImg[0].Dokument.Url : defaultImg
                      lajmImg ? lajmImg : defaultImg
                    }
                    id={lajm.Id}
                    key={index}
                    t={t}
                    datePublikimi={lajm?.DatePublikimi}
                    // content={lajm.Permbajtje}
                  />
                );
              })}
              <Pagination
                current={current}
                disabled={!lajme}
                onChange={getSelectedLajme}
                showSizeChanger={false}
                total={1750}
              />
            </div>
          </LoadingComp>
        </div>
        <Newsletter {...{ type: "lajmet", tip: 0, t }} />
      </div>
    </>
  );
};

export default NewsList;
