import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
// import { dummyAgenda as agenda } from "./DummyAgenda";
import "./Agenda.scss";
import AgendaItem from "./AgendaItem";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import { getMbledhjetHomepage } from "API/requests";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
// import { convertToIsoFomat } from "helpers/isoTimeFormat";    //maybe needs to be added again instead of "toISOSstring" method, if it doesn't work correctly
// import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";

export default function Agenda() {
  const [t] = useTranslation();
  const [fromDate, setfromDate] = useState(
    moment().startOf("day").toISOString()
  );
  const [days, setDays] = useState([]);
  const [activeDay, setActiveDay] = useState(0);
  const [mbledhjet, setMbledhjet] = useState();

  const disabledDate = useCallback((current) => {
    let oneMonthLater = moment().add(1, "M");
    return current && current >= oneMonthLater;
  }, []);

  const onChange = useCallback(
    (date) => {
      if (date == null) {
        setfromDate(moment().toISOString());
      } else {
        setfromDate(date.toISOString());
        setActiveDay(0);
      }
    },
    [fromDate]
  );

  const handlePickedDate = (day, i) => {
    const filteredMbledhjeByDate = mbledhjet.allMbledhje.filter((mbledhje) =>
      moment(moment(mbledhje.DateOre).format("YYYY-MM-DD")).isSame(
        day.format("YYYY-MM-DD"),
        "day"
      )
    );
    const fullObj = {
      allMbledhje: mbledhjet?.allMbledhje,
      filteredMbledhjeByDate,
    };
    setActiveDay(i);
    setMbledhjet(fullObj);
  };

  useEffect(() => {
    var daysState = [];
    for (var i = 0; i < 5; i++) {
      daysState.push(moment(fromDate).startOf("day").add(i, "day"));
    }
    setDays(daysState);
  }, [fromDate]);

  useEffect(() => {
    let fourDaysAfter = moment(fromDate)
      .add(4, "days")
      .endOf("day")
      .toISOString();
    // console.log("fromDate", fromDate);
    // console.log("fourDaysAfter", fourDaysAfter);
    getMbledhjetHomepage({
      apiName: "mbledhjet",
      today: fromDate,
      fourDaysAfter,
    }).then((res) => {
      // console.log("res :>> ", res.data);
      let filteredMbledhjeByDate = res.data.filter((item) => {
        // console.log(moment(item.DateOre).format("YYYY-MM-DD"));
        return moment(moment(item.DateOre).format("YYYY-MM-DD")).isSame(
          moment(fromDate).format("YYYY-MM-DD"),
          "day"
        );
      });
      setMbledhjet({
        allMbledhje: res.data,
        filteredMbledhjeByDate,
      });
    });
  }, [fromDate]);

  // console.log(mbledhjet);
  // console.log(fromDate);

  return (
    <div className="agenda">
      <h1>{t("homeSections.homeHeader.agendaHeading")}</h1>
      <LoadingComp loading={!mbledhjet} foundData={{ hasData: "" }}>
        <div className="day-picker">
          <DatePicker
            value={moment(fromDate)}
            onChange={onChange}
            disabledDate={disabledDate}
          />
          <span>
            {days.map((day, i) => {
              return (
                <div
                  key={day.format("x")}
                  onClick={() => {
                    handlePickedDate(day, i);
                  }}
                  className={`day-pick ${activeDay == i && "selected"}`}
                >
                  {day.format("DD MMMM")}
                </div>
              );
            })}
          </span>
        </div>
        <div className="agenda-list">
          {mbledhjet?.filteredMbledhjeByDate?.length > 0 ? (
            mbledhjet?.filteredMbledhjeByDate?.map((agenda) => {
              return <AgendaItem key={agenda.Id} data={agenda} />;
            })
          ) : (
            <div className="mbledhje-warning">
              {t("homeSections.homeHeader.noMeetings")}
            </div>
          )}
        </div>
      </LoadingComp>
      <br />
      <br />
    </div>
  );
}
