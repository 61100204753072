import React from "react";
import "./DokumentComp.scss";
import doc_comp_icon from "assets/images/doc_comp_icon.png";
// import { ReactComponent as PlayIcon } from "../../../../assets/images/play.svg";
// import moment from "moment";

export default function DokumentComp({ data, procesverbal }) {
  console.log(data);
  return (
    <div
      onClick={() => {
        window.open(procesverbal ? data.Url : data?.url, "_blank");
      }}
      className="dok-comp"
    >
      <img width={30} alt="calendar-icon" src={doc_comp_icon} />
      <div className="doc-details">
        <p>{procesverbal ? data.FileName : data.fileName}</p>
        {procesverbal && <span>Struktura:&nbsp;</span>}
      </div>
    </div>
  );
}
