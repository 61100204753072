import React, { useEffect } from "react";
import "./NotFound.scss";

export default function NotFound() {
  useEffect(() => {
    document.title = "404";
  }, []);
  return (
    <div className="not-found">
      <h1>404</h1>
    </div>
  );
}
