import React from "react";
import "./NenStruktureComp.scss";
import struktureIcon from "assets/images/struktur.png";
//import moment from "moment";
import { Col } from "antd";
import { useHistory } from "react-router-dom";

export default function RendiDitesComp({ data }) {
  const history = useHistory();
  // console.log(data);
  return (
    <div
      className="rendi-dites-comp"
      onClick={() => {
        history.push(`/struktura/${data?.id}`);
      }}
    >
      <Col flex={1}>
        <img alt="calendar-icon" src={struktureIcon} />
      </Col>
      <Col flex={10}>
        <p>{data ? data.emer : data.Emer}</p>
      </Col>
    </div>
  );
}
