import React from "react";
import "./Kushtetuta.scss";
import { FilePdfOutlined } from "@ant-design/icons";
import kushtetutaImage from "../../../../assets/images/kushtetuta.png";

export const Kushtetuta = () => {
  return (
    <div className="kushtetuta-wrapper">
      <b>Kushtetuta e Republikës së Shqipërisë</b>
      <div className="kushtetuta-img-wrapper">
        <img src={kushtetutaImage}></img>
      </div>
      <div className="kushtetuta-content">
        <FilePdfOutlined />
        <a href="">Kushtetuta e Republikës së Shqipërisë</a>
      </div>
    </div>
  );
};

export default Kushtetuta;
