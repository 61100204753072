import React from "react";
import "./Legjislaturat.scss";
import { legjislaturatData } from "./LegjislaturatData";
import CustomTabsComp from "components/GlobalComponents/CustomTabs/CustomTabsComp";

export const Legjislaturat = () => {
  return (
    <CustomTabsComp
      {...{
        tabData: legjislaturatData,
        headerTitle: "Legjislaturat",
        verticalTabs: true,
      }}
    />
  );
};

export default Legjislaturat;
