import moment from "moment";
import React from "react";
import "./NenStrukture.scss";
import NenStruktureComp from "./NenStruktureComp/NenStruktureComp";

export default function NenStrukture({ strukturat, t }) {
  // console.log(rendi);
  return (
    <div className="komisioni-rendi-dites-wrapper">
      <strong>
        <h2>{t("strukturaSections.nenStrukturat")}</h2>
      </strong>
      <div className="rendi-list">
        {strukturat
          .sort((a, b) => {
            return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
          })
          .map((strukture) => (
            <NenStruktureComp key={strukture.id} data={strukture} />
          ))}
      </div>
    </div>
  );
}
