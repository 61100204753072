import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Select } from "antd";
import "./AskDeputy.scss";
import ReCAPTCHA from "react-google-recaptcha";
// import { verifyGoogleCaptcha } from "API/requests";

const { Option } = Select;

const layout = {
  wrapperCol: { span: 24 },
};

const GOOGLE_RECAPTCHA = "6Lc69YIiAAAAAEUShJBxVrQn1KDZhlLnAEIX9Fh6";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} nuk duhet te jete bosh!",
  types: {
    // email: "${label} nuk eshte i sakte!",
    number: "${label} nuk eshte i sakte!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
export default function AskDeputy({ members = null, t, title }) {
  const [form] = Form.useForm();

  const [token, setToken] = useState("");
  const recaptchaRef = React.createRef(null);
  // const onFinish = () => {
  //   // console.log(values);
  //   if (!token) {
  //     message.error("You must verify the captcha to be able to complete form");
  //   } else {
  //     verifyGoogleCaptcha({ token })
  //       .then((res) => {
  //         console.log(res);
  //         message.success("Captcha verified");
  //         setToken("");
  //       })
  //       .catch((e) => console.log("Err", e));
  //   }
  // };
  const onTokenGenerate = (value) => {
    setToken(value);
  };

  // console.log(token);
  // console.log("Captcha type:",typeof(token));
  // console.log(members);

  const handleDepEmail = () => {
    const seperatedName = form.getFieldValue("Deputeti")?.split(" ");
    const nameEmailForm = seperatedName?.join(".")?.toLowerCase();
    return nameEmailForm?.concat("", "@parlament.al");
  };

  const handleSendMail = () => {
    window.open(`mailto:${handleDepEmail()},
  ?subject=${form
    .getFieldValue("Emri")
    ?.concat(
      ", Phone number: ",
      form.getFieldValue("Phone")
    )}&body=${form.getFieldValue("Pyetje")}`);
  };

  return (
    <div className="ask-deputy">
      {title && (
        <h3>
          <b>{t("askDeputy.header")}</b>
        </h3>
      )}
      <Form
        {...layout}
        form={form}
        name="nest-messages"
        // onFinish={onFinish}
        validateMessages={validateMessages}
      >
        {members && (
          <Form.Item
            name={"Deputeti"}
            rules={[{ required: true }]}
            // label="Zgjidh Deputetin"
          >
            <Select
              placeholder={t("askDeputy.choose")}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              rows={8}
            >
              {members.map((member) => (
                <Option
                  key={member.id || member.Id}
                  value={member.emer.concat(" ", member.mbiemer)}
                >
                  {member.emer || member.Emer}{" "}
                  {member.mbiemer || member.Mbiemer}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name={"Pyetje"}
          rules={[{ required: true }]}
          //   label="Pyetje"
        >
          <Input.TextArea placeholder={t("askDeputy.question")} rows={8} />
        </Form.Item>
        <Form.Item name={"Emri"} rules={[{ required: true }]}>
          <Input placeholder={t("askDeputy.name")} />
        </Form.Item>
        {/* <Form.Item name={"Email"} rules={[{ type: "email", required: true }]}>
          <Input placeholder={t("askDeputy.email")} />
        </Form.Item> */}
        <Form.Item name={"Phone"} rules={[{ type: "number", required: true }]}>
          <InputNumber placeholder={t("askDeputy.nr")} />
        </Form.Item>
        <Form.Item>
          <Button
            color="#DC000B"
            type="primary"
            htmlType="submit"
            disabled={!token}
            onClick={handleSendMail}
          >
            {t("subscription.send")}
          </Button>
        </Form.Item>
      </Form>
      <ReCAPTCHA
        sitekey={GOOGLE_RECAPTCHA}
        onChange={(token) => onTokenGenerate(token)}
        onExpired={() => setToken("")}
        ref={recaptchaRef}
      />
    </div>
  );
}
