import React from "react";
import "./NewsCard.scss";
import moment from "moment";
import { longText } from "helpers/longText";
import { useHistory } from "react-router";
import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";

export const NewsCard = ({
  title,
  imageUrl,
  id,
  key,
  datePublikimi,
  // content,
}) => {
  const history = useHistory();
  return (
    <div className="newsList-content" key={key}>
      <article className="card">
        <div className="img-container">
          <img src={imageUrl} alt="S'ka imazh" />
        </div>
        <div className="card-info">
          <p>
            <strong>{longText(title)}</strong>
            {/* <div
              className="news-content"
              dangerouslySetInnerHTML={{ __html: longText(content) }}
            /> */}
          </p>
          <span className="bottom">
            <time dateTime={moment()}>
              {moment(datePublikimi).format("DD/MM/YYYY")}
            </time>
            <DynamicButton
              text="Me shume"
              action={() => {
                history.push(`/lajme/${id}`);
              }}
              type="view-act"
            />
          </span>
        </div>
      </article>
    </div>
  );
};

export default NewsCard;
