import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React, { useState, useEffect } from "react";
import "./PyetDeputetin.scss";
import AskDeputy from "components/DeputetComponents/AskDeputy/AskDeputy";
import { useTranslation } from "react-i18next";
import { getAllAnetaretData } from "API";
import { objectKeysToLowerCase } from "helpers/objectKeysToLowerCase";

export default function PyetDeputetin() {
  const [members, setMembers] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    getAllAnetaretData({ apiName: "anetaret" }).then((res) => {
      let letChangedObject = res.data.map((item) =>
        objectKeysToLowerCase(item)
      );
      setMembers(letChangedObject);
    });
  }, []);
  return (
    <div className="pyet-deputet-wrapper">
      <DynamicHeader title={t("askDeputy.header")} />
      <AskDeputy {...{ t, members }} />
    </div>
  );
}
