import { getAkteByTip } from "API";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import "./LibrariaAkteve.scss";
import TabelaAkteve from "./TabelaAkteve/TabelaAkteve";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const AkteCat = () => {
  const [akte, setAkte] = useState();
  const [current, setCurrent] = useState(1); // Set default page to 1
  const { tip } = useParams();
  const [t] = useTranslation();

  const getSelectedActs = async (page) => {
    setCurrent(page);

    const pageSize = 10; // Specify the default page size
    const skip = (page - 1) * pageSize; // Calculate the number of records to skip

    const response = await getAkteByTip({
      tip,
      skip,
      pageSize,
    });

    setAkte(response.data);
  };

  useEffect(() => {
    getAkteByTip({
      tip,
      page: 10,
      pageSize: 10, // or you can omit this line if you want to retrieve all records
    }).then((res) => {
      setAkte(res.data);
    });
  }, []);

  return (
    <div className="libraria-akteve-wrapper">
      <DynamicHeader title="Libraria e Akteve" />
      <div className="libraria-akteve-content">
        <div className="akte-filters-section">
          <b>{t("allActsPage.actsList")}</b>
          <LoadingComp loading={!akte} foundData={akte}>
            <TabelaAkteve {...{ akte, t }} />
          </LoadingComp>
        </div>
        <Pagination
          current={current}
          disabled={!akte}
          onChange={getSelectedActs}
          showSizeChanger={false}
          total={1000}
        />
      </div>
    </div>
  );
};

export default AkteCat;
