import React, { useState, useEffect } from "react";
import "./LiveIcon.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
//import { fbToken } from "../../../API/requests";

export default function LiveIcon() {
  const [isLive, setIsLive] = useState(false);
  useEffect(() => {
    axios
      .get(
        //`https://graph.facebook.com/v13.0/105330971141171/live_videos?access_token=${fbToken}&pretty=0&limit=10`
        `https://kuvendiapi.azurewebsites.net/api/FbWebhook`
      )
      .then((res) => {
        const data = res.data.data;
        if (data.filter((a) => a.status == "LIVE").length > 0) {
          setIsLive(true);
        }
      });
  }, [axios]);
  const history = useHistory();

  return (
    <div
      onClick={() => {
        history.push("/live-vod");
      }}
      className={`outer-circle-2 ${!isLive && "no-live"}`}
    >
      <div className={`outer-circle-1 ${!isLive && "no-live"}`}>
        <div className={`inner-circle ${!isLive && "no-live"}`}>
          <div className="live-text">LIVE</div>
        </div>
      </div>
    </div>
  );
}
