import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import Newsletter from "components/GlobalComponents/Newsletter/Newsletter";
import React, { useState, useEffect } from "react";
import NewsCard from "./NewsCard.jsx/NewsCard";
import "./NjoftimeList.scss";
import { Pagination } from "antd";
import defaultImg from "../../assets/images/header-backround.jpg";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import { getNjoftime } from "API";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";

export const NjoftimeList = () => {
  const [lajme, setLajme] = useState();
  const [current, setCurrent] = useState();
  const [t, i18n] = useTranslation();

  const getSelectedLajme = async (page, pageSize) => {
    setCurrent(page);
    await getNjoftime({
      apiName: "lajmet",
      page: pageSize,
      pageSize: (page - 1) * 10,
    }).then((res) => {
      setLajme(res.data);
    });
  };

  useEffect(() => {
    getNjoftime({ apiName: "lajmet", page: 10, pageSize: 0 }).then((res) => {
      setLajme(res.data);
    });
  }, []);
  // console.log(lajme);
  // if (lajme.length == 0) {
  //   return <div>Loading</div>;
  // }
  return (
    <>
      <DynamicHeader title="Shpallje dhe njoftime" />
      <div className="newsList-wrapper">
        <LoadingComp loading={!lajme} foundData={lajme}>
          <div className="newList-content-wrapper">
            {lajme?.map((lajm, index) => {
              const lajmImg = lajm?.LajmDokumentet[0]?.Dokument?.Url;
              // ?.filter(
              //   (doc) => doc.Dokument.TipDokument.Emer === "FotoKryesoreLajm"
              // ) || [];
              // console.log(lajmImg.length > 0
              //   ? lajmImg[0].Dokument.Url
              //   : defaultImg);
              return (
                <NewsCard
                  title={getTranslatedField(lajm, i18n, "Titull", "TitullEn")}
                  imageUrl={
                    // lajmImg.length > 0 ? lajmImg[0].Dokument.Url : defaultImg
                    lajmImg ? lajmImg : defaultImg
                  }
                  id={lajm.Id}
                  key={index}
                  datePublikimi={lajm.DatePublikimi}
                  // content={lajm.Permbajtje}
                />
              );
            })}
            <Pagination
              current={current}
              defaultCurrent={1}
              onChange={getSelectedLajme}
              showSizeChanger={false}
              total={1000}
            />
          </div>
        </LoadingComp>

        {/* <div className="newsletter-wrapper"> */}
        <Newsletter {...{ type: "lajmet", tip: 0, t }} />
        {/* </div> */}
      </div>
    </>
  );
};

export default NjoftimeList;
