import React, { useState, useEffect } from "react";
import "./Home.scss";
import HomeHeader from "../../components/Home/HomeHeader/HomeHeader";
import { Platforma } from "../../components/Home/Platforma/Platforma";
import NewsLayout from "components/Home/NewsLayout/NewsLayout";
import { LatestActs } from "components/Home/LatestActs/LatestActs";
// import PIPKIP from "components/Home/PIPKIP/PIPKIP";
import KryetariGraph from "components/GlobalComponents/KyetariGraph/KryetariGraph";
import { getAkteForHomePage, getLajmeForHomePage, getItemApiMethod } from "API";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";

const NO_NEWS_DATA = "Nuk u gjet asnje lajm";
const NO_AKTE_DATA = "Nuk u gjet asnje akt";

export const Home = () => {
  const [akte, setAkte] = useState();
  const [lajme, setLajme] = useState();
  const [kryetare, setKryetare] = useState();

  useEffect(() => {
    getAkteForHomePage({ apiName: "aktet" }).then((res) => {
      setAkte(res.data);
    });
  }, []);
  useEffect(() => {
    getLajmeForHomePage({ apiName: "lajmet" }).then((res) => {
      setLajme(res.data);
    });
  }, []);
  useEffect(() => {
    getItemApiMethod({
      apiName: "anetaret",
      id: "afb72233-f771-4b06-a51a-a42eaef51077",
    }).then((res) => {
      setKryetare(res.data);
    });
  }, []);
  // console.log(akte);
  return (
    <>
      <HomeHeader />
      <LoadingComp loading={!lajme} noDataMsg={NO_NEWS_DATA} foundData={lajme}>
        <NewsLayout {...{ lajme }} />
      </LoadingComp>
      <KryetariGraph {...{ kryetare }} />
      <LoadingComp loading={!akte} noDataMsg={NO_AKTE_DATA} foundData={akte}>
        <LatestActs {...{ akte }} />
      </LoadingComp>
      <Platforma />
    </>
  );
};

export default Home;
