import React from "react";
import { Form, Input, Button, message } from "antd";
import "./Newsletter.scss";
import { postAbonim } from "API";
import { v4 as uuidv4 } from "uuid";

export default function Newsletter({ type, tip, t }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    postAbonim({ apiName: "abonimet", id: uuidv4(), email: values.Email, tip })
      .then((res) => {
        console.log(res);
        message.success("Abonimi u krye me sukses!");
        form.resetFields(["Email"]);
      })
      .catch((e) => {
        console.log(e);
        message.error("Abonimi nuk u krye me sukses!");
      });
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };
  return (
    <div className="newsletter-wrapper">
      <h4>
        {t("subscription.description")}{" "}
        {type === "lajmet"
          ? t("subscription.newestNews")
          : type === "aktivitetet"
          ? t("subscription.activities")
          : t("subscription.acts")}
        .
      </h4>
      <Form
        form={form}
        validateMessages={validateMessages}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="Email" rules={[{ type: "email", required: true }]}>
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("subscription.send")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
