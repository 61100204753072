import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React from "react";
import "./SinjalizimeFooter.scss";

const SinjalizimetFooter = () => {
  return (
    <>
      <DynamicHeader title="Mbi sinjalizimin dhe mbrojtjen e sinjalizuesëve" />
      <div className="sinjalizime-footer-wrapper">
        <p>
          <h2>Akte ligjore dhe nënligjore</h2>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Ligji-nr.-60-2016-i-ndryshuar.pdf">
            -Ligj nr. 60/2016 “Per sinjalizimin dhe mbrojtjen e sinjalizuesve
          </a>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/vendim_i_km_816_16_11_2016_per_ngritje_strukturave.pdf">
            -VKM nr.816 date 16.11.2016 ”Për strukturën, kriteret e përzgjedhjes
            dhe marrëdhëniet e punës së punonjësve të njësisë përgjegjëse në
            autoritetet publike, në zbatim të ligjit nr. 60/2016, “për
            sinjalizimin dhe mbrojtjen e sinjalizuesve”
          </a>
        </p>
        <p>
          <h2>Rregulloret</h2>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/Rregullorja_sinjalizimit.pdf">
            -Rregulloren për hetimin administrativ të sinjalizimit dhe mbrojtjen
            e konfidencialitetit në institucionin e Kuvendit
          </a>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/hakmarrje_rregullore.pdf">
            -Rregulloren për hetimin administrativ të kërkesës së sinjalizuesit
            për mbrojtjen nga hakmarrja në institucionin e Kuvendit
          </a>
        </p>
        <p>
          <h2>Formularë</h2>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/form_sinj_brendshem.pdf">
            -https://kuvendiwebfiles.blob.core.windows.net/webfiles/form_sinj_brendshem.pdf
          </a>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/form_sinj_jashtem.pdf">
            -Formularin e sinjalizimit të jashtem
          </a>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/form_mbrojt_hakmarrje.pdf">
            -Formularin kërkesë për mbrojtje nga hakmarrja
          </a>
        </p>
        <p>
          <h2>Regjistra</h2>
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/regj_sinj_rregullore.xlsx">
            -Regjistrin e sinjalizimit te brendshem
          </a>
        </p>
      </div>
    </>
  );
};

export default SinjalizimetFooter;
