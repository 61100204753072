import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React from "react";
import "./KontakteFooter.scss";

const KontakteFooter = () => {
  return (
    <>
      <DynamicHeader title="Kontakte" />
      <div className="kontakte-footer-wrapper">
        <b>Urdhër i Brendshëm</b>
        <p>
          Të dhënat e kontaktit të Sekretarit të Përgjithshëm me qëllim
          administrimin e çdo denoncimi dhe të çdo rasti të përfshirjes,
          nxitjes, urdhërimit, shantazhimit apo kërkesës për përfshirjen e
          punonjësve të administratës së kuvendit në veprimtari politike apo në
          mbështetje të një subjekti zgjedhor apo kandidati në zgjedhje. (sipas
          pikës 2 të urdhërit)
        </p>
        <p>Urdhëri i Sekretarit të Përgjithshëm</p>
        <p>
          <b>E-mail:</b>&nbsp; genci.gjoncaj@parlament.al
        </p>
        <p>
          <b>Tel/Fax:</b>&nbsp; +355 42232599
        </p>
      </div>
    </>
  );
};

export default KontakteFooter;
