import React from "react";
import attachRed from "assets/images/attachmentRed.png";
import "./StrukturaList.scss";
import { useHistory } from "react-router-dom";

export default function StrukturaList({ list }) {
  const history = useHistory();
  return (
    <div className="struktura-list-wrapper">
      {list.map((struct, i) => {
        return (
          <p
            onClick={() => {
              history.push(`/struktura/${struct?.id}`);
            }}
            key={i}
            style={{ cursor: "pointer" }}
          >
            <img src={attachRed} />{" "}
            {`${
              struct?.emer?.length > 50
                ? `${struct?.emer.substring(0, 50)}...`
                : `${struct?.emer}`
            }`}
          </p>
        );
      })}
    </div>
  );
}
