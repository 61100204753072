import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React, { useState } from "react";
import "./ProgramiPunes.scss";
import { Table, DatePicker } from "antd";
// import { useParams } from "react-router-dom";
// import { getItemApiMethod } from "API";
// import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";

export const ProgramiPunes = () => {
  // const { id } = useParams();
  // See summery table property for the row with solo title
  const { RangePicker } = DatePicker;
  const [datePicked, setDatePicked] = useState([]);
  // const [pip, setPip] = useState();
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  const onDatePick = (momentDates, datesAsString) => {
    //use momentDates if you want to have other date format
    // console.log(datesAsString)
    setDatePicked(datesAsString);
  };
  // useEffect(() => {
  //   getItemApiMethod({ apiName: "pip", id }).then((res) => {
  //     setPip(res.data);
  //   });
  // }, []);

  // if (pip.length === 0) {
  //   return <div>Loading...</div>;
  // }
  // console.log(Object.keys(pip?.aktStrukturat[0].akt)[1]);
  return (
    <div className="programi-punes-wrapper">
      <DynamicHeader title="Programi i punes se kuvendit" />
      {/* <LoadingComp loading={!pip}> */}
      <strong>
        <RangePicker onCalendarChange={onDatePick} />
        {`Per periudhen ${datePicked.length && datePicked[0]} - ${
          datePicked.length && datePicked[1]
        }`}
        {/* (Për periudhën 15 nentor - 24 dhjetor 2021) */}
      </strong>
      <p>
        {/* {pip?.titull} */}
        Titull
      </p>
      <Table
        columns={
          columns
          // columns(pip)
        }
        dataSource={
          data
          // pip?.aktStrukturat
        }
        bordered
        pagination={{ position: ["bottomLeft"] }}
        onChange={onChange}
      />
      {/* </LoadingComp> */}
    </div>
  );
};

export default ProgramiPunes;

// const columns = (pip) => {
//   const aktStrukturat = pip?.aktStrukturat[0];
//   // console.log(aktStrukturat);
//   // console.log(Object.keys(pip).find(key => key === 'id'));
//   // const defineRelationship = (rel) => {
//   //   switch(rel) {
//   //     case 0:
//   //     return 'Komision Pergjegjes';
//   //   default:
//   //     return 'Komision Dhenie Mendimi';
//   //   }
//   // }
//   return [
//     {
//       title: "",
//       dataIndex: Object.keys(aktStrukturat).find((key) => key === "aktId"),
//     },
//     {
//       title: "Ceshtja",
//       dataIndex: Object.keys(aktStrukturat.akt).find((key) => key === "titull"),
//     },
//     {
//       title: "Nismetari/Data e depozitimit",
//       dataIndex: Object.keys(aktStrukturat.akt)
//         .find((key) => key === "propozuesi")
//         .concat(
//           "/",
//           Object.keys(aktStrukturat.akt).find((key) => key === "dateDepozitimi")
//         ),
//     },
//     {
//       title: "Komisioni Pergjegjes",
//       dataIndex: Object.keys(aktStrukturat).find(
//         (key) => key === "relationship"
//       ),
//     },
//     {
//       title: "Komision dhenie mendimi",
//       dataIndex: Object.keys(aktStrukturat).find(
//         (key) => key === "relationship"
//       ),
//     },
//   ];
// };

const columns = [
  {
    title: "Number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Ceshtja",
    dataIndex: "ceshtja",
    key: "ceshtja",
  },
  {
    title: "Nismetari/Data e depozitimit",
    dataIndex: "nism_dt_dep",
    key: "nism_dt_dep",
  },
  {
    title: "Komisioni Pergjegjes",
    dataIndex: "komisionPergj",
    key: "komisionPergj",
  },
  {
    title: "Komision dhenie mendimi",
    dataIndex: "komis_dhenie_mend",
    key: "komis_dhenie_mend",
  },
];

const data = [
  {
    key: "1",
    number: "1",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
  },
  {
    key: "2",
    number: "2",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
  },
  {
    key: "3",
    number: "3",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
  },
  {
    key: "4",
    number: "4",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
  },
  {
    key: "5",
    number: "5",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
  },
  {
    key: "6",
    number: "6",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
  },
  {
    key: "7",
    number: "7",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
  },
];
