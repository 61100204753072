import React from "react";
import { Input, DatePicker, Form, Button } from "antd";
import "./NewsFilters.scss";

const { RangePicker } = DatePicker;

export const NewsFilters = ({ form, handleNewsFilter, clearFilters, t }) => {
  return (
    <div className="news-filters">
      <Form form={form}>
        <div className="fliters">
          <Form.Item name="fjaleKyce">
            <Input placeHolder={t("allNewsPage.filters.keywords")} />
          </Form.Item>
          <Form.Item name="datePublikimi">
            <RangePicker
              placeholder={[
                t("allNewsPage.filters.startDate"),
                t("allNewsPage.filters.endDate"),
              ]}
              allowEmpty={[true, true]}
            />
          </Form.Item>
        </div>
        <div className="filter-buttons">
          <Form.Item>
            <Button type="primary" htmlType="submit" onClick={handleNewsFilter}>
              {t("navigation.searchPlaceholder")}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={clearFilters}>
              {t("allNewsPage.filters.cleaFilters")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default NewsFilters;
