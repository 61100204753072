import { getFilteredDokumenta, getFilteredDokumentaSearch } from "API";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import "./Dokumentat.scss";
import { getTranslatedField } from "helpers";
import { ActCard } from "../../components/GlobalComponents/ActCard/ActCard";
import { useParams } from "react-router-dom";

export const Dokumenta = () => {
  const [procesverbale, setDokumenta] = useState();
  const [current, setCurrent] = useState(1);
  const { struktureId, tipDokId } = useParams();
  const [t, i18n] = useTranslation();

  const getOtherRecords = async (page) => {
    setCurrent(page);
    getFilteredDokumentaSearch({
      apiName: "strukturat",
      struktureId,
      tipDokId,
      page: (page - 1) * 10,
    }).then((res) => {
      setDokumenta(res.data);
    });
  };

  useEffect(() => {
    getFilteredDokumenta({
      apiName: "strukturat",
      struktureId,
      tipDokId,
    }).then((res) => {
      setDokumenta(res.data);
    });
  }, []);
  console.log(procesverbale);
  return (
    <div className="procesverbale-wrapper">
      <DynamicHeader title="Dokumenta" />
      <div className="procesverbale-content">
        <div className="procesverbale-fitlers">
          <b>{t("dokumentaPage.recordList")}</b>
        </div>
        <LoadingComp loading={!procesverbale} foundData={procesverbale}>
          <div className="procesverbale-cards">
            {procesverbale?.map((dok, index) => (
              <ActCard
                key={index}
                id={dok.id}
                title={dok.FileName}
                // number="123"
                propozuesi={null}
                struktura={
                  dok?.StruktureDokumentet[0]?.Strukture &&
                  getTranslatedField(
                    dok?.StruktureDokumentet[0]?.Strukture,
                    i18n,
                    "Emer",
                    "EmerEn"
                  )
                }
                time={null}
                type={null}
                t={t}
                buttonText={t("dokumentaPage.download")}
                url={dok.Url}
              />
              // <DokumentComp key={dok.id} data={dok} procesverbal />
            ))}
          </div>
        </LoadingComp>
        <Pagination
          current={current}
          defaultCurrent={1}
          disabled={!procesverbale?.length > 0}
          onChange={(page) => getOtherRecords(page)} // Updated
          showSizeChanger={false}
          total={1000}
        />
      </div>
    </div>
  );
};

export default Dokumenta;
