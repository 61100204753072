import React from "react";
// import PostCard from "../PostCard/PostCard";
// import faker from "faker";
// import TweetCard from "react-tweet-card";
import { Alert } from "antd";
import "./PostList.scss";
import { Timeline } from "react-twitter-widgets";
import { useTranslation } from "react-i18next";

export default function PostList({ twitterName }) {
  const [t] = useTranslation();
  return (
    <div className="post-list-wrapper">
      <Alert message={t("tabs.postsAlert")} type="info" />
      <div className="post-cards-wrapper">
        <div
          style={{
            width: "100%",
            marginBottom: "20px",
            overflowY: "auto",
          }}
        >
          <Timeline
            options={{
              chrome: "noheader, nofooter",
            }}
            dataSource={{ sourceType: "profile", screenName: twitterName }}
          />
        </div>

        {/* <div
          className="fb-post"
          data-href="https://www.facebook.com/LinditaNikolla.official/posts/497402278420259"
          data-width="610"
          data-show-text="true"
        >
          <blockquote
            cite="https://www.facebook.com/LinditaNikolla.official/posts/497402278420259"
            className="fb-xfbml-parse-ignore"
          >
            <p>
              Kuvendin e Shqipërisë e përshëndeti Presidenti i Turqisë, z. Recep
              Tayyip Erdoğan. Turqia është partneri i 4 tregtar i...
            </p>
            Posted by{" "}
            <a href="https://www.facebook.com/LinditaNikolla.official">
              Lindita Nikolla
            </a>{" "}
            on&nbsp;
            <a href="https://www.facebook.com/LinditaNikolla.official/posts/497402278420259">
              Tuesday, January 18, 2022
            </a>
          </blockquote>
        </div>
        <div
          style={{ marginTop: "25px" }}
          className="fb-video"
          data-href="https://www.facebook.com/LinditaNikolla.official/videos/963640110953159/"
          data-width="610"
          data-show-text="false"
        >
          <blockquote
            cite="https://www.facebook.com/LinditaNikolla.official/videos/963640110953159/"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/LinditaNikolla.official/videos/963640110953159/">
              Seancë Plenare 17 Janar 2022
            </a>
            <p>
              Seancë Plenare 17 Janar 2022 Fjala e Presidentit të Republikës së
              Turqisë SH.T.Z. RECEP TAYYIP ERDOĞAN drejtuar Kuvendit të
              Shqipërisë
            </p>
            Posted by{" "}
            <a href="https://www.facebook.com/LinditaNikolla.official">
              Lindita Nikolla
            </a>{" "}
            on Monday, January 17, 2022
          </blockquote>
        </div> */}
        {/* <div className="fb-page" data-href="https://www.facebook.com/LinditaNikolla.official" data-tabs="timeline" data-width="610" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"><blockquote cite="https://www.facebook.com/LinditaNikolla.official" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/LinditaNikolla.official">Lindita Nikolla</a></blockquote></div> */}
      </div>
    </div>
  );
}
