import React from "react";
import { socialLinksData } from "./SocialLinksData";
import "./SocialLinks.scss";

export const SocialLinks = ({ className = "" }) => {
  return (
    <div className={`social-links-wrapper ${className}`.trim()}>
      {socialLinksData.map((link, index) => (
        <div
          key={index}
          style={{ border: `1px solid ${link.color}` }}
          onClick={() => window.open(link.url)}
        >
          <img src={link.icon}></img>
        </div>
      ))}
    </div>
  );
};
