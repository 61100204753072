import React from "react";

export const LinksDataComp = ({ linksData = [] }) => {
  return (
    <>
      {linksData.map((link, index) => (
        <li key={index}>{link}</li>
      ))}
    </>
  );
};
