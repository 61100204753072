import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React, { useState } from "react";
import "./KalendariPuneve.scss";
import { Table, DatePicker } from "antd";
// import { getAllApiMethod } from "API";

export const KalendariPuneve = () => {
  // See summery table property for the row with solo title
  const { RangePicker } = DatePicker;
  const [datePicked, setDatePicked] = useState([]);
  // const [kip, getKip] = useState([]);
  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  const onDatePick = (momentDates, datesAsString) => {
    //use momentDates if you want to have other date format
    // console.log(datesAsString)
    setDatePicked(datesAsString);
  };
  // useEffect(() => {
  //   getAllApiMethod({ apiName: "kip" }).then((res) => {
  //     getKip(res.data);
  //   });
  // }, []);
  // console.log(kip);
  return (
    <div className="kalendari-puneve-wrapper">
      <DynamicHeader title="Kalendari i puneve te kuvendit" />
      <strong>
        <RangePicker onCalendarChange={onDatePick} />
        {`Per periudhen ${datePicked.length && datePicked[0]} - ${
          datePicked.length && datePicked[1]
        }`}
        {/* (Për periudhën 6 - 24 dhjetor 2021) */}
      </strong>
      <p>
        (Miratuar me vendimin nr. 8, datë 2.12.2021 të Konferencës së
        Kryetarëve)
      </p>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={{ position: ["bottomLeft"] }}
        onChange={onChange}
      />
    </div>
  );
};

export default KalendariPuneve;

const columns = [
  {
    title: "",
    dataIndex: "number",
  },
  {
    title: "Ceshtja",
    dataIndex: "ceshtja",
  },
  {
    title: "Nismetari/Data e depozitimit",
    dataIndex: "nism_dt_dep",
  },
  {
    title: "Komisioni Pergjegjes",
    dataIndex: "komisionPergj",
  },
  {
    title: "Komision dhenie mendimi",
    dataIndex: "komis_dhenie_mend",
  },
  {
    title: "Shqyrtimi ne seance planare",
    dataIndex: "shqyrt_seanc_plan",
  },
];

const data = [
  {
    key: "1",
    number: "1",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
    shqyrt_seanc_plan: "20 dhjetor 2021 Ora 10:00",
  },
  {
    key: "2",
    number: "2",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
    shqyrt_seanc_plan: "20 dhjetor 2021 Ora 10:00",
  },
  {
    key: "3",
    number: "3",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
    shqyrt_seanc_plan: "20 dhjetor 2021 Ora 10:00",
  },
  {
    key: "4",
    number: "4",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
    shqyrt_seanc_plan: "20 dhjetor 2021 Ora 10:00",
  },
  {
    key: "5",
    number: "5",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
    shqyrt_seanc_plan: "20 dhjetor 2021 Ora 10:00",
  },
  {
    key: "6",
    number: "6",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
    shqyrt_seanc_plan: "20 dhjetor 2021 Ora 10:00",
  },
  {
    key: "7",
    number: "7",
    ceshtja: "Projektligj “Për Agjencinë e  Mbikëqyrjes Policore”.",
    nism_dt_dep: `Këshilli i Ministrave 10.02.2021`,
    komisionPergj: "Komisioni për Sigurinë Kombëtare",
    komis_dhenie_mend:
      "Komisioni për Çështjet Ligjore,Administratën Publike dhe të Drejtat e Njeriut",
    shqyrt_seanc_plan: "20 dhjetor 2021 Ora 10:00",
  },
];
