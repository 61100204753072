import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import Members from "components/GlobalComponents/Members/Members";
// import { deputet } from "DummyData/DummyDeputet";
import React, { useState, useEffect } from "react";
import "./DeputetList.scss";
import { getAllAnetaretData } from "API";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import { objectKeysToLowerCase } from "helpers/objectKeysToLowerCase";
import { useTranslation } from "react-i18next";

export default function DeputetList() {
  const [anetaret, setAnetaret] = useState();
  const [t] = useTranslation();

  useEffect(() => {
    getAllAnetaretData({ apiName: "anetaret" }).then((res) => {
      let letChangedObject = res.data.map((item) =>
        objectKeysToLowerCase(item)
      );
      setAnetaret(letChangedObject);
    });
  }, []);
  // console.log(anetaret);
  return (
    <div className="deputet-list-wrapper">
      <DynamicHeader title={t("mpsPage.mps")} />
      <LoadingComp
        loading={!anetaret}
        className="long-data-loading"
        foundData={anetaret}
      >
        <Members members={anetaret} t={t} />
      </LoadingComp>
    </div>
  );
}
