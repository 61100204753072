import React from "react";
import "./Kontrolli.scss";
import { kontrolliData } from "./KontrolliData";

export const Kontrolli = () => {
  return (
    <div className="kontrolli-wrapper">
      <b>Kontrolli nëpërmjet komunikimit</b>
      <div className="kontrolli-content">
        {kontrolliData.map((item) => (
          <>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </>
        ))}
      </div>
    </div>
  );
};

export default Kontrolli;
