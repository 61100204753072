import React from "react";
import "./NewsRelatedComp.scss";
// import { ReactComponent as PlayIcon } from "../../../../assets/images/play.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";
//import { longTextAct } from "helpers/longText";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";

export default function NewsRelatedComp({ data }) {
  // console.log(data);
  //let lajmet = data?.Lajm;
  const history = useHistory();
  const { i18n } = useTranslation();

  return (
    <div
      onClick={() => {
        history.push(`/lajme/${data.id}`);
      }}
      className="news-related-comp-wrapper"
    >
      <img
        alt="news-img"
        src={"https://parlament.al/static/media/header-backround.7fbe2678.jpg"}
      />
      <div className="overlay" />
      <p>
        {getTranslatedField(data, i18n, "titull", "titullen")} <br />
        {moment(data.datepublikimi).format("DD/MM/YYYY")}
      </p>

      <div className="go-to-btn">{/* <PlayIcon /> */}</div>
    </div>
  );
}
