import React from "react";
import "./IntroInfo.scss";
import { Row, Col } from "antd";

export const IntroInfo = ({ title, description, imageUrl, t }) => {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 50 }} className="intro-wrapper">
      <Col xs={24} sm={24} md={13}>
        <h2>{title}</h2>
        {description ? (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          <div>{t("strukturaSections.noDesc")}</div>
        )}
      </Col>
      <Col xs={24} sm={24} md={9} className="photo-wrapper">
        <img src={imageUrl}></img>
      </Col>
    </Row>
  );
};
