import React, { useState, useEffect } from "react";
import "./AgendaView.scss";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getMbledhjetByDate } from "API";
import { useTranslation } from "react-i18next";
// import { convertToIsoFomat } from "helpers/isoTimeFormat";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import Newsletter from "components/GlobalComponents/Newsletter/Newsletter";
import { getTranslatedField } from "helpers";

export const AgendaView = () => {
  const [mbledhjet, setMbledhjet] = useState();
  const { date } = useParams();
  const [t, i18n] = useTranslation();
  // let convertedDate = convertToIsoFomat(moment(date));
  // console.log(mbledhjet);

  let startOfDay = moment(date).startOf("day").toISOString();
  let endOfDay = moment(date).endOf("day").toISOString();
  // console.log("start",startOfDay);
  // console.log("end",endOfDay);

  useEffect(() => {
    getMbledhjetByDate({
      apiName: "mbledhjet",
      startOfDay,
      endOfDay,
    }).then((res) => {
      setMbledhjet(res.data);
    });
  }, []);
  return (
    <>
      <DynamicHeader
        title={`${t("agenda.header")} (${moment(date).format("LL")})`}
      />
      <div className="content-wrapper">
        <div className="agendas-wrapper">
          <div className="agenda-section">
            {mbledhjet?.map((mb) => (
              <div className="agenda-container" key={mb.MbledhjeId}>
                <div className="agenda-title">
                  {getTranslatedField(
                    mb.MbledhjeStrukturat[0].Strukture,
                    i18n,
                    "Emer",
                    "EmerEn"
                  )}
                </div>
                <h4>
                  - {t("agenda.location")}:
                  <span>
                    {getTranslatedField(mb, i18n, "Vendodhja", "VendodhjaEn")}
                  </span>
                </h4>
                <h4>
                  - {t("agenda.time")}:
                  <span>{moment(mb.DateOre).format("LT")}</span>
                </h4>
                <div
                  className="agenda-body-content"
                  dangerouslySetInnerHTML={{
                    __html: getTranslatedField(mb, i18n, "Rendi", "RendiEn"),
                  }}
                />
              </div>
            ))}
          </div>
          <Newsletter {...{ type: "aktivitetet", tip: 2, t }} />
        </div>
      </div>
    </>
  );
};

export default AgendaView;
