import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React from "react";
import "./AktetLigjoreFooter.scss";
import { FileTextOutlined } from "@ant-design/icons";

const AkteLigjoreFooter = () => {
  return (
    <>
      <DynamicHeader title="Akte ligjore" />
      <div className="akte-ligjore-footer-wrapper">
        <span>
          <FileTextOutlined />
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/statusi_i_nepunesit_civil.doc">
            - Statusi i Nenpunesit Civil
          </a>
        </span>
        <span>
          <FileTextOutlined />
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/kodi_i_punes.doc">
            - Kodi i Punës i Republikës së Shqipërisë
          </a>
        </span>
        <span>
          <FileTextOutlined />
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/vendim_i_km_242.doc">
            - Vendim Nr. 242, datë 18.03.2015 “Për plotësimin e vendeve të lira
            në kategorinë e ulët dhe të mesme drejtuese”
          </a>
        </span>
        <span>
          <FileTextOutlined />
          <a href="https://kuvendiwebfiles.blob.core.windows.net/webfiles/vendim_i_km_243.doc">
            - Vendim Nr. 243, datë 18.03.2015 “Për pranimin, lëvizjen paralele,
            periudhën e proves dhe emërimin në kategorinë ekzekutive”
          </a>
        </span>
      </div>
    </>
  );
};

export default AkteLigjoreFooter;
