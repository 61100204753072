import React from "react";
import { Row, Col, Input, DatePicker, Select, Form, Button } from "antd";
import "./Filters.scss";
import { akteTypes } from "helpers/Denfiners";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const Filters = ({ form, handleAkteFilter, clearFilters, t }) => {
  return (
    <div className="acts-filters">
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <div className="first-filters">
            <Col span={6} sm={12}>
              <Form.Item name="titull">
                <Input placeholder={t("allActsPage.filters.title")} />
              </Form.Item>
            </Col>
            <Col span={6} sm={12}>
              <Form.Item name="tip">
                <Select placeholder={t("allActsPage.filters.actType")}>
                  {akteTypes.map((akt) => (
                    <Option key={akt} value={akt}>
                      {akt}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </div>
          <Col span={10} sm={12}>
            <Form.Item name="date_miratimi">
              <RangePicker
                placeholder={[
                  t("allActsPage.filters.approvalStart"),
                  t("allActsPage.filters.approvalEnd"),
                ]}
                allowEmpty={[true, true]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" onClick={handleAkteFilter}>
              {t("navigation.searchPlaceholder")}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={clearFilters}>
              {t("allNewsPage.filters.cleaFilters")}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default Filters;
