import React from "react";
import "./KryetariItems.scss";
import { Select } from "antd";
// import { CaretRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";

const { Option } = Select;

export const KryetariItems = ({
  positionedLeft = true,
  selectInput = true,
  // selectInputPlaceholder = "Zgjidh",
  text,
  // number,
  options = [],
}) => {
  const history = useHistory();
  const [t, i18n] = useTranslation();

  const onKomisionSelect = (value, obj) => {
    // console.log(value, obj);
    history.push(`/struktura/${obj.key}`);
  };

  const onSearch = (val) => {
    console.log("search:", val);
  };

  return (
    <div className="kryetari-item-wrapper">
      {/* <span className={`${positionedLeft ? "left" : "right"} stats`.trim()}>
        <strong>{number}</strong>
        <h5>KPs</h5>
      </span> */}
      <p className={positionedLeft ? "left" : "right"}>{text}</p>
      {selectInput && (
        <Select
          showSearch
          placeholder={t("graphSection.choose")}
          optionFilterProp="children"
          onSelect={onKomisionSelect}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map((op) => (
            <Option
              value={getTranslatedField(op, i18n, "emer", "emerEn")}
              key={op.id}
            >
              {getTranslatedField(op, i18n, "emer", "emerEn")}
            </Option>
          ))}
        </Select>
      )}
      {/* <span className={positionedLeft ? "left" : "right"}>
        <CaretRightOutlined />
        <h6 onClick={() => history.push("/komision/123")}>Go to website</h6>
      </span> */}
    </div>
  );
};
