export const ndertesaData = [
  {
    title: "Ndërtesa e Kryesisë së Kuvendit të Republikës së Shqipërisë",
    description: `Vepër e projektuar nga arkitekti Anton Lufi që në vitin 1953. Planimetria ka formën e shkronjës
    “π” me gjatësi 80 m dhe me dy krahë me gjatësi 53 m. Arkitektura e jashtme ka përmasa të
    harmonizuara mjaft mirë, me paraqitje të qetë, por monumentale, e zbukuruar me pilastra,
    kapitele dhe korniza të arkitekturës klasike. Hyrja kryesore është trajtuar me harqe, përpjekje kjo
    për të përdorur elemente të arkitekturës popullore. Kjo ndërtesë katërkatëshe disponon shumë
    zyra, salla të ndryshme, bibliotekë dhe të gjitha mjediset e tjera të nevojshme, të plotësuara edhe
    me pjesët inxhinierike. Salla e mbledhjeve, e dekoruar me elemente klasike, ndodhet në katin e
    katërt të krahut jugor. Pas mbylljes së veprimtarisë së PPSH-së (1991), në këtë ndërtesë u
    vendosën pjesërisht administrata e Kuvendit të Shqipërisë, Gjykata Kushtetuese, Avokati i
    Popullit dhe për një periudhë kohe Ministria e Kulturës, Rinisë dhe Sporteve. Sot pjesën më të
    madhe të ambienteve e ka në përdorim Kuvendi i Shqipërisë, por ndodhen ende Gjykata
    Kushtetuese dhe Kontrolli i Lartë i Shtetit.`,
  },
  {
    title: "Ndërtesa e Seancave Plenare të Kuvendit të Republikës së Shqipërisë",
    description: `Ndërtesa është një projekt i vitit 1955 me autor arkitektin S. Luarasi në bashkëpunim me arkitektin
    rus D. Vasilef. Projekti u hartua në bazë të projektit të godinës ekzistuese (Lidhja Kulturore), në
    mënyrë që dhomat e kësaj godine të shërbenin si lidhje me godinën e re, pra si zyra të
    administratës së Kuvendit Popullor. Salloni i kinemasë ekzistuese u shndërrua në sallë për
    seancat plenare me kapacitet 160 vende, ndërsa tek tribuna e presidiumit me 40 vende. Për
    diplomatët u parashikuan lozhat me 44 vende nga njëra anë e sallës, ndërsa nga ana tjetër 44
    vende u caktuan për gazetarë, korrespodentë etj. Ballkoni përballë përmban 246 vende për
    audiencën. Për presidiumin u parashikua hyrja kryesore e godinës ekzistuese (Lidhjes Kulturore).
    Gardërobë, bufe dhe dhomë për rojat e presidiumit u parashikuan të vendosen në sallën e
    kinemasë ekzistuese. Kjo godinë e re e Kuvendit u projektua në mënyrë të tillë që të mund të
    përdoren të gjitha mobiliet ekzistuese të Kuvendit të vjetër (e presidiumit, e deputetëve dhe
    karriget e ballkonit).
    Sipërfaqja e ndërtesës është 710 m2; Vëllimi i ndërtesës është 6500 m3.`,
  },
];
