import React from "react";
import "./Historiku.scss";
import { historikuData } from "./HistorikuData";
// import photoUrl from "../../../../assets/images/historikuPhoto.png";
import { useTranslation } from "react-i18next";

export const Historiku = () => {

  const [t] = useTranslation();

  return (
    <div className="historiku-wrapper">
      <b>{t("kuvendiMenu.history.header")}</b>
      <div className="historiku-content">
        {historikuData({ ...{ t } }).map((item) => (
          <>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </>
        ))}
      </div>
    </div>
  );
};

export default Historiku;
