/**
 * Capitalize first letter of all object keys
 * @param {object} origObj - The current object
 */
export const objectKeysToLowerCase = (origObj) => {
  return Object.keys(origObj).reduce((newObj, key) => {
    let val = origObj[key];
    newObj[key.toLowerCase()] = val;
    return newObj;
  }, {});
};
