import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Home from "screens/Home/Home";
import "i18n";
import NavBar from "components/GlobalComponents/NavBar/NavBar";
import localization from "moment/locale/sq";
import moment from "moment";
import NotFound from "screens/NotFound/NotFound";
import News from "screens/News/News";
import Historiku from "screens/NavbarPages/Kuvendi/Historiku/Historiku";
import { Footer } from "components/GlobalComponents/Footer/Footer";
import DrejtuesNeVite from "screens/NavbarPages/Kuvendi/DrejtuesNeVite/DrejtuesNeVite";
import LigjvenesNeVite from "screens/NavbarPages/Kuvendi/LigjvenesNeVite/LigjvenesNeVite";
import Kushtetuta from "screens/NavbarPages/Kuvendi/Kushtetuta/Kushtetuta";
import Rregullorja from "screens/NavbarPages/Kuvendi/Rregullorja/Rregullorja";
import Ndertesa from "screens/NavbarPages/Kuvendi/Ndertesa/Ndertesa";
import Zgjedhjet from "screens/NavbarPages/Kuvendi/Zgjedhjet.js/Zgjedhjet";
import Roli from "screens/NavbarPages/Kuvendi/Funksionet/Roli/Roli";
import ProjektLigjLigj from "screens/NavbarPages/Kuvendi/Funksionet/ProjektligjLigj/ProjektLigj";
import Kontrolli from "screens/NavbarPages/Kuvendi/Funksionet/Kontrolli/Kontrolli";
import Legjislaturat from "screens/NavbarPages/Kuvendi/Legjislaturat/Legjislaturat";
import LibrariaAkteve from "screens/NavbarPages/Dokumentacioni/LibrariaAkteve/LibrariaAkteve";
import ScrollToTop from "react-scroll-to-top";
import Deputet from "screens/Deputet/Deputet";
import ActTimeline from "screens/NavbarPages/Dokumentacioni/LibrariaAkteve/ActTimeline/ActTimeline";
import Kontakte from "components/Kontakte/Kontakte";
import NewsList from "components/NewsList/NewsList";
import DeputetList from "screens/DeputetList/DeputetList";
import KalendariPuneve from "screens/KalendariPuneve/KalendariPuneve";
import ProgramiPunes from "screens/ProgramiPunes/ProgramiPunes";
import ScrollToTopForce from "ScrollToTopForce";
import PyetDeputetin from "screens/PyetDeputetin/PyetDeputetin";
import { NjoftimeList } from "components/NjoftimeList/NjoftimeList";
import Struktura from "screens/Struktura/Struktura";
import AgendaView from "screens/AgendaView/AgendaView";
import SearchView from "screens/SearchView/SearchView";
import StrukturaFooter from "screens/FooterScreens/StrukturaFooter/StrukturaFooter";
import SherbimeFooter from "screens/FooterScreens/SherbimeFooter/SherbimeFooter";
import AkteLigjoreFooter from "screens/FooterScreens/AkteLigjoreFooter/AkteLigjoreFooter";
import BuxhetiFooter from "screens/FooterScreens/BuxhetiFooter/BuxhetiFooter";
import KontakteFooter from "screens/FooterScreens/KontakteFooter/KontakteFooter";
import SinjalizimetFooter from "screens/FooterScreens/SinjalizimetFooter/SinjalizimetFooter";
import InstitutiParlamentar from "screens/FooterScreens/InstitutiParlamentar/InstitutiParlamentar";
import LiveVod from "screens/LiveVod/LiveVod";
import Procesverbale from "screens/Procesverbale/Procesverbale";
import Dokumenta from "screens/Dokumentat/Dokumentat";
import DokSearch from "screens/DokSearch/DokSearch";
import AkteCat from "screens/NavbarPages/Dokumentacioni/LibrariaAkteve/AkteCat";
import "react-image-lightbox/style.css";

const App = () => {
  moment().locale("sq", localization);
  return (
    <Router>
      <ScrollToTop smooth color="#ff0000" width="24" height="24" />
      <NavBar />
      <div>
        <ScrollToTopForce />
        <Switch>
          <Route exact path="/live-vod">
            <LiveVod />
          </Route>
          <Route exact path="/struktura/:id">
            <Struktura />
          </Route>
          <Route exact path="/lajme/:id">
            <News />
          </Route>
          <Route exact path="/deputet">
            <DeputetList />
          </Route>
          <Route exact path="/search">
            <SearchView />
          </Route>
          <Route exact path="/deputet/:id">
            <Deputet />
          </Route>
          <Route exact path="/kuvendi/historiku">
            <Historiku />
          </Route>
          <Route exact path="/kuvendi/drejtues-ne-vite">
            <DrejtuesNeVite />
          </Route>
          <Route exact path="/kuvendi/ligjvenes-shqiptar-ne-vite">
            <LigjvenesNeVite />
          </Route>
          <Route exact path="/kuvendi/kushtetuta">
            <Kushtetuta />
          </Route>
          <Route exact path="/kuvendi/rregullorja">
            <Rregullorja />
          </Route>
          <Route exact path="/kuvendi/ndertesa">
            <Ndertesa />
          </Route>
          <Route exact path="/kuvendi/zgjedhjet-parlamentare">
            <Zgjedhjet />
          </Route>
          <Route exact path="/kuvendi/funksionet/roli">
            <Roli />
          </Route>
          <Route exact path="/kuvendi/funksionet/projektLigjLigj">
            <ProjektLigjLigj />
          </Route>
          <Route exact path="/kuvendi/funksionet/kontrolli">
            <Kontrolli />
          </Route>
          <Route exact path="/kuvendi/legjislaturat">
            <Legjislaturat />
          </Route>
          <Route exact path="/kontakte">
            <Kontakte />
          </Route>
          <Route exact path="/dokumentacioni/aktet">
            <LibrariaAkteve />
          </Route>
          <Route exact path="/dokumentacioni/aktet/:id">
            <ActTimeline />
          </Route>
          <Route exact path="/kalendariIpuneve">
            <KalendariPuneve />
          </Route>
          <Route exact path="/programIpunes">
            <ProgramiPunes />
          </Route>
          <Route exact path="/lajme">
            <NewsList />
          </Route>
          <Route exact path="/njoftime">
            <NjoftimeList />
          </Route>
          <Route exact path="/pyeteDeputetin">
            <PyetDeputetin />
          </Route>
          <Route exact path="/kalendar/:date">
            <AgendaView />
          </Route>

          <Route exact path="/strukturafooter">
            <StrukturaFooter />
          </Route>
          <Route exact path="/sherbimefooter">
            <SherbimeFooter />
          </Route>
          <Route exact path="/akteLigjoreFooter">
            <AkteLigjoreFooter />
          </Route>
          <Route exact path="/boxhetiFooter">
            <BuxhetiFooter />
          </Route>
          <Route exact path="/kontakteFooter">
            <KontakteFooter />
          </Route>
          <Route exact path="/sinjalizimeFooter">
            <SinjalizimetFooter />
          </Route>
          <Route exact path="/institutiparlFooter">
            <InstitutiParlamentar />
          </Route>
          <Route exact path="/procesverbale">
            <Procesverbale />
          </Route>
          <Route exact path="/dokumenta/:struktureId/:tipDokId">
            <Dokumenta />
          </Route>
          <Route exact path="/doksearch/">
            <DokSearch />
          </Route>
          <Route exact path="/dokumentacioni/aktecat/:tip">
            <AkteCat />
          </Route>

          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
