import React from "react";
import { Row, Col, Select, Form, Button } from "antd";
import {
  // edukimiQytetar,
  keshillat,
  komisionetParl,
  nenKomisionet,
  organeKuvendi,
  sekretariate,
} from "helpers/strukturaDefiners";
import { tipDokmentet } from "helpers/tipdokDefiners";
import { useTranslation } from "react-i18next";
import { getTranslatedField } from "helpers";

const { Option } = Select;

export const Filters = ({ form, handleRecordsFilter, clearFilters, t }) => {
  const allStructures = [
    ...komisionetParl,
    ...sekretariate,
    ...organeKuvendi,
    ...keshillat,
    ...nenKomisionet,
    // ...edukimiQytetar,
  ];
  const allTipdok = tipDokmentet;
  const { i18n } = useTranslation();
  return (
    <Form form={form}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6} sm={12} className="strukture-filter">
          <Form.Item name="struktureId">
            <Select
              placeholder={t("procesVerbalPage.filters.structure")}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allStructures.map((str) => (
                <Option key={str.id} value={str.id}>
                  {getTranslatedField(str, i18n, "emer", "emerEn")}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} sm={12} className="strukture-filter">
          <Form.Item name="tipDokId">
            <Select
              placeholder={t("dokumentaPage.filters.tipDok")}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allTipdok.map((str) => (
                <Option key={str.id} value={str.id}>
                  {getTranslatedField(str, i18n, "emer", "emer")}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleRecordsFilter}
          >
            {t("navigation.searchPlaceholder")}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={clearFilters}>
            {t("allNewsPage.filters.cleaFilters")}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default Filters;
