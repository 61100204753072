export const getTranslatedField = (obj, i18n, albField, enField) => {
  if (!obj) {
    return "";
  }
  if (i18n.language === "al") {
    return obj[albField];
  }
  if (obj[enField]) {
    return obj[enField];
  }
  return obj[albField];
};
