import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import React from "react";
import "./SherbimeFooter.scss";

const SherbimeFooter = () => {
  return (
    <>
      <DynamicHeader title="Sherbime" />
      <div className="sherbime-footer-wrapper">
        <div className="sherbime-container">
          <p>
            Në krye të administratës së Kuvendit qëndron Sekretari i Përgjithshëm, i cili, sipas dispozitave kushtetuese është nëpunësi më i lartë civil i Kuvendit. Byroja e Kuvendit emëron, me propozimin e Kryetarit të Kuvendit, Sekretarin e Përgjithshëm mbi bazën e tre kandidaturave të dala nga procedura e konkurrimit, sipas ligjit “Për nëpunësin civil”. 
          </p>
          <p>
            Administrata e Kuvendit organizohet në shërbime, të cilat kryejnë detyra këshillimore, informuese, organizative dhe teknike në shërbim të veprimtarisë së deputetëve, Kuvendit dhe organeve të tij. Shërbimet e Kuvendit krijojnë mundësi dhe kushte të barabarta që deputetët, Kuvendi dhe organet e tij të kryejnë detyrat kushtetuese dhe ligjore. 
          </p>
          <p>
            Shërbimet e Kuvendit përbëhen nga:
          </p>
          <p>
            <strong>-Zyra e Sekretarit të Përgjithshëm, në përbërje të të cilit janë:</strong> Sektori i Projekteve, Sektori i Arkivës Parlamentare, Sektori i Auditit dhe të gjithë shërbimet e tjera që kanë varësi nga zyra e Sekretarit të Përgjithshëm.
          </p>
          <p>
            <strong>-Shërbimi i Përgjithshëm Legjislativ, në përbërje të të cilit janë:</strong> Shërbimi Juridik, Shërbimi i Komisioneve Parlamentare dhe Shërbimi i Seancës Plenare. 
          </p>
          <p>
            <strong>-Shërbimi i Përgjithshëm i Informacionit dhe Dokumentacionit, në përbërje të të cilit janë:</strong> Shërbimi i Teknologjisë së Informacionit, Shërbimi i Redaktorëve dhe Shërbimi i Komunikimit.
          </p>
          <p>
            <strong>-Shërbimi i Monitorimit të Institucioneve kushtetuese dhe atyre të pavarur të krijuar me ligj.</strong>
          </p>
          <p>
            <strong>-Instituti Parlamentar, në përbërje të të cilit janë:</strong> Shërbimi i Edukimit Qytetar, Shërbimi i Kërkimit dhe Analizës, si dhe Shërbimi i Botimeve dhe Bibliotekës Parlamentare.
          </p>
          <p>
            <strong>-Shërbimi i Përgjithshëm i Integrimit Europian, në përbërje të të cilit janë:</strong> Shërbimi i Përafrimit të Legjislacionit, Sekretariati i Këshillit Kombëtar për Integrimin Europian, si dhe Sekretariati Teknik pranë KCE (Komisionit për Çështjet Europiane).
          </p>
          <p>
            <strong>-Shërbimi i Përgjithshëm Administrativ, në përbërje të të cilit janë:</strong> Shërbimi  i Burimeve Njerëzore dhe i Trajtimit të Deputetëve, Shërbimi i Financës dhe i Buxhetit, Shërbimi i Transportit, Shërbimi i Mirëmbajtjes, Shërbimi i Prokurimeve dhe Sektori i Protokollit.
          </p>
          <p>
            <strong>-Shërbimi i i Përgjithshëm i Marrëdhënieve me Jashtë, në përbërje të të cilit janë:</strong> Shërbimi i Bashkëpunimit Dypalësh, Shërbimi i Bashkëpunimit me Organizatat Ndërkombëtare, si  dhe Shërbimi i Protokollit.
          </p>
          <p>
            Shërbimet e Kuvendit drejtohen dhe përgjigjen para Sekretarit të Përgjithshëm të Kuvendit. Ky i fundit siguron dhe kujdeset për mbarëvajtjen e punëve në Kuvend dhe përgjigjet para Kryetarit të Kuvendit dhe Byrosë së Kuvendit. Ai përgjigjet për hartimin dhe zbatimin e buxhetit të Kuvendit. Struktura e detajuar dhe kufiri organik i punonjësve të Kuvendit vendosen nga Byroja e Kuvendit, me propozimin e Sekretarit të Përgjithshëm.
          </p>
        </div>
      </div>
    </>
  );
};

export default SherbimeFooter;
