export const komisionetParl = [
  {
    id: "0cf43cc6-e63a-4d9d-8bdf-3ecc1429e743",
    emer: "Komisioni për Çështjet Ligjore, Administratën Publike dhe të Drejtat e Njeriut",
    emerEn: "Legal Issues, Public Administration and Human Rights Committee",
  },
  {
    id: "6659e9fe-9ba3-418e-8960-47480d7f5985",
    emer: "Komisioni për Punën, Çështjet Sociale dhe Shëndetësinë",
    emerEn: "Work, Social Issues and Health Committee",
  },
  {
    id: "b0b6142e-08af-4aaa-9fe8-4915e62cbf3a",
    emer: "Komisioni për Veprimtaritë Prodhuese, Tregtinë dhe Mjedisin",
    emerEn: "Productive Activity, Trade and Environment Committee",
  },
  {
    id: "ee21f12d-3f78-42b6-8cfe-870b51331cb1",
    emer: "Komisioni për Politikën e Jashtme",
    emerEn: "Foreign Policy Committee",
  },
  {
    id: "21eb4d1a-8b6e-4cd9-b584-a8554732a9ab",
    emer: "Komisioni për Sigurinë Kombëtare",
    emerEn: "National Security Committee",
  },
  {
    id: "2bc4536d-d197-4e8a-af43-b06ba9cf34e2",
    emer: "Komisioni për Çështjet Europiane",
    emerEn: "European Integration Committee",
  },
  {
    id: "c1cc21fe-59c4-48ea-b42b-bf44ed9aa7e1",
    emer: "Komisioni për Edukimin dhe Mjetet e Informimit Publik",
    emerEn: "Education and Means of Public Information Committee",
  },
  {
    id: "c314af43-615d-44b8-b63c-d0c4cdb109f4",
    emer: "Komisioni për Ekonominë dhe Financat",
    emerEn: "Economy and Finance Committee",
  },

  //Komisionet hetimore
  {
    id: "1c5a498c-88b7-42d8-8dc6-58892e474415",
    emer: "Komisioni hetimor për të kontrolluar ligjshmërinë e veprimeve të Presidentit për zgjedhjet vendore/emërimi gjyqtarëve kushtetues",
    emerEn:
      "Investigative commission to check the legality of the President's actions for local elections/appointment of constitutional judges.",
  },
  {
    id: "d8b0b1bf-ef1b-456e-aba8-b8a37a4d7125",
    emer: "Komisioni hetimor për impiantet e trajtimit të mbetjeve urbane",
    emerEn: "Investigative commission for urban waste treatment plants",
  },

  {
    id: "5db23a9b-f565-42ec-9424-c8e3bf6b1ed6",
    emer: "Komisioni hetimor për zgjedhjet e 25 prillit 2021",
    emerEn: "Investigative commission for the elections of April 25, 2021",
  },

  {
    id: "c6e6e070-54e5-466c-9e35-f0ec3f2237d8",
    emer: "KOMISIONI HETIMOR PËR TRAJTIMIN E TË DHËNAVE TË SISTEMIT TIMS",
    emerEn: "KOMISIONI HETIMOR PËR TRAJTIMIN E TË DHËNAVE TË SISTEMIT TIMS",
  },

  {
    id: "2fb0a4c4-fc05-4a2b-9a5f-27395d4d8de2",
    emer: "KOMISIONI HETIMOR LIDHUR ME SHËRBIMET PUBLIKE TË STERILIZIMIT DHE TË CHECK-UP NË SHËNDETËSI",
    emerEn:
      "KOMISIONI HETIMOR LIDHUR ME SHËRBIMET PUBLIKE TË STERILIZIMIT DHE TË CHECK-UP NË SHËNDETËSI",
  },

  //Nenkomisionet
  {
    id: "2181a753-ef5e-46d2-b690-28e81a86c673",
    emer: "Nënkomisioni për të Drejtat e Njeriut",
    emerEn: "Sub-Committee on Human Rights",
  },
  {
    id: "e200931a-6de3-471d-8a1f-4a27e5562f0a",
    emer: "Nënkomisioni për Administratën Publike",
    emerEn: "Public Administration Sub-Committee",
  },
  {
    id: "f7b6c7ef-e6b3-4827-b191-73185d953770",
    emer: "Nënkomisioni për diasporën dhe migracionin",
    emerEn: "Sub-Committee on Diaspora and Migration",
  },
  {
    id: "62dcf634-a870-4a23-ae68-7d1298e54b53",
    emer: "Nënkomisioni për monitorimin e zbatimit të buxhetit të shtetit dhe kontrollin e tij",
    emerEn:
      "Sub-Committee on the Monitoring of the Implementation of the State Budget and its Control",
  },
  {
    id: "6e3a9623-1aee-4b6c-b342-9b870733b734",
    emer: "Nënkomisioni për barazinë gjinore dhe parandalimin e dhunës ndaj gruas",
    emerEn:
      "Sub-Committee on Minors Issues, Gender Equality, Domestic Violence Committee",
  },
  {
    id: "d1d56719-80b6-4a1e-92df-b1b0314d0e00",
    emer: "Nënkomisioni për zhvillim të qëndrueshëm",
    emerEn: "Sub-Committee for sustainable development",
  },
  {
    id: "ee89f751-6844-4c9a-bb81-e973e1630ead",
    emer: "Nënkomisioni për qeverisjen vendore",
    emerEn: "Sub-Committee on Local Governance",
  },
  {
    id: "58519995-6369-4c59-b6db-f188126f51b3",
    emer: "Nënkomisioni për mbikëqyrjen e zbatimit të ligjit për Vetting-un e Policisë",
    emerEn: "Sub-Committee on Oversight of Law Enforcement on Police Vetting",
  },
  {
    id: "05f69e06-3c8f-431b-8de6-6a4c3b6c900f",
    emer: "Nënkomisioni për mjedisin dhe bujqësinë",
    emerEn: "Nënkomisioni për mjedisin dhe bujqësinë",
  },
  // {
  //   id: "c0840b24-b12e-4453-af3d-5e5e4a635e6d",
  //   emer: "Nënkomisioni “Për përzgjedhjen e kandidatëve për anëtarë të Këshillit të Lartë Gjyqësorë dhe Këshillit të Lartë të Prokurorisë nga radhët e avokatëve”",
  //   emerEn:
  //     "Nënkomisioni “Për përzgjedhjen e kandidatëve për anëtarë të Këshillit të Lartë Gjyqësorë dhe Këshillit të Lartë të Prokurorisë nga radhët e avokatëve”",
  // },
  // {
  //   id: "95f6f754-8383-4dfb-ab5a-01583061d138",
  //   emer: "Nënkomisioni “Për përzgjedhjen e kandidatëve për anëtarë të Këshillit të Lartë Gjyqësorë dhe Këshillit të Lartë të Prokurorisë që përfaqësojnë shoqërinë civile”",
  //   emerEn:
  //     "Nënkomisioni “Nënkomisioni “Për përzgjedhjen e kandidatëve për anëtarë të Këshillit të Lartë Gjyqësorë dhe Këshillit të Lartë të Prokurorisë që përfaqësojnë shoqërinë civile”",
  // },
  {
    id: "f15baac2-02dd-4ed9-88b7-bfcd7b273f20",
    emer: "Nënkomisioni për Auditimin e Sektorit Publik",
    emerEn: "Sub-Committee on Auditing the Public Sector",
  },
];

export const sekretariate = [
  {
    id: "a77d8061-1f4f-4c74-b330-18cfc70fd78b",
    emer: "Sekretariati për Transparencën dhe Teknologjinë e Informacionit",
    emerEn: "Secretariat on Transparency and Information Technology",
  },
  {
    id: "c040b468-ecdc-44d8-973e-2f01d07974b8",
    emer: "Sekretariati për Procedurat, Votimet dhe Etikën",
    emerEn: "Secretariat on Procedures and Voting",
  },
  {
    id: "0776fff4-99ee-4865-8477-9e556589aaf6",
    emer: "Sekretariati për Statusin e Deputetit",
    emerEn: "Secretariat on MP Status",
  },
  {
    id: "d462c5e6-81df-4216-9c18-a8a4977b1045",
    emer: "Sekretariati për Kërkimin Parlamentar, Bibliotekën dhe Botimet",
    emerEn: "Secretariat on Scientific Research, Library and Publications",
  },
  {
    id: "37a02df1-0660-49a5-96f4-bb43e1a528bd",
    emer: "Sekretariati për Marrëdheniet me Jashtë",
    emerEn: "Secretariat on Foreign Affairs",
  },
  {
    id: "2de8f82d-e00c-4964-b917-e507088d632a",
    emer: "Sekretariati për Buxhetin",
    emerEn: "Secretariat on Budget",
  },
];

export const organeKuvendi = [
  {
    id: "332f2692-f708-41aa-8059-2b8d2143bca1",
    emer: "Seanca Plenare",
    emerEn: "Plenary Session",
  },
  {
    id: "79de9e7f-5e70-4cac-a983-23864d6c1619",
    emer: "Byroja e Kuvendit",
    emerEn: "Bureau of the Assembly",
  },
  {
    id: "b4b8eede-3161-492d-8942-551da877cece",
    emer: "Konferenca e Kryetarëve",
    emerEn: "Conference of Chairmen",
  },
];

export const keshillat = [
  {
    id: "93143c27-443b-4f35-89a9-a39d2206bb9d",
    emer: "Këshilli për Rregulloren, Mandatet dhe Imunitetin",
    emerEn: "Council on the Rules of Procedure, Mandates and Immunities",
  },
  {
    id: "c847a326-44f9-4f86-9c8d-ec72d77de02c",
    emer: "Këshilli për Legjislacionin",
    emerEn: "Council on Legislation",
  },
];

export const nenKomisionet = [
  {
    id: "2181a753-ef5e-46d2-b690-28e81a86c673",
    emer: "Nënkomisioni për të Drejtat e Njeriut",
    emerEn: "Sub-Committee on Human Rights",
  },
  {
    id: "e200931a-6de3-471d-8a1f-4a27e5562f0a",
    emer: "Nënkomisioni për Administratën Publike",
    emerEn: "Public Administration Sub-Committee",
  },
  {
    id: "f7b6c7ef-e6b3-4827-b191-73185d953770",
    emer: "Nënkomisioni për diasporën dhe migracionin",
    emerEn: "Sub-Committee on Diaspora and Migration",
  },
  {
    id: "62dcf634-a870-4a23-ae68-7d1298e54b53",
    emer: "Nënkomisioni për monitorimin e zbatimit të buxhetit të shtetit dhe kontrollin e tij",
    emerEn:
      "Sub-Committee on the Monitoring of the Implementation of the State Budget and its Control",
  },
  {
    id: "6e3a9623-1aee-4b6c-b342-9b870733b734",
    emer: "Nënkomisioni për barazinë gjinore dhe parandalimin e dhunës ndaj gruas",
    emerEn:
      "Sub-Committee on Minors Issues, Gender Equality, Domestic Violence Committee",
  },
  {
    id: "d1d56719-80b6-4a1e-92df-b1b0314d0e00",
    emer: "Nënkomisioni për zhvillim të qëndrueshëm",
    emerEn: "Sub-Committee for sustainable development",
  },
  {
    id: "ee89f751-6844-4c9a-bb81-e973e1630ead",
    emer: "Nënkomisioni për qeverisjen vendore",
    emerEn: "Sub-Committee on Local Governance",
  },
  {
    id: "58519995-6369-4c59-b6db-f188126f51b3",
    emer: "Nënkomisioni për mbikëqyrjen e zbatimit të ligjit për Vetting-un e Policisë",
    emerEn: "Sub-Committee on Oversight of Law Enforcement on Police Vetting",
  },
  {
    id: "05f69e06-3c8f-431b-8de6-6a4c3b6c900f",
    emer: "Nënkomisioni për mjedisin dhe bujqësinë",
    emerEn: "Nënkomisioni për mjedisin dhe bujqësinë",
  },
  {
    id: "f15baac2-02dd-4ed9-88b7-bfcd7b273f20",
    emer: "Nënkomisioni për Auditimin e Sektorit Publik",
    emerEn: "Sub-Committee on Auditing the Public Sector",
  },
];

export const komisionetPosacme = [
  {
    id: "477ca4e3-f341-46b3-a216-86ecfe1d8817",
    emer: "Komisioni i Posaçëm Parlamentar për hartimin e disa shtesave dhe ndryshimeve në ligjin nr 15, datë 5.03.2015 “Për rolin e Kuvendit në procesin e Integrimit të Republikës së Shqipërisë në Bashkimin Europian”",
    emerEn:
      "The Special Parliamentary Committee for the drafting of some additions and changes to Law No. 15, dated 03/05/2015 ”On the role of the Assembly in the process of the Integration of the Republic of Albania into the European Union”",
  },
  {
    id: "16322ac8-aa61-4f89-bc7e-615b793eb3ea",
    emer: "Komisioni i Posaçëm për Reformën Zgjedhore",
    emerEn: "Komisioni i Posaçëm për Reformën Zgjedhore",
  },
];

export const bodiesGov = [
  {
    id: "e7118566-8fa0-4bd5-8005-64796993eaa6",
    emer: "Grupi i Gjelbër",
    emerEn: "Grupi i Gjelbër",
  },
  {
    id: "9a2efb41-4237-4a38-8124-4d71d307c56c",
    emer: "Grupi i Deputetëve “Miqtë e Fëmijëve”",
    emerEn: "Grupi i Deputetëve “Miqtë e Fëmijëve”",
  },
  {
    id: "2b74baec-09c4-4c41-9e73-a47d65a4cfe3",
    emer: "Grupi Vullnetar për Popullsinë dhe Zhvillimin",
    emerEn: "Grupi Vullnetar për Popullsinë dhe Zhvillimin",
  },
  {
    id: "99aeb82d-58b5-4be3-9664-6385b0857abd",
    emer: "Aleanca e Grave Deputete",
    emerEn: "Aleanca e Grave Deputete",
  },
  {
    id: "1b320a43-46fd-4efb-a41d-0e7544edcbdb",
    emer: "Klubi Parlamentar për të Rinjtë",
    emerEn: "Klubi Parlamentar për të Rinjtë",
  },
  {
    id: "657be50e-b4d5-4ca0-8e80-57fdbeb4448f",
    emer: 'Grupi Vullnetar "Për Dialogun Qytetar për Sigurinë"',
    emerEn: 'Grupi Vullnetar "Për Dialogun Qytetar për Sigurinë"',
  },
  {
    id: "a2fc652f-7cbb-42b2-9210-aaac06b84b08",
    emer: 'Grupi Vullnetar "Miqtë e personave me Aftësi te Kufizuara"',
    emerEn: 'Grupi Vullnetar "Miqtë e personave me Aftësi te Kufizuara"',
  },
];

//sherbimi i edukimit qytetar
export const edukimiQytetar = [
  {
    id: "656aabcc-e983-4a94-bd2f-03bebe1ae484",
    emer: "Shërbimi i Edukimit Qytetar",
    emerEn: "Civic Education Service",
  },
];

//koordinatori per shoqerine civile
export const koordinatoriShqoCivile = [
  {
    id: "042d8e58-33d1-4697-8fa2-8e3ed2a98098",
    emer: "Koordinatori për të Drejtën e Informimit",
  },
];

export const administrata = [
  // {
  //   id: "656aabcc-e983-4a94-bd2f-03bebe1ae484",
  //   emer: "Shërbimi i Edukimit Qytetar",
  //   emerEn: "Civic Education Service",
  // },
  // {
  //   id: "cb4baa71-b1a3-4cb6-9041-046ddd541da9",
  //   emer: "Shërbimi i Prokurimeve",
  //   emerEn: "Procurement Service",
  // },
  // {
  //   id: "7158d48a-222e-4e25-8f58-1ac600e3c7ce",
  //   emer: "Shërbimi i Përafrimit të Legjislacionit",
  //   emerEn: "Legislative Approximation Service",
  // },
  // {
  //   id: "d3c300c8-0206-42ae-a1ce-1f0989fb95a4",
  //   emer: "Shërbimi i Mardhënieve Dypalëshe dhe Shumëpalëshe",
  //   emerEn: "Bilateral and Multilateral Relations Service",
  // },
  // {
  //   id: "b12074f2-9891-4868-8c11-373c43475fee",
  //   emer: "Shërbimi i Teknologjisë së Informacionit",
  //   emerEn: "Information Technology Service",
  // },
  {
    id: "8d08aa81-2be2-4973-949d-37867730a876",
    emer: "Shërbimi Legjislativ",
    emerEn: "Legislative Service",
  },
  {
    id: "51933ad7-009a-4b52-80f7-3942a0eac378",
    emer: "Shërbimi i Administrativ",
    emerEn: "Administrative Service",
  },
  // {
  //   id: "e2ea8c0b-75b5-4a18-9b0b-4319f2c43644",
  //   emer: "Shërbimi i Komisioneve Parlamentare",
  //   emerEn: "Parliamentary Commissions Service",
  // },
  // {
  //   id: "03653fa6-20fd-4fd7-8823-82be44b3a7b6",
  //   emer: "Shërbimi i Burimeve Njerzore dhe Trjatimit të Deputetëve",
  //   emerEn: "Service of Human Resources and Treatment of MPs",
  // },
  // {
  //   id: "b7560a24-3b48-4c6b-9a24-90faf2bf79e0",
  //   emer: "Shërbimi i Kërkimit dhe Analizës",
  //   emerEn: "Research and Analysis Service",
  // },
  // {
  //   id: "b2e86f36-b61d-4dfa-8790-9abeb46f5210",
  //   emer: "Shërbimi i Juridik",
  //   emerEn: "Legal Service",
  // },
  // {
  //   id: "209085eb-fa70-4e0f-92a0-9f3bba28c2c9",
  //   emer: "Shërbimi i Botimeve dhe i Bibliotekës Parlamentare",
  //   emerEn: "Publications and Parliamentary Library Service",
  // },
  {
    id: "2a66c42d-a83d-4f22-8fb5-ace9da37e5f8",
    emer: "Shërbimi i Monitorimit të Institucioneve",
    emerEn: "Monitoring Service",
  },
  // {
  //   id: "f6e0b2c0-65f1-4660-8ef9-b516a6bc9b26",
  //   emer: "Shërbimi i Mardhënieve me Publikun",
  //   emerEn: "Public Relations Service",
  // },
  // {
  //   id: "30c3094f-7f7a-4651-982f-b7f9293a1fd8",
  //   emer: "Shërbimi i Protokollit",
  //   emerEn: "Protocol Service",
  // },
  // {
  //   id: "1cd4218c-b5cf-4a59-895f-b92291c0f5da",
  //   emer: "Shërbimi i Seancës Plenare",
  //   emerEn: "Plenary Session Service",
  // },
  // {
  //   id: "b220847f-677f-4a01-9795-c8fe5a899597",
  //   emer: "Shërbimi i Transportit",
  //   emerEn: "Transport Service",
  // },
  {
    id: "c754a02d-abc7-4ca6-88b8-ca415383f779",
    emer: "Shërbimi i Informacionit dhe Dokumentacionit",
    emerEn: "Information and Documentation Service",
  },
  // {
  //   id: "67d6663d-5f74-4836-b7b8-ec0c88bf1419",
  //   emer: "Shërbimi i Financës dhe Buxhetit",
  //   emerEn: "Finance and Budget Service",
  // },
  {
    id: "5adde3bc-ffa3-4b04-b760-ed6127000502",
    emer: "Shërbimi i Mardhënieve me Jashtë",
    emerEn: "Foreign Relations Service",
  },
  // {
  //   id: "b00d77d4-47f0-4e07-9031-f3be1b8cf696",
  //   emer: "Shërbimi i Mirmbajtjes",
  //   emerEn: "Maintenance Service",
  // },
  // {
  //   id: "1a2ab2f6-1c0e-484d-a77b-fc93ab0b108f",
  //   emer: "Shërbimi i Redaktorëve",
  //   emerEn: "Editors' Service",
  // },
  {
    id: "bb64f9f3-58fc-434d-95bd-b9d9a8cd0805",
    emer: "Instituti Parlamentar",
    emerEn: "Parliamentary Institute",
  },
  {
    id: "902aa225-9422-4fab-86fb-b5a73bcf28e4",
    emer: "Sektori i Arkivës",
    emerEn: "Archive Sector",
  },
  {
    id: "82946470-06ae-497e-93fe-29806495234a",
    emer: "Sektori i Projekteve",
    emerEn: "Projects Sector",
  },
  {
    id: "f5e7176c-d17e-4427-a627-c3e47e202e31",
    emer: "Sekretari i Përgjithshëm",
    emerEn: "Secretary General",
  },
  {
    id: "cb7c3a17-5b45-42db-9d58-a866b432345a",
    emer: "Sekretariati i Këshillit Kombëtar për Integrimin Europian",
    emerEn: "Secretariat of the National Council for European Integration",
  },
];

//grupet e miqesise
export const grupetMiqesise = [
  {
    id: "C706F8FE-E6E8-400B-8326-29FEE64862E1",
    emer: "SHQIPËRI - ZVICER",
  },
  {
    id: "B1B82BF5-E394-4C64-BD28-1B9EF6F19608",
    emer: "SHQIPËRI - UKRAINE",
  },
  {
    id: "C1FFEE16-8E39-499F-A5CF-ACDB23FA7DDD",
    emer: "SHQIPËRI - TURQI",
  },
  {
    id: "F2D4445A-264A-4969-9321-B22DD0F4DAD7",
    emer: "SHQIPËRI - SUEDI",
  },
  {
    id: "DFA2BBA2-6941-44B0-8B68-6E313C7AF869",
    emer: "SHQIPËRI - SHBA",
  },
  {
    id: "69A9CD7A-B931-418A-A653-FAD12ABE6E8E",
    emer: "SHQIPËRI - SLLOVAKI",
  },
  {
    id: "A42960F4-00C8-4F8D-B2E2-9B7FFEE35C3D",
    emer: "SHQIPËRI - SLLOVENI",
  },
  {
    id: "EA7B7851-37B4-4CD3-B67F-3B9C8A2E2C9F",
    emer: "SHQIPËRI - SPANJË",
  },
  {
    id: "1F98FEB1-9C4C-44E5-B201-9B33B5600E00",
    emer: "SHQIPËRI - SERBI",
  },
  {
    id: "86CE59C2-F7C8-4D44-947F-E1BADA4AC2D0",
    emer: "SHQIPËRI - RUSI",
  },
  {
    id: "6BF3D6C2-CFB7-49B8-9098-EEE46E942C1C",
    emer: "SHQIPËRI - RUMANI",
  },
  {
    id: "F5E86587-5760-44EE-A31A-5EF980562DAE",
    emer: "SHQIPËRI - QIPRO",
  },
  {
    id: "718D34B2-8B13-45FA-B93B-E91FDA922E00",
    emer: "SHQIPËRI - PORTUGALI",
  },
  {
    id: "24A137F7-F14B-4C82-A751-CEF81C3DFD74",
    emer: "SHQIPËRI - POLONI",
  },
  {
    id: "C491B98E-1E80-491F-BCED-0FE7F0B8F0F3",
    emer: "SHQIPËRI - PAKISTAN",
  },
  {
    id: "25E44C3A-F48B-4984-AB5B-C0D7DF019BED",
    emer: "SHQIPËRI - NORVEGJI",
  },
  {
    id: "744A56A5-F317-4AB4-B81F-B61724C68CC0",
    emer: "SHQIPËRI - MONGOLI",
  },
  {
    id: "03282231-A750-415F-9E29-3B3FD76394C0",
    emer: "SHQIPËRI - MONAKO",
  },
  {
    id: "55FE6D58-343F-42BA-9D18-8AD399D5BE8B",
    emer: "SHQIPËRI - MEKSIKË",
  },
  {
    id: "8121E742-39B3-4B93-8F99-C4BA2E188FA5",
    emer: "SHQIPËRI - BRITANI E MADHE",
  },
  {
    id: "7B8C2603-59CA-4A5F-A464-B87F514BB094",
    emer: "SHQIPËRI - MAROK",
  },
  {
    id: "BDCE79B4-041E-4C8F-94D3-A76C0C57ADEA",
    emer: "SHQIPËRI - MAQEDONIA E VERIUT",
  },
  {
    id: "6F21E79A-A7AD-4D6E-B12C-1833DE40D5E9",
    emer: "SHQIPËRI - MALTE",
  },
  {
    id: "8FD7D5ED-0E5F-4B3C-B878-0528A81D1D65",
    emer: "SHQIPËRI - MALI I ZI",
  },
  {
    id: "5414A82D-1892-4915-9CCA-EE09D4D4FEFF",
    emer: "SHQIPËRI - LUKSEMBURG",
  },
  {
    id: "308EB487-D04C-4098-B68F-6298FFD43F96",
    emer: "SHQIPËRI - LITUANI",
  },
  {
    id: "662DDC82-4C12-426B-B32A-928B69FE4872",
    emer: "SHQIPËRI - LIBAN",
  },
  {
    id: "8896F0B1-9D58-44C7-B9FC-E04B103AF8FD",
    emer: "SHQIPËRI - LETONI",
  },
  {
    id: "49E992F6-062B-4517-85FE-79375CF52F4D",
    emer: "SHQIPËRI - KUVAJT",
  },
  {
    id: "B8BA5352-799F-4763-8CCF-BBA826BD537D",
    emer: "SHQIPËRI - KUBË",
  },
  {
    id: "3688A8E3-F9CD-4C2E-820F-44772031287B",
    emer: "SHQIPËRI - KROACI",
  },
  {
    id: "93590048-9FF5-401E-A1AB-D1646D30D793",
    emer: "SHQIPËRI - KOREA E JUGUT",
  },
  {
    id: "6D0532E7-E21A-4F5C-BCA7-0B42279B01D0",
    emer: "SHQIPËRI - KINË",
  },
  {
    id: "DACE5942-1BB4-4673-A2B7-4F3BD62CFE82",
    emer: "SHQIPËRI - KAZAKISTAN",
  },
  {
    id: "249CC013-6650-4E14-9427-112A477C191F",
    emer: "SHQIPËRI - KATAR",
  },
  {
    id: "2900C781-7A14-4315-8CFC-3F70B10FFEA2",
    emer: "SHQIPËRI - KANADA",
  },
  {
    id: "3693B64D-03A4-44D1-8A38-FC66A70625C5",
    emer: "SHQIPËRI - JORDANI",
  },
  {
    id: "117609EA-7802-4E74-A971-3C8E729CD7C2",
    emer: "SHQIPËRI - JAPONI",
  },
  {
    id: "03333633-25A9-4E09-A254-48C356EDB147",
    emer: "SHQIPËRI - IZRAEL",
  },
  {
    id: "EB4CEA56-92F1-4B03-A280-C8D0F1B06933",
    emer: "SHQIPËRI - ITALI",
  },
  {
    id: "48C3F290-83F9-4F71-8598-F5285CA6E1A4",
    emer: "SHQIPËRI - ISLANDË",
  },
  {
    id: "0D20EAA0-D54E-485A-854F-98FCB8F48711",
    emer: "SHQIPËRI - IRLANDË",
  },
  {
    id: "E8105380-A87A-4365-AEEF-22937A85D98B",
    emer: "SHQIPËRI - INDONEZI",
  },
  {
    id: "FA20597D-9B6F-4A46-B32F-948F0B14DE70",
    emer: "SHQIPËRI - INDI",
  },
  {
    id: "655508E3-F0D7-47C5-A50B-E36212977EA7",
    emer: "SHQIPËRI - HUNGARI",
  },
  {
    id: "78CC4802-C200-4128-9751-A3CCCE777A51",
    emer: "SHQIPËRI - HOLLANDË",
  },
  {
    id: "9C99DD65-6555-4670-A26E-A66DDB07DF75",
    emer: "SHQIPËRI - GJERMANI",
  },
  {
    id: "38772485-1494-4597-97E2-95A9B737C893",
    emer: "SHQIPËRI - GREQI",
  },
  {
    id: "7FDC4897-1947-4E22-A127-F7D88D69F3F0",
    emer: "SHQIPËRI - FRANCË",
  },
  {
    id: "DA490BB3-C110-441B-8F1A-E153211332A3",
    emer: "SHQIPËRI - FINLANDE",
  },
  {
    id: "1A88F0A2-27A4-4002-BE89-A759852C3812",
    emer: "SHQIPËRI - ESTONI ",
  },
  {
    id: "4BB788D9-6F1F-4337-9A5B-11107353C989",
    emer: "SHQIPËRI - EMIRATET E BASHKUARA ARABE",
  },
  {
    id: "A7839DE3-25A7-4BC9-AD21-B5767DA2B5AA",
    emer: "SHQIPËRI - EGJIPT",
  },
  {
    id: "E26E9865-5FE8-4CE9-8C50-7536FF0964D7",
    emer: "SHQIPËRI - DANIMARKË",
  },
  {
    id: "84B18F56-2814-482D-B42E-7827D29C5148",
    emer: "SHQIPËRI - ÇEKI ",
  },
  {
    id: "2569E748-B3C9-4D90-94E5-9F4FB05809F3",
    emer: "SHQIPËRI - BULLGARI",
  },
  {
    id: "340486E2-0315-4570-B54B-F5C3F20387BA",
    emer: "SHQIPËRI - BRAZIL",
  },
  {
    id: "781E3135-86EE-464C-8E1E-4B92513C6EFB",
    emer: "SHQIPËRI - ARABIA SAUDITE",
  },
  {
    id: "427D2D2B-6082-424F-A41D-DEB2206CC702",
    emer: "SHQIPËRI - AFRIKA E JUGUT",
  },
  {
    id: "15FC2D8B-6BF5-4937-B98E-D102EF6BE226",
    emer: "SHQIPËRI - BOSNJE- HERCEGOVINË",
  },
  {
    id: "79416651-DA10-4D07-A8F3-F0AAB5EB3E64",
    emer: "SHQIPËRI - BELGJIKË",
  },
  {
    id: "6A17E061-DAB3-4DB1-A63A-C36A5F142BA4",
    emer: "SHQIPËRI - BAHREIN",
  },
  {
    id: "9489C6DB-F6BA-4001-9E74-03615217E312",
    emer: "SHQIPËRI - AZERBAJXHAN",
  },
  {
    id: "B185C22D-EDB1-4AA0-B21F-8CE67CF3DF89",
    emer: "SHQIPËRI - AUSTRI",
  },
  {
    id: "224BF797-B4E0-41B1-A288-705EE30761E5",
    emer: "SHQIPËRI - AUSTRALI",
  },
];
