import React, { useState, useEffect } from "react";
import "./LiveVod.scss";
import axios from "axios";
import DynamicHeader from "components/GlobalComponents/DynamicHeader/DynamicHeader";
import LoadingComp from "components/GlobalComponents/LoadingComp/LoadingComp";
import DynamicButton from "components/GlobalComponents/DynamicButton/DynamicButton";
import { useTranslation } from "react-i18next";
import LiveStreamComp from "./LiveStreamComp";
//import { fbToken } from "../../API/requests";

export default function LiveVod() {
  const [facebookVideos, setFacebookVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextURIReq, setNextURIReq] = useState(
    //`https://graph.facebook.com/v13.0/105330971141171/live_videos?access_token=${fbToken}&pretty=0&limit=6`
    `https://kuvendiapi.azurewebsites.net/api/FbWebhook`
  );
  const [t] = useTranslation();

  useEffect(() => {
    getLiveVod(nextURIReq);
  }, [axios]);
  const getLiveVod = (uri) => {
    axios.get(uri).then((res) => {
      const data = res.data;
      setNextURIReq(data.paging.next);
      setFacebookVideos([...facebookVideos, ...data.data]);
      setLoading(false);
    });
  };

  // console.log(facebookVideos);
  return (
    <div className="live-vod-vertical">
      <DynamicHeader title={"Live & VOD"} />
      <div className="live-vod-wrapper">
        {loading ? (
          <div className="loading-space">
            <LoadingComp loading={true} />
          </div>
        ) : (
          facebookVideos.map((video) => (
            <LiveStreamComp
              key={video.id}
              title={video.title}
              html={video.embed_html}
            />
          ))
        )}
      </div>
      <DynamicButton
        text={t("navigation.more")}
        action={() => getLiveVod(nextURIReq)}
        type="secondary"
      />
    </div>
  );
}
