import React from "react";
import DynamicButton from "../DynamicButton/DynamicButton";
import "./ActCard.scss";
// import { useHistory } from "react-router";
import moment from "moment";
import { definePropozuesi, defineType } from "helpers/Denfiners";

export const ActCard = ({
  title = "",
  time,
  type,
  id,
  propozuesi,
  t,
  struktura,
  buttonText,
  url,
}) => {
  const PROJEKT = "projekt";
  //const DATE_MIRATIMI = t("allActsPage.actCard.approvalDate");
  const DATE_DEPOZITIMI = t("allActsPage.actCard.depositDate");
  // const history = useHistory();

  return (
    <div className="act-card-wrapper">
      <div className="act-top-details">
        <h5>{`${title}`}</h5>
        {type && (
          <h6>
            {t("allActsPage.actCard.actType")}: {defineType(type)}
          </h6>
        )}
        {type && defineType(type).toLowerCase().includes(PROJEKT) && (
          <h6>
            {t("allActsPage.actCard.proposer")}: {definePropozuesi(propozuesi)}
          </h6>
        )}
        {struktura && (
          <h6>
            {t("allActsPage.actCard.structure")}:{"  " + struktura}
          </h6>
        )}
      </div>
      <div className="act-bottom-details">
        {time && (
          <span>
            {defineType(type).toLowerCase().includes(PROJEKT)
              ? DATE_DEPOZITIMI
              : DATE_DEPOZITIMI}
            :{" "}
            {moment(
              defineType(type).toLowerCase().includes(PROJEKT)
                ? time.dt_dep
                : time.dt_dep
            ).format("MMMM Do YYYY")}
          </span>
        )}
        <DynamicButton
          text={buttonText || t("allActsPage.actCard.more")}
          action={() => {
            if (type === null) {
              const win = window.open(url, "_blank");
              win.focus();
            } else {
              const win = window.open(`/dokumentacioni/aktet/${id}`, "_blank");
              win.focus();
            }

            // history.push(`/dokumentacioni/aktet/${id}`);
          }}
          type="view-act"
        />
      </div>
    </div>
  );
};
