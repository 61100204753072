import React from "react";
import "./SimpleCards.scss";
import { Row, Col } from "antd";
// import { ReactComponent as PlayIcon } from "../../../assets/images/play.svg";

export const SimpleCards = ({ cardData }) => {
  return (
    <Row
      className="simple-cards-wrapper"
      gutter={{ xs: 10, sm: 20, md: 40, lg: 60 }}
    >
      <div className="simple-cards-container">
        {cardData.map((c, i) => {
          return (
            <Col
              onClick={() => {
                if (c.goTo) {
                  window.open(c.goTo, "_blank");
                }
              }}
              key={i}
              className="card"
              span={7}
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
            >
              <img
                className={c.bigger ? "bigger-icon" : ""}
                src={c.icon}
                alt=""
              />
              {/* <div className="overlay-bckg"></div> */}
              {/* <PlayIcon /> */}
              {c.text ? (
                <div className="card-content">
                  <h4>{c.text}</h4>{" "}
                </div>
              ) : (
                ""
              )}
            </Col>
          );
        })}
      </div>
    </Row>
  );
};
