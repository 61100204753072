import moment from "moment";
import React from "react";
import "./RendiDites.scss";
import RendiDitesComp from "./RendiDitesComp/RendiDitesComp";

export default function RendiDites({ rendi, t }) {
  // console.log(rendi);
  return (
    <div className="komisioni-rendi-dites-wrapper">
      <strong>
        <h2>{t("strukturaSections.agenda")}</h2>
      </strong>
      <div className="rendi-list">
        {rendi
          .sort((a, b) => {
            return moment(b.dateKrijimi).diff(moment(a.dateKrijimi));
          })
          .map((rend) => (
            <RendiDitesComp key={rend.id} data={rend} />
          ))}
      </div>
    </div>
  );
}
